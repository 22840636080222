import AlertInfo from "components/ContentAlerts/AlertInfo";
import TextArea from "components/FormElements/TextArea";
import TextBox from "components/FormElements/TextBox";
import PageSettings from "components/PageSettings/PageSettings";
import SeoSettings from "components/SeoSettings/SeoSettings";
import React, { useEffect, useState } from "react";
import { useGlobalStore } from "store/GlobalStore";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { getContact, updateContact } from "services/AllServices";

const schema = yup.object().shape({
  can_see: yup.string(),
  seo_title: yup.string(),
  first_box_icon: yup.string(),
  third_box_icon: yup.string(),
  first_box_title: yup.string(),
  fourth_box_icon: yup.string(),
  google_map_code: yup.string(),
  second_box_icon: yup.string(),
  seo_description: yup.string(),
  third_box_title: yup.string(),
  fourth_box_title: yup.string(),
  second_box_title: yup.string(),
  first_box_content: yup.string(),
  third_box_content: yup.string(),
  fourth_box_content: yup.string(),
  second_box_content: yup.string(),
  last_operation_time: yup.string(),
});

function ContactPageEdit() {
  const [pageData, setPageData] = useState();
  const [selectedLanguage] = useGlobalStore((state) => [
    state.selectedLanguage,
  ]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const notify = () => {
    toast.success("Başarılı", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onSubmit = async (data) => {
    const configureData = {
      language: selectedLanguage.value,

      first_box_icon: data.first_box_icon.replaceAll("'", "’"),
      first_box_title: data.first_box_title.replaceAll("'", "’"),
      first_box_content: data.first_box_content.replaceAll("'", "’"),
      second_box_icon: data.second_box_icon.replaceAll("'", "’"),
      second_box_title: data.second_box_title.replaceAll("'", "’"),
      second_box_content: data.second_box_content.replaceAll("'", "’"),
      third_box_icon: data.third_box_icon.replaceAll("'", "’"),
      third_box_title: data.third_box_title.replaceAll("'", "’"),
      third_box_content: data.third_box_content.replaceAll("'", "’"),
      fourth_box_icon: data.fourth_box_icon.replaceAll("'", "’"),
      fourth_box_title: data.fourth_box_title.replaceAll("'", "’"),
      fourth_box_content: data.fourth_box_content.replaceAll("'", "’"),
      google_map_code: data.google_map_code.replaceAll("'", "’"),
      seo_title: data.seo_title.replaceAll("'", "’"),
      seo_description: data.seo_description.replaceAll("'", "’"),
      can_see: data.can_see,
      last_operation_time: data.last_operation_time,
    };
    updateContact(selectedLanguage.value, { content: configureData }).then(
      (res) => {
        notify();
      }
    );
  };

  const onGetContact = () => {
    getContact(selectedLanguage.value).then((res) => {
      reset(res);
      setPageData(res);
    });
  };
  useEffect(() => {
    onGetContact();
  }, [selectedLanguage]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className=" flex justify-between">
        <div className="container pt-6 pr-4 pb-4  overflow-y-scroll h-screen">
          <div className="flex items-center justify-between">
            <h1 className="font-semibold text-2xl text-main">
              İletişim Sayfasını Düzenle
            </h1>
          </div>
          <h2 className="font-semibold text-xl text-main mt-8 mb-4">
            İletişim Bilgileri
          </h2>

          {/* Icontext boxes are below */}
          <div className="grid grid-cols-3 gap-5 mt-5">
            <div className="col-span-1">
              <TextBox
                name="first_box_icon"
                label="1.Kutu İkonu"
                placeholder="ex:<i class=ri-map-pin-2-fill></i>"
                innerRef={{ ...register("first_box_icon") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="first_box_title"
                label="1.Kutu Başlığı"
                placeholder="Adres"
                innerRef={{ ...register("first_box_title") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="first_box_content"
                label="1.Kutu Metni"
                placeholder="Beşyol Fabrikalar Cad. No:21 Sefaköy / Istanbul PK 34295"
                innerRef={{ ...register("first_box_content") }}
                required
              />
            </div>

            <div className="col-span-1">
              <TextBox
                name="second_box_icon"
                label="2.Kutu İkonu"
                placeholder="ex:<i class=ri-phone-fill></i>"
                innerRef={{ ...register("second_box_icon") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="second_box_title"
                label="2.Kutu Başlığı"
                placeholder="Telefon"
                innerRef={{ ...register("second_box_title") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="second_box_content"
                label="2.Kutu Metni"
                placeholder="+90 (212) 541 61 80"
                innerRef={{ ...register("second_box_content") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="third_box_icon"
                label="3.Kutu İkonu"
                placeholder="ex:<i class=ri-printer-fill></i>"
                innerRef={{ ...register("third_box_icon") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="third_box_title"
                label="3.Kutu Başlığı"
                placeholder="Faks"
                innerRef={{ ...register("third_box_title") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="third_box_content"
                label="3.Kutu Metni"
                placeholder="+90 (212) 579 70 34"
                innerRef={{ ...register("third_box_content") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="fourth_box_icon"
                label="4.Kutu İkonu"
                placeholder="ex:<i class=ri-mail-unread-fill></i>"
                innerRef={{ ...register("fourth_box_icon") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="fourth_box_title"
                label="4.Kutu Başlığı"
                placeholder="E-Posta"
                innerRef={{ ...register("fourth_box_title") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="fourth_box_content"
                label="4.Kutu Metni"
                placeholder="info@cemofset.net"
                innerRef={{ ...register("fourth_box_content") }}
                required
              />
            </div>
          </div>

          <div className="col-span-9 lg:mt-5">
            <TextArea
              name="google_map_code"
              label="Google Harita Kodu"
              placeholder="Harita Kodu"
              innerRef={{ ...register("google_map_code") }}
              required
            />
          </div>
          <div className="col-span-9">
            <AlertInfo
              title="Bilgilendirme"
              description="Harita kodunuzu maps.google.com adresinden konumu yazıp paylaş dedikten sonra açılan sekmede “Embed Map” kısmından alabilirsiniz."
            />
          </div>

          <SeoSettings register={register} />
        </div>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ToastContainer />
        <PageSettings date={pageData?.last_operation_time} />
      </div>
    </form>
  );
}

export default ContactPageEdit;
