import React from "react";

function AlertInfo({ title, description }) {
  return (
    <div className="bg-white w-full p-4 flex items-center">
      <div>
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="48" height="48" rx="24" fill="#C6E3FF" />
          <path
            d="M24 34C18.477 34 14 29.523 14 24C14 18.477 18.477 14 24 14C29.523 14 34 18.477 34 24C34 29.523 29.523 34 24 34ZM23 23V29H25V23H23ZM23 19V21H25V19H23Z"
            fill="#508BFF"
          />
        </svg>
      </div>
      <div className="ml-3">
        <p className="text-alertBlue font-semibold">{title}</p>
        <p className="text-mainText text-xs mt-1 ">{description}</p>
      </div>
    </div>
  );
}

export default AlertInfo;
