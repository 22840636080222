import IconTextBox from "components/FormElements/IconTextBox";
import MainButton from "components/FormElements/MainButton";
import TextBox from "components/FormElements/TextBox";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { createStaff, getStaff, updateStaff } from "services/AllServices";

const schema = yup.object().shape({
  fullname: yup.string(),
  email: yup.string(),
  password_decrypted: yup.string(),
});

function UpdateUserModal({ setOpenModal, notify, id }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (data) => {
    const configureData = {
      fullname: data.fullname,
      email: data.email,
      password: data.password_decrypted,
    };
    if (data.password_decrypted === data.passwordAgain) {
      updateStaff(id, configureData).then((res) => {
        setOpenModal();
        notify();
      });
    } else {
      alert("Şifre eşleşmiyor.");
    }
  };

  useEffect(() => {
    getStaff(id).then((res) => {
      reset(res);
    });
  }, []);

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white  ">
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex items-center justify-between  py-3 px-5 ">
                    <p
                      className="text-xl leading-6 font-semibold text-main"
                      id="modal-headline"
                    >
                      Kullanıcı Düzenle
                    </p>
                    <i
                      onClick={() => setOpenModal(false)}
                      className="ri-close-line text-2xl text-main cursor-pointer"
                    ></i>
                  </div>
                  <div className="px-5 pt-2 pb-5 bg-white">
                    <TextBox
                      label="Ad Soyad"
                      id="fullname"
                      name="fullname"
                      placeholder="Adı Soyadı"
                      innerRef={{ ...register("fullname") }}
                      required
                    />

                    <TextBox
                      label="E-Posta"
                      name="email"
                      placeholder="info@alanadi.com"
                      style="mt-5"
                      innerRef={{ ...register("email") }}
                      required
                    />
                    <div className="grid grid-cols-2 gap-5 mt-5">
                      <div className="col-span-1">
                        <IconTextBox
                          label="Parola"
                          name="password_decrypted"
                          type="password"
                          placeholder="•••••••"
                          iconChange={true}
                          textBoxIcon="ri-eye-line"
                          textBoxSecondIcon="ri-eye-off-line"
                          innerRef={{ ...register("password_decrypted") }}
                          required
                        />
                      </div>
                      <div className="col-span-1">
                        <IconTextBox
                          label="Parola Yeniden"
                          name="passwordAgain"
                          type="password"
                          placeholder="•••••••"
                          iconChange={true}
                          textBoxIcon="ri-eye-line"
                          textBoxSecondIcon="ri-eye-off-line"
                          innerRef={{ ...register("passwordAgain") }}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center justify-end px-5 pt-2 pb-5 bg-white">
                    <MainButton type="submit" className="" left text="Kaydet">
                      <i className="ri-checkbox-circle-fill text-white mr-2"></i>
                    </MainButton>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateUserModal;
