import React from "react";

function TextArea({
  label,
  name,
  placeholder,
  rows,
  innerRef,
  defaultValue,
  value,
  onChange,
  style,
  maxLength,
  required,
}) {
  return (
    <div className={`${style && style}`}>
      <label className="text-mainText text-sm mb-1">{label}</label>
      <textarea
        {...innerRef}
        className={`w-full p-3 border border-secondaryText focus:outline-none  focus:border-hoverYellow focus:ring-1 focus:ring-hoverYellow placeholder-secondaryText`}
        value={value}
        onChange={onChange && ((e) => onChange(e.target.value))}
        name={name}
        placeholder={placeholder}
        rows={rows ? rows : 3}
        maxLength={maxLength && maxLength}
        required={required}
        defaultValue={defaultValue && defaultValue}
      />
    </div>
  );
}

export default TextArea;
