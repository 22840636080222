import FixedPagesTable from "components/FixedPages/FixedPagesTable";
import React from "react";

function FixedPages() {
  return (
    <div className="container pt-6">
      <h1 className="font-semibold text-2xl text-main">Sayfaları Görüntüle</h1>
      <div className="mt-10">
        <FixedPagesTable />
      </div>
    </div>
  );
}

export default FixedPages;
