import Sidebar from "components/Sidebar/Sidebar";
import React, { useEffect, useState } from "react";

function Layout(props) {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="flex flex-col bg-white h-screen overflow-hidden">
      <div className="flex h-screen ">
        <div className="border-r-2 border-borderGra h-full">
          <Sidebar />
        </div>
        <div className="relative w-full h-full">
          <div className="h-screen   pl-6 bg-bgColor">
            <div className="">{props.children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
