import React, { useState, useEffect } from "react";

export default function FileUploader(props) {
  const [newName, setNewName] = useState("Dosya Seçiniz");

  function addFile(file) {
    if (props.maxFile) {
      if (file.length <= props.maxFile) {
        let newVal = [];
        for (const [key, value] of Object.entries(file)) {
          newVal.push(value);
        }
        setNewName(newVal);
        props.setValue(file);
      } else {
        alert(`Maksimum ${props.maxFile} adet dosya yükleyebilirsiniz.`);
      }
    } else {
      let newVal = [];
      for (const [key, value] of Object.entries(file)) {
        newVal.push(value);
      }
      setNewName(newVal);
      props.setValue(file);
    }
  }
  const onDeleteFile = (name) => {
    let newFileList = [];
    newName.map((n) => {
      if (n.name !== name) {
        newFileList.push(n);
      }
    });
    setNewName(newFileList);
    props.setValue(newFileList);
  };
  useEffect(() => {
    if (props.defaultValue) {
      setNewName([{ name: props.defaultValue }]);
    }
  }, []);

  useEffect(() => {
    if (props.value === null) {
      setNewName([]);
    }
  }, [props.value]);
  return (
    <div className="">
      <label
        className={
          "block float-left w-full pr-4 mb-1 text-sm text-main md:text-left md:mb-0 " +
          props.labelStyle
        }
        htmlFor={props.id}
      >
        {props.label}
      </label>
      <div className="box-border relative flex flex-wrap justify-start w-full align-middle text-secondary pr-1 cursor-pointer">
        <div className="box-border relative flex-wrap w-full text-xl">
          <div
            className={
              props.inputStyle +
              " border-2 rounded-md " +
              (props.disabled
                ? "bg-primary-disabled border-primary-disabled text-gray-600"
                : props.message !== ""
                ? "form-error"
                : "input-text-style text-gray-700") +
              " float-left w-full px-3 py-1 text-sm leading-tight appearance-none  focus:outline-none focus:bg-white  "
            }
          >
            <input
              id={props.id}
              type="file"
              className="hidden"
              disabled={props.disabled}
              multiple={props.multiple}
              accept={
                props.justFile
                  ? "application/pdf"
                  : "image/x-png,image/gif,image/jpeg,image/jpg,image/ico,image/svg,video/mp4,video/avi,video/mov,video/wmv,video/flv"
              }
              onChange={(e) => {
                addFile(e.target.files);
              }}
            />
            <a
              onClick={() => {
                if (!props.disabled) document.getElementById(props.id).click();
              }}
              className="cursor-pointer float-left pr-3 py-1 mt-2 rounded-sm absolute focus:shadow-outline focus:outline-none"
            >
              <i className="ri-attachment-line text-mainText" />
            </a>
            <div
              id={props.file}
              className="customScroll pl-6 pr-6 flex items-center h-10 max-h-20  border-none bg-transparent  overflow-x-auto  outline-none focus:outline-none "
            >
              {newName !== "Dosya Seçiniz" &&
                newName.map((n, i) => {
                  return (
                    <div
                      key={i}
                      className="flex items-center border mr-3  border-borderGray2 py-1 px-2 rounded-md"
                    >
                      <i
                        onClick={() => onDeleteFile(n.name)}
                        className="ri-close-line mr-2 text-alert-red"
                      ></i>
                      <p className="mr-5">
                        {n.name.length < 30 ? (
                          n.name
                        ) : (
                          <a title={n.name}>
                            {n.name.substring(0, 30) + "..."}
                          </a>
                        )}
                      </p>
                    </div>
                  );
                })}
            </div>
            {/* <input
              type="text"
              id={props.file}
              className="pl-6 pr-6 float-left h-6 mt-1 border-none bg-transparent w-full outline-none focus:outline-none cursor-pointer"
              disabled
              value={newName}
            /> */}
            {/* <a
              href={
                props.defaultText !== null
                  ? 'http://api.thanos.makdos.com' + props.defaultText.split('thanos__fe_smt')[1]
                  : ''
              }
              className={
                'absolute right-0 inline-block w-8 h-6 mt-1 pl-2 mr-1 cursor-pointer text-base ' +
                (props.disabled
                  ? 'text-gray-500 border-gray-500'
                  : 'text-primary-hover border-border')
              }
              download={
                props.defaultText !== null
                  ? 'http://api.thanos.makdos.com' + props.defaultText.split('thanos__fe_smt')[1]
                  : ''
              }
              target="_blank"
            >
              <i className="ri-file-download-line" />
            </a> */}
          </div>
        </div>
        <label className="form-error text-sm w-full">{props.message}</label>
      </div>
    </div>
  );
}
