import TextBox from "components/FormElements/TextBox";
import PageSettings from "components/PageSettings/PageSettings";
import React, { useEffect, useState } from "react";
import { useGlobalStore } from "store/GlobalStore";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  getSiteSettings,
  updateSiteSettings,
  contentsAddFile,
} from "services/AllServices";
import { ToastContainer, toast } from "react-toastify";

const schema = yup.object().shape({
  site_title: yup.string(),
  site_slogan: yup.string(),
  site_logo: yup.string(),
  site_icon: yup.string(),
  facebook: yup.string(),
  twitter: yup.string(),
  instagram: yup.string(),
  linkedin: yup.string(),
  youtube: yup.string(),
});

function SiteSettingsEdit() {
  const [favIconFile, uploadFavIconFile] = useState();
  const [logoFile, uploadLogoFile] = useState();
  const [pageData, setPageData] = useState();

  const [selectedLanguage] = useGlobalStore((state) => [
    state.selectedLanguage,
  ]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (data) => {
    // let faviconUrl = pageData?.site_icon ? pageData?.site_icon : "";
    // let logoiconUrl = pageData?.site_logo ? pageData?.site_logo : "";
    // if (favIconFile) {
    //   for (const [key, value] of Object.entries(favIconFile)) {
    //     let params = new FormData();
    //     params.append("files", value);
    //     await contentsAddFile(selectedLanguage.value, params).then(
    //       (response) => {
    //         faviconUrl = response[0].file_path;
    //       }
    //     );
    //   }
    // } else {
    //   alert("Lütfen Resim1 Seçiniz..");
    // }

    // if (logoFile) {
    //   for (const [key, value] of Object.entries(logoFile)) {
    //     let params = new FormData();
    //     params.append("files", value);
    //     await contentsAddFile(selectedLanguage.value, params).then(
    //       (response) => {
    //         logoiconUrl = response[0].file_path;
    //       }
    //     );
    //   }
    // } else {
    //   alert("Lütfen Resim2 Seçiniz..");
    // }

    const configureData = {
      language: selectedLanguage.value,
      // site_title: data.site_title.replaceAll("'", "’"),
      // site_slogan: data.site_slogan.replaceAll("'", "’"),
      // site_logo: faviconUrl,
      // site_icon: logoiconUrl,
      facebook: data.facebook.replaceAll("'", "’"),
      twitter: data.twitter.replaceAll("'", "’"),
      instagram: data.instagram.replaceAll("'", "’"),
      linkedin: data.linkedin.replaceAll("'", "’"),
      youtube: data.youtube.replaceAll("'", "’"),
      can_see: data.can_see,
      last_operation_time: data.last_operation_time,
    };

    const notify = () => {
      toast.success("Kayıt Başarılı", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };
    //  if (faviconUrl !== "" && logoiconUrl !== "") {
    setTimeout(() => {
      updateSiteSettings(selectedLanguage.value, {
        content: configureData,
      }).then((res) => {
        notify();
      });
    }, 1000);
    //  }
  };

  useEffect(() => {
    getSiteSettings(selectedLanguage.value).then(async (res) => {
      reset(res);
      await setPageData(null);
      setPageData(res);
    });
  }, [selectedLanguage]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className=" flex justify-between">
        <div className="container pt-6">
          <div className="flex items-center justify-between">
            <h1 className="font-semibold text-2xl text-main">Site Ayarları</h1>
          </div>
          {/* <h2 className="font-semibold text-xl text-main mt-8 mb-4">
            Genel Ayarlar
          </h2>
          {pageData && (
            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-6">
                <TextBox
                  name="site_title"
                  label="Site Başlığı"
                  placeholder="CemOfset"
                  innerRef={{ ...register("site_title") }}
                  required
                />
              </div>
              <div className="col-span-6">
                <TextBox
                  name="site_slogan"
                  label="Site Sloganı"
                  placeholder="Portföy Yönetimi A.Ş."
                  innerRef={{ ...register("site_slogan") }}
                  required
                />
              </div>
              <div className="col-span-6">
                <FileUploader
                  id="site_logo"
                  file="fileUpload1"
                  name="site_logo"
                  uploadId="uploadId1"
                  value={logoFile}
                  defaultText="Dosya Seçiniz"
                  required="required"
                  setValue={uploadLogoFile}
                  label="Logo"
                  placeholder="Dosya seçiniz"
                  inputStyle="w-full mb-2 text-xs"
                  labelStyle="mt-3 mb-0"
                  multiple={false}
                  maxFile={10}
                  defaultValue={pageData?.site_logo}
                />
              </div>
              <div className="col-span-6">
                <FileUploader
                  id="favIconUpload"
                  file="favIconUpload"
                  name="site_icon"
                  uploadId="favIconUpload"
                  value={favIconFile}
                  defaultText="Dosya Seçiniz"
                  required="required"
                  setValue={uploadFavIconFile}
                  label="Favicon (Site İkonu)"
                  placeholder="Dosya seçiniz"
                  inputStyle="w-full mb-2 text-xs"
                  labelStyle="mt-3 mb-0"
                  multiple={false}
                  maxFile={10}
                  defaultValue={pageData?.site_icon}
                />
              </div>
            </div>
          )} */}

          <h2 className="font-semibold text-xl text-main mt-8 mb-4">
            Sosyal Medya Ayarları
          </h2>
          {pageData && (
            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-6">
                <TextBox
                  name="facebook"
                  label="Facebook"
                  placeholder="https://www.facebook.com/"
                  innerRef={{ ...register("facebook") }}
                  required
                />
              </div>
              <div className="col-span-6">
                <TextBox
                  name="twitter"
                  label="Twitter"
                  placeholder="https://www.twitter.com/"
                  innerRef={{ ...register("twitter") }}
                  required
                />
              </div>

              <div className="col-span-6">
                <TextBox
                  name="instagram"
                  label="Instagram"
                  placeholder="https://www.instagram.com/"
                  innerRef={{ ...register("instagram") }}
                  required
                />
              </div>
              <div className="col-span-6">
                <TextBox
                  name="linkedin"
                  label="Linkedin"
                  placeholder="https://www.linkedin.com/"
                  innerRef={{ ...register("linkedin") }}
                  required
                />
              </div>
              <div className="col-span-6">
                <TextBox
                  name="youtube"
                  label="Youtube"
                  placeholder="https://www.youtube.com/"
                  innerRef={{ ...register("youtube") }}
                  required
                />
              </div>
            </div>
          )}
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ToastContainer />

        <PageSettings date={pageData?.last_operation_time} />
      </div>
    </form>
  );
}

export default SiteSettingsEdit;
