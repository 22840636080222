import React from "react";

function HomeCard({ count, description, icon }) {
  return (
    <div className="w-full h-full px-10 py-8 bg-white hover:bg-hoverYellow flex justify-around items-center shadow-sm cursor-pointer transition-all duration-300 hover:shadow-xl group">
      {icon ? (
        <div className="w-14 h-14 bg-bgColor group-hover:bg-mainYellow transition-all duration-300  flex items-center justify-center rounded-full">
          <i
            className={`${icon} text-main text-2xl transition-all duration-300 group-hover:text-white`}
          ></i>
        </div>
      ) : (
        <div>
          <p className="text-main text-4xl font-bold transition-all duration-300 group-hover:text-white">
            {count}
          </p>
        </div>
      )}

      <div>
        <p className="ml-1 transition-all duration-300 group-hover:text-white">
          {description}
        </p>
      </div>
    </div>
  );
}

export default HomeCard;
