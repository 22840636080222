import TextBox from "components/FormElements/TextBox";
import React, { useEffect, useState } from "react";
import TextArea from "components/FormElements/TextArea";
import PageSettings from "components/PageSettings/PageSettings";
import SeoSettings from "components/SeoSettings/SeoSettings";
import { createSlideRecords } from "services/AllServices";
import { useGlobalStore } from "store/GlobalStore";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useSlugify } from "hooks";

const schema = yup.object().shape({
  slide_title: yup.string(),
  first_button_text: yup.string(),
  first_button_url: yup.string(),
  second_button_text: yup.string(),
  second_button_url: yup.string(),
  slide_description: yup.string(),
  can_see: yup.bool(),
});
function SlideCreate(props) {
  const history = useHistory();
  const [file, uploadFile] = useState();
  const [newFile, setNewFile] = useState();
  const [pageTitleSlug, setPageTitleSlug] = useSlugify();
  const [pageTitle, setPageTitle] = useState("");

  const [selectedLanguage] = useGlobalStore((state) => [
    state.selectedLanguage,
  ]);
  const id = props?.history?.location?.state?.id
    ? props.history.location?.state?.id
    : null;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const notify = () => {
    toast.success("Kayıt Başarılı", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onSubmit = async (data) => {
    const configureData = {
      language: selectedLanguage.value,
      slide_title: data.slide_title.replaceAll("'", "’"),
      slide_description: data.slide_description.replaceAll("'", "’"),
      first_button_text: data.first_button_text.replaceAll("'", "’"),
      first_button_url: data.first_button_url.replaceAll("'", "’"),
      second_button_text: data.second_button_text.replaceAll("'", "’"),
      second_button_url: data.second_button_url.replaceAll("'", "’"),
      order: null,
    };
    createSlideRecords(configureData).then((res) => {
      notify();
      history.push("/slaytlari-goruntule");
    });
  };

  useEffect(() => {
    setPageTitleSlug(pageTitle);
  }, [pageTitle]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-between ">
        <div className="container pr-4 pt-6 pb-6 h-screen overflow-y-auto ">
          <h1 className="font-semibold text-2xl text-main mb-5">
            Slayt {id ? "Düzenle" : "Oluştur"}
          </h1>
          <TextBox
            style="mb-5"
            name="slide_title"
            label="Başlık"
            placeholder="Slayt Başlığı"
            innerRef={{ ...register("slide_title") }}
            required
            maxLength={40}
          />
          {/* {id && newFile && (
            <FileUploader
              id="download1"
              file="fileUpload1"
              name="download1"
              uploadId="uploadId1"
              value={file}
              defaultText="Dosya Seçiniz"
              required="required"
              setValue={uploadFile}
              label="Slayt Görseli"
              placeholder="Dosya seçiniz"
              inputStyle="w-full mb-2 text-xs"
              labelStyle="mt-3 mb-0"
              multiple={false}
              maxFile={10}
              defaultValue={newFile}
            />
          )}
          {id === null && (
            <FileUploader
              id="download1"
              file="fileUpload1"
              name="download1"
              uploadId="uploadId1"
              value={file}
              defaultText="Dosya Seçiniz"
              required="required"
              setValue={uploadFile}
              label="Slayt Görseli"
              placeholder="Dosya seçiniz"
              inputStyle="w-full mb-2 text-xs"
              labelStyle="mt-3 mb-0"
              multiple={false}
              maxFile={10}
            />
          )}

          <p className="text-sm text-mainText">
            Yükleyeceğiniz görselin boyutu 623 x 404 boyutunda olmalıdır.
          </p> */}

          <div className="grid grid-cols-2 gap-5 mb-5">
            <div className="col-span-1 ">
              <TextBox
                name="first_button_text"
                label="Buton Yazısı"
                placeholder="Detaylı Bilgi İsteyin"
                innerRef={{ ...register("first_button_text") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="first_button_url"
                label="Buton URL Adresi"
                placeholder="/contact-us"
                innerRef={{ ...register("first_button_url") }}
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-5">
            <div className="col-span-1">
              <TextBox
                name="second_button_text"
                label="Buton Yazısı"
                placeholder="Detaylı Bilgi İsteyin"
                innerRef={{ ...register("second_button_text") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="second_button_url"
                label="Buton URL Adresi"
                placeholder="/contact-us"
                innerRef={{ ...register("second_button_url") }}
                required
              />
            </div>
          </div>

          <TextArea
            name="slide_description"
            label="Slayt Yazısı"
            placeholder="Slayt Alt Yazısı"
            style="mt-3 mb-3"
            rows="7"
            innerRef={{ ...register("slide_description") }}
            required
            maxLength={180}
          />
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ToastContainer />

        <PageSettings hideLanguage={id ? true : false} />
      </div>
    </form>
  );
}

export default SlideCreate;
