import React, { useState } from "react";
import { useTable, useSortBy } from "react-table";
import { useHistory } from "react-router-dom";

function FixedPagesTable({ setOpenErrorModal, setOpenEditMail }) {
  const history = useHistory();

  const data = React.useMemo(
    () => [
      {
        col1: "Anasayfa",
        // col2: "",
        // col3: "",
      },
      {
        col1: "Hakkımızda",
        // col2: "",
        // col3: "",
      },
      {
        col1: "Ödüllerimiz",
        // col2: "",
        // col3: "",
      },
      {
        col1: "Referanslarımız",
        // col2: "",
        // col3: "",
      },
      {
        col1: "Sertifikalarımız",
        // col2: "",
        // col3: "",
      },
      {
        col1: "Sorumluluklarımız",
        // col2: "",
        // col3: "",
      },
      {
        col1: "İletişim",
        // col2: "",
        // col3:"" ,
      },
    ],
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Sayfa Başlığı",
        accessor: "col1",
      },
      {
        Header: "Aksiyonlar",
        accessor: "col2",
        Cell: settings,
      },

      // {
      //   Header: "Düzenleme Tarihi",
      //   accessor: "col2",
      // },
      // {
      //   Header: "Dil",
      //   accessor: "col3",
      // },
    ],
    []
  );

  function settings(props) {
    return (
      <div className="flex items-center justify-left">
        {/* <i className="ri-eye-fill text-xl text-secondary cursor-pointer"></i> */}
        <div
          className="flex items-center  cursor-pointer"
          onClick={() => goPage(props.row.original.col1)}
        >
          <i className="ri-edit-box-fill text-lg text-menuText  mr-1"></i>
          <p className="  text-secondary">Düzenle</p>
        </div>
      </div>
    );
  }

  const goPage = (value) => {
    if (value === "Anasayfa") {
      history.push("/anasayfa-duzenle");
    }
    if (value === "Hakkımızda") {
      history.push("/hakkimizda-duzenle");
    }
    if (value === "Ödüllerimiz") {
      history.push("/odullerimiz-duzenle");
    }
    if (value === "Referanslarımız") {
      history.push("/referanslarimiz-duzenle");
    }
    if (value === "Sertifikalarımız") {
      history.push("/sertifikalarimiz-duzenle");
    }
    if (value === "Sorumluluklarımız") {
      history.push("/sorumluluklarimiz-duzenle");
    }
    if (value === "İletişim") {
      history.push("/iletisim-duzenle");
    }
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <table {...getTableProps()} className="w-full">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {/* {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps()}
                                className="bg-main text-white p-3 text-left text-sm"
                            >
                                {column.render('Header')}
                            </th>
                        ))} */}
            {headerGroup.headers.map((column) => (
              <th
                className="bg-mainDark text-white p-3 text-left text-sm"
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render("Header")}
                <span className="flex relative justify-between items-center">
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <svg
                        className="absolute right-0 -top-3"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 6L0.669873 0.749999L9.33013 0.75L5 6Z"
                          fill="#D8D8D8"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="absolute right-0 -top-3"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 0L9.33013 5.25L0.669873 5.25L5 0Z"
                          fill="#D8D8D8"
                        />
                      </svg>
                    )
                  ) : (
                    ""
                  )}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className={index % 2 === 0 ? " bg-white p-2" : " bg-bgColor p-2"}
            >
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()} className="p-2 text-mainText">
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default FixedPagesTable;
