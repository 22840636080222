import IconTextBox from "components/FormElements/IconTextBox";
import MainButton from "components/FormElements/MainButton";
import SecondaryButton from "components/FormElements/SecondaryButton";
import TextBox from "components/FormElements/TextBox";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  logout,
  updateStaffMail,
  updateStaffPassword,
} from "services/AllServices";
import { useAuthStore } from "store/AuthStore";
import { useHistory } from "react-router-dom";

const schema = yup.object().shape({
  fullname: yup.string(),
  email: yup.string(),
  password: yup.string(),
});

function ChangeMail() {
  const [isLoggin, setIsLoggin, setLogginOpen, userInformations] = useAuthStore(
    (state) => [
      state.isLoggin,
      state.setIsLoggin,
      state.setLogginOpen,
      state.userInformations,
    ]
  );
  const history = useHistory();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onLogOut = () => {
    logout()
      .then((res) => {
        setIsLoggin(false);
        localStorage.clear();
        history.push("/login");
      })
      .catch(setIsLoggin(false), localStorage.clear(), history.push("/login"));
  };

  const onChangeMail = (data) => {
    let configure = {
      password: data.fullname,
      email: data.email,
      password: data.password,
    };
    updateStaffMail(configure).then((res) => {
      if (res.status === "incorrect_password") {
        alert("Lütfen şifrenizi doğru giriniz.");
      } else {
        alert("Başarılı Yeni Bilgilerinizle Giriş Yapabilirsiniz.");
        onLogOut();
      }
    });
  };

  useEffect(() => {
    if (userInformations) {
      reset(userInformations);
    }
  }, []);
  return (
    <div className="mt-10">
      <h1 className="font-semibold text-2xl text-main ">Hesap Ayarları</h1>
      <form onSubmit={handleSubmit(onChangeMail)}>
        <TextBox
          label="Ad Soyad"
          name="staff_fullname"
          placeholder="Adınız Soyadınız"
          style="mt-6"
          innerRef={{ ...register("fullname") }}
          required
        />

        <TextBox
          label="E-posta Adresi"
          name="email"
          placeholder="info@alanadi.com"
          style="mt-4"
          innerRef={{ ...register("email") }}
          required
        />

        <IconTextBox
          label="Parola"
          name="password"
          type="password"
          placeholder="•••••••"
          iconChange={true}
          textBoxIcon="ri-eye-line"
          textBoxSecondIcon="ri-eye-off-line"
          style="mt-4"
          innerRef={{ ...register("password") }}
          required
        />
        <p className="text-xs text-mainText mt-4">
          Değişikliğin yapılabilmesi için paralonızı doğru giriniz.
        </p>
        <MainButton style="mt-4 w-full" left text="Kaydet">
          <i className="ri-checkbox-circle-fill text-white mr-2"></i>
        </MainButton>
      </form>
    </div>
  );
}

export default ChangeMail;
