import React from "react";

function FileCard({
  name,
  inf1,
  inf2,
  innerRef,
  style,
  datas,
  deleteClick,
  editClick,
}) {
  return (
    <div
      ref={innerRef}
      className={`bg-white w-full shadow-md p-3 flex items-center justify-between ${
        style && style
      }`}
    >
      <div className="flex items-center">
        <i className="ri-menu-fill mr-3"></i>
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="56"
            height="56"
            rx="28"
            fill="#218F96"
            fillOpacity="0.1"
          />
          <path
            d="M37 24V36.993C37.0009 37.1243 36.976 37.2545 36.9266 37.3762C36.8772 37.4979 36.8043 37.6087 36.7121 37.7022C36.6199 37.7957 36.5101 37.8701 36.3892 37.9212C36.2682 37.9723 36.1383 37.9991 36.007 38H19.993C19.7298 38 19.4774 37.8955 19.2912 37.7095C19.105 37.5235 19.0003 37.2712 19 37.008V18.992C19 18.455 19.449 18 20.002 18H30.997L37 24ZM35 25H30V20H21V36H35V25Z"
            fill="#18474A"
          />
        </svg>

        <div className="ml-5">
          <p className="text-main text-lg font-semibold mb-1">{name}</p>
          <div className="flex items-end">
            <p className="text-xs text-menuText">Dosya: {inf1}</p>
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <div
          onClick={() => deleteClick(datas.order)}
          className="w-8 h-8 flex items-center justify-center bg-alertRedBg cursor-pointer mr-3"
        >
          <i className="ri-delete-bin-fill text-alertRed "></i>
        </div>
        {/* <div className="w-8 h-8 flex items-center justify-center bg-secondaryButtonBg cursor-pointer mr-3">
          <i className="ri-eye-fill text-main "></i>
        </div> */}
        <div
          onClick={() => editClick(datas)}
          className="w-8 h-8 flex items-center justify-center bg-secondaryButtonBg cursor-pointer"
        >
          <i className="ri-edit-box-fill text-main "></i>
        </div>
      </div>
    </div>
  );
}

export default FileCard;
