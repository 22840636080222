import React, { useState } from "react";
import { useTable, useSortBy } from "react-table";
import { useGlobalStore } from "store/GlobalStore";
import moment from "moment/min/moment-with-locales";

function ProductsTable({ datas, deleteFunc, editFunc }) {
  const [passID, setPassID] = useState();
  const data = React.useMemo(() => datas, []);

  const [setSelectedLanguage] = useGlobalStore((state) => [
    state.setSelectedLanguage,
  ]);
  const columns = React.useMemo(
    () => [
      {
        Header: "Ürün Başlığı",
        accessor: "product_content[0].product_title",
      },
      {
        Header: "Güncelleme Tarihi",
        accessor: "product_content[0].last_operation_time",
        Cell: (props) =>
          props.value !== null
            ? moment(props.value).format("DD/MM/YYYY , h:mm:ss")
            : "-",
      },
      {
        Header: "Aksiyonlar",
        accessor: "id",
        Cell: settings,
      },
    ],
    []
  );

  function settings(props) {
    return (
      <div className="flex items-center ml-2 ">
        <i
          onClick={() => deleteFunc(props.value)}
          className="ri-delete-bin-fill text-alertRed cursor-pointer"
        ></i>
        {/* <i className="ri-eye-fill text-secondary cursor-pointer ml-3"></i> */}
        <i
          onClick={() => {
            setSelectedLanguage(
              props.row.original.language === "tr"
                ? { id: 1, text: "Türkçe", value: "tr" }
                : { id: 2, text: "İngilizce", value: "en" }
            );
            editFunc(props.row.original.id);
          }}
          className="ri-edit-box-fill ml-3 text-secondary cursor-pointer"
        ></i>
      </div>
    );
  }
  function visibled(props) {
    return <p>{props.value === true ? "Yayında" : "Gizli"}</p>;
  }

  function language(props) {
    return <p>{props.value === "tr" ? "Türkçe" : "İngilizce"}</p>;
  }
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <table {...getTableProps()} className="w-full">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {/* {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps()}
                                className="bg-main text-white p-3 text-left text-sm"
                            >
                                {column.render('Header')}
                            </th>
                        ))} */}
            {headerGroup.headers.map((column) => (
              <th
                className="bg-mainDark text-white p-3 text-left text-sm"
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render("Header")}
                <span className="flex relative justify-between items-center">
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <svg
                        className="absolute right-0 -top-3"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 6L0.669873 0.749999L9.33013 0.75L5 6Z"
                          fill="#D8D8D8"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="absolute right-0 -top-3"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 0L9.33013 5.25L0.669873 5.25L5 0Z"
                          fill="#D8D8D8"
                        />
                      </svg>
                    )
                  ) : (
                    ""
                  )}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className={index % 2 === 0 ? " bg-white p-2" : " bg-bgColor p-2"}
            >
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()} className="p-2 text-mainText">
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default ProductsTable;
