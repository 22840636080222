import React from "react";

function RedButton({ text, style, children, type, left, onClick }) {
  return (
    <button
      className={`bg-alertRed font-semibold flex items-center justify-center text-white hover:opacity-75 py-3 px-4 cursor-pointer transition-all duration-300 ${
        style && style
      }`}
      type={type && type}
      onClick={onClick && onClick}
    >
      {left && children}
      {text}
      {!left && children}
    </button>
  );
}

export default RedButton;
