export function isNullOrEmptyOrUndefined(item) {
  return item === null || item === "" || typeof item === "undefined";
}

//for data with language
export const combineData = async (data) => {
  let result = [];
  if (!isNullOrEmptyOrUndefined(data))
    if (typeof data.length !== "undefined")
      await data.map((d) => {
        result.push({
          id: d.id,
          language: d.language,
          order: d.order,
          ...d.content,
        });
      });
    else
      result = {
        ...data.content,
        id: data.id,
        order: data.order,
        language: data.language,
      };
  return result;
};

//for data without language
export const combineIDContent = async (data) => {
  let result = [];
  if (!isNullOrEmptyOrUndefined(data))
    if (typeof data.length !== "undefined")
      await data.map((d) => {
        result.push({ id: d.id, ...d.content });
      });
    else result = { ...data.content, id: data.id };
  return result;
};
