import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import MainButton from "components/FormElements/MainButton";
import { useAuthStore } from "store/AuthStore";
import { getStaff, getStaffOwn, login } from "services/AllServices";
import { Link } from "react-router-dom";

const schema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
});

function Login() {
  const history = useHistory();

  const [changeIcon, setChangeIcon] = useState(false);

  const [isLoggin, setIsLoggin, setLogginOpen, setUserInformations] =
    useAuthStore((state) => [
      state.isLoggin,
      state.setIsLoggin,
      state.setLogginOpen,
      state.setUserInformations,
    ]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onLogin = (onSubmit) => {
    //we do this instead of api below
    // setIsLoggin(true);
    // history.push("/");
    //we do this instead of api above

    login(onSubmit)
      .then((data) => {
        if (data.access_token) {
          setIsLoggin(true);
          getStaffOwn().then((res) => {
            let configureInfo = {
              fullname: res.fullname,
              email: res.email,
              is_admin: res.is_admin,
            };
            setUserInformations(configureInfo);
            localStorage.setItem(
              "__userInformations",
              JSON.stringify(configureInfo)
            );
          });
          localStorage.setItem("__mp_tk", data.access_token);
          history.push("/");
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401 || err?.response?.status === 400) {
          alert("Kullanıcı adı veya şifre hatalı");
        }
      });
  };

  useEffect(() => {
    if (isLoggin) {
      history.push("/");
    }
  }, []);

  return (
    <div className=" h-screen flex items-center">
      <div className="grid grid-cols-4 h-screen w-full">
        <div className="col-span-4 lg:col-span-1 ">
          <div className="w-full px-4 lg:px-14 flex flex-col justify-center  h-screen  ">
            <img
              src="/cemofsetlogo.png"
              className="mx-auto"
              width="200"
              height="82"
            />

            <form onSubmit={handleSubmit(onLogin)} className="mt-16 ">
              <div className="flex flex-col   mt-5 lg:mt-0 w-full">
                <label className="mb-1 text-mainText text-sm ">
                  Kullanıcı Adı
                </label>
                <div className="flex items-center justify-center relative">
                  <input
                    {...register("email")}
                    className="w-full border-2 border-secondaryText h-10 px-3 text-sm focus:outline-none focus:border-hoverYellow focus:ring-0 rounded-md"
                    type="text"
                    name="email"
                    placeholder="info@cemofset.com"
                  />
                </div>
              </div>
              <div className="flex flex-col  mt-5 lg:mt-5">
                <label className="mb-1 text-mainText text-sm ">Şifre</label>
                <div className="flex items-center relative">
                  <input
                    {...register("password")}
                    className="w-full border-2 border-secondaryText h-10 px-3 text-sm focus:outline-none focus:border-hoverYellow focus:ring-0 rounded-md"
                    type={!changeIcon ? "password" : "text"}
                    name="password"
                    placeholder="••••••••"
                  />
                  <div
                    onClick={() => setChangeIcon(!changeIcon)}
                    className="w-12 flex justify-center  py-2 rounded-md rounded-l-none absolute right-0 cursor-pointer"
                  >
                    <div className="mr-2 bg-makdosSecondaryGray w-0.5 h-5"></div>
                    <i
                      className={
                        changeIcon
                          ? "ri-eye-line text-secondaryText"
                          : "ri-eye-off-line text-secondaryText"
                      }
                    ></i>
                  </div>
                </div>
              </div>

              <div>
                {/* <div className="flex">
                  <label className="inline-flex items-center mt-4">
                    <input
                      type="checkbox"
                      className="form-checkbox h-4 w-4 text-main  border focus:ring-0"
                    />
                    <span className="w-full ml-2 text-cardText text-sm cursor-pointer">
                      Oturumu Açık Tut
                    </span>
                  </label>
                </div> */}
              </div>
              <div className="mt-6">
                <MainButton text="Giriş Yap" type="submit" style="w-full" />
              </div>
              <div>
                <Link to={"/forget-password"}>
                  <p className="text-center text-mainText cursor-pointer hover:opacity-75 mt-3">
                    Parolamı Unuttum
                  </p>
                </Link>
              </div>

              {/* <p className="text-sm text-main mt-3 cursor-pointer">Forgot your password ?</p> */}
            </form>
          </div>
        </div>
        <div className=" hidden lg:flex  lg:col-span-3  w-full h-full ">
          <img
            src="/loginimg.png"
            className="h-screen"
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </div>
  );
}

export default Login;
