import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import MainButton from "components/FormElements/MainButton";
import { useAuthStore } from "store/AuthStore";
import {
  getStaff,
  getStaffOwn,
  login,
  rememberPasswordNewPassword,
  rememberPasswordSendMail,
} from "services/AllServices";
import { Link } from "react-router-dom";

function ForgetPassword() {
  const history = useHistory();
  const [changeIcon, setChangeIcon] = useState(false);
  const [changeIcon2, setChangeIcon2] = useState(false);

  const [email, setEmail] = useState();
  const [validationCode, setValidationCode] = useState();
  const [password, setPassword] = useState();
  const [passwordAgain, setPasswordAgain] = useState();

  const [changePass, setChangePass] = useState(false);

  const [isLoggin, setIsLoggin, setLogginOpen, setUserInformations] =
    useAuthStore((state) => [
      state.isLoggin,
      state.setIsLoggin,
      state.setLogginOpen,
      state.setUserInformations,
    ]);

  const onSendMail = () => {
    rememberPasswordSendMail(email).then((res) => {
      if (res.status === "staff_not_found") {
        alert("Girdiğiniz E-Posta Sistemde Kayıtlı Değil.");
      } else {
        setChangePass(true);
      }
    });
  };

  const onChangePassword = () => {
    if (password === passwordAgain) {
      rememberPasswordNewPassword(email, validationCode, password).then(
        (res) => {
          alert("Başarılı, Yeni şifreniz ile giriş yapabilirsiniz.");
          history.push("/login");
        }
      );
    } else {
      alert("Şifreler Eşleşmiyor.");
    }
  };

  useEffect(() => {
    if (isLoggin) {
      history.push("/");
    }
  }, []);

  return (
    <div className=" h-screen flex items-center">
      <div className="grid grid-cols-4 h-screen">
        <div className="col-span-4 lg:col-span-1 ">
          <div className="w-full px-4 lg:px-14 flex flex-col justify-center  h-screen  ">
            {/* <img
              src="/cemofsetlogo.png"
              className="mx-auto"
              width="200"
              height="82"
            /> */}

            {!changePass ? (
              <div className="flex flex-col   mt-8  w-full">
                <h2 className="text-3xl mb-3 text-main font-semibold">
                  Parola Hatırlatma
                </h2>
                <p className="text-mainText text-sm mb-6">
                  Parola sıfırlama iletisi e-posta adresiniz eğer kayıtlıysa
                  gönderilecektir.
                </p>
                <label className="mb-1 text-mainText text-sm ">E-Posta</label>
                <div className="flex items-center justify-center relative">
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full border-2 border-secondaryText h-10 px-3 text-sm focus:outline-none focus:border-mainYellow focus:ring-0 rounded-md"
                    type="text"
                    name="email"
                    placeholder="info@cemofset.net"
                    required
                  />
                </div>
                <div className="mt-6">
                  <MainButton
                    onClick={() => onSendMail()}
                    text="Parola Sıfırlama Gönder"
                    type="submit"
                    style="w-full"
                  />
                </div>
              </div>
            ) : (
              <div className="flex flex-col   mt-8  w-full">
                <h2 className="text-3xl mb-3 text-main font-semibold">
                  Parola Yenileme
                </h2>
                <p className="text-mainText text-sm mb-6">
                  E-Posta adresinize gelen kodu aşağıya girerek yeni şifrenizi
                  oluşturabilirsiniz.
                </p>
                <label className="mb-1 text-mainText text-sm ">E-Posta</label>
                <div className="flex items-center justify-center relative">
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full border-2 border-secondaryText h-10 px-3 text-sm focus:outline-none focus:border-mainYellow focus:ring-0 rounded-md"
                    type="text"
                    name="email"
                    placeholder="info@cemofset.net"
                    required
                  />
                </div>

                <label className="mb-1 mt-5 text-mainText text-sm ">
                  E-Posta Onay Kodu
                </label>
                <div className="flex items-center justify-center relative">
                  <input
                    value={validationCode}
                    onChange={(e) => setValidationCode(e.target.value)}
                    className="w-full border-2 border-secondaryText h-10 px-3 text-sm focus:outline-none focus:border-mainYellow focus:ring-0 rounded-md"
                    type="text"
                    name="code"
                    placeholder="123456"
                    required
                  />
                </div>

                <div className="flex flex-col  mt-5 lg:mt-5">
                  <label className="mb-1 text-mainText text-sm ">
                    Yeni Şifre
                  </label>
                  <div className="flex items-center relative">
                    <input
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-full border-2 border-secondaryText h-10 px-3 text-sm focus:outline-none focus:border-mainYellow focus:ring-0 rounded-md"
                      type={!changeIcon ? "password" : "text"}
                      name="password"
                      placeholder="••••••••"
                    />
                    <div
                      onClick={() => setChangeIcon(!changeIcon)}
                      className="w-12 flex justify-center  py-2 rounded-md rounded-l-none absolute right-0 cursor-pointer"
                    >
                      <div className="mr-2 bg-makdosSecondaryGray w-0.5 h-5"></div>
                      <i
                        className={
                          changeIcon
                            ? "ri-eye-line text-secondaryText"
                            : "ri-eye-off-line text-secondaryText"
                        }
                      ></i>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col  mt-5 lg:mt-5">
                  <label className="mb-1 text-mainText text-sm ">
                    Yeni Şifre Tekrar
                  </label>
                  <div className="flex items-center relative">
                    <input
                      value={passwordAgain}
                      onChange={(e) => setPasswordAgain(e.target.value)}
                      className="w-full border-2 border-secondaryText h-10 px-3 text-sm focus:outline-none focus:border-mainYellow focus:ring-0 rounded-md"
                      type={!changeIcon2 ? "password" : "text"}
                      name="password"
                      placeholder="••••••••"
                    />
                    <div
                      onClick={() => setChangeIcon2(!changeIcon2)}
                      className="w-12 flex justify-center  py-2 rounded-md rounded-l-none absolute right-0 cursor-pointer"
                    >
                      <div className="mr-2 bg-makdosSecondaryGray w-0.5 h-5"></div>
                      <i
                        className={
                          changeIcon2
                            ? "ri-eye-line text-secondaryText"
                            : "ri-eye-off-line text-secondaryText"
                        }
                      ></i>
                    </div>
                  </div>
                </div>
                <div className="mt-6">
                  <MainButton
                    onClick={() => onChangePassword()}
                    text="Parola Sıfırlama Gönder"
                    type="submit"
                    style="w-full"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className=" hidden lg:flex  lg:col-span-3  w-full h-full">
          <img
            src="/loginimg.png"
            className=" h-screen"
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
