import MainButton from "components/FormElements/MainButton";
import DeleteModal from "components/Modals/DeleteModal";
import PoliticsTable from "components/Policies/PoliciesTable";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { getPoliciesList, deletePolicies, logout } from "services/AllServices";
import { useAuthStore } from "store/AuthStore";

function PoliticList() {
  const [policyList, setPolicyList] = useState();
  const history = useHistory();
  const [limit, setLimit] = useState(20);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState(false);

  const [setIsLoggin] = useAuthStore((state) => [state.setIsLoggin]);

  // const onLogOut = () => {
  //   logout()
  //     .then((res) => {
  //       setIsLoggin(false);
  //       localStorage.clear();
  //       history.push("/login");
  //     })
  //     .catch(setIsLoggin(false), localStorage.clear(), history.push("/login"));
  // };

  const notify = () => {
    toast.success("Başarılı", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onEditPolicy = (id) => {
    history.push({
      pathname: "/politika-duzenle",
      state: { id: id },
    });
  };

  const onDeletePolicy = (id) => {
    deletePolicies(id).then((res) => {
      notify();
      onListPolicy();
    });
  };

  const onListPolicy = () => {
    getPoliciesList(limit).then((res) => {
      setPolicyList();
      setPolicyList(res);
    });
    // .catch((err) => {
    //    onLogOut();
    // });
  };

  useEffect(() => {
    onListPolicy();
  }, [limit]);

  return (
    <div className="container pt-6 overflow-y-auto h-screen">
      <div className="flex items-center justify-between">
        <h1 className="font-semibold text-2xl text-main">
          Politikaları Görüntüle
        </h1>
        <Link to="politika-olustur">
          <MainButton className="" left text="Politika Oluştur">
            <i className="ri-file-upload-fill text-white mr-2"></i>
          </MainButton>
        </Link>
      </div>
      <div className="mt-10">
        {policyList && policyList.status !== "report_not_found" && (
          <>
            <PoliticsTable
              datas={policyList}
              deleteFunc={(id) => {
                setDeleteID(id);
                setOpenDeleteModal(true);
              }}
              editFunc={(id) => {
                onEditPolicy(id);
              }}
            />
            <div
              onClick={() => setLimit(limit + 10)}
              className="w-full border border-secondBg bg-white flex items-center justify-center py-2 mt-8 cursor-pointer transition-all group duration-300 hover:bg-mainDark hover:text-white"
            >
              <i className="ri-restart-line text-mainText font-semibold mr-4 transition-all duration-300 group-hover:text-white"></i>
              <p className="text-mainText font-semibold transition-all duration-300 group-hover:text-white">
                DAHA FAZLA YÜKLE
              </p>
            </div>
          </>
        )}
      </div>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />

      {openDeleteModal && (
        <DeleteModal
          setOpenModal={() => setOpenDeleteModal(false)}
          title="Politika Sil"
          question="Politika silmek istediğinize emin misiniz ?"
          buttonText="Sil"
          notify={() => notify()}
          onClick={() => {
            onDeletePolicy(deleteID);
            setOpenDeleteModal(false);
          }}
          id={deleteID}
        />
      )}
    </div>
  );
}

export default PoliticList;
