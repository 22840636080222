import AlertWarning from "components/ContentAlerts/AlertWarning";
import TextArea from "components/FormElements/TextArea";
import TextBox from "components/FormElements/TextBox";
import React from "react";

function SeoSettings({ register }) {
  return (
    <div className="mt-5">
      <h2 className="font-semibold text-2xl text-main mb-5">SEO Ayarları</h2>
      <TextBox
        name="seo_title"
        label="SEO Başlığı"
        placeholder="Başlık Giriniz"
        innerRef={register && { ...register("seo_title") }}
        required
        maxLength="60"
      />
      <TextArea
        name="seo_description"
        label="SEO Açıklaması"
        placeholder="SEO Açıklaması Giriniz.."
        style="mt-3 mb-3"
        rows="4"
        maxLength="160"
        innerRef={register && { ...register("seo_description") }}
        required
      />
      <AlertWarning
        title="Dikkat"
        description="SEO başlık metni 60 ve SEO açıklama metni 160 karakteri geçmemelidir."
        style="mt-4"
      />
    </div>
  );
}

export default SeoSettings;
