import FileUploader from "components/FormElements/FileUploader";
import TextBox from "components/FormElements/TextBox";
import React, { useEffect, useState } from "react";
import TextArea from "components/FormElements/TextArea";
import PageSettings from "components/PageSettings/PageSettings";
import SeoSettings from "components/SeoSettings/SeoSettings";
import { useGlobalStore } from "store/GlobalStore";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import {
  getRewards,
  updateRewards,
  contentsAddFile,
} from "services/AllServices";
import { ToastContainer, toast } from "react-toastify";
import AddMediaModal from "components/Modals/AddMediaModal";
import EditFileModal from "components/Modals/EditFileModal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FileCard from "components/Cards/FileCard";
import SecondaryButton from "components/FormElements/SecondaryButton";

const schema = yup.object().shape({
  reward_title: yup.string(),
  reward_content: yup.string(),
  reward_image: yup.string(),
  reward_image_title: yup.string(),
  can_see: yup.bool(),

  seo_title: yup.string(),
  seo_description: yup.string(),
});

function RewardsPageEdit() {
  const [file, uploadFile] = useState();
  const [pageData, setPageData] = useState();
  const [allImageList, setAllImageList] = useState([]);
  const [maxOrder, setMaxOrder] = useState();

  const [activityReportData, setActivityReportData] = useState(null);
  const [openAddCardModal, setOpenAddCardModal] = useState(false);
  const [openEditCardModal, setOpenEditCardModal] = useState(false);
  const [selectedEditCard, setselectedEditCard] = useState();

  const [selectedLanguage] = useGlobalStore((state) => [
    state.selectedLanguage,
  ]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const notify = () => {
    toast.success("Kayıt Başarılı", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onSubmit = async (data) => {
    let fileUrl = "";
    if (!file?.isOld)
      if (file) {
        for (const [key, value] of Object.entries(file)) {
          let params = new FormData();
          params.append("files", value);
          await contentsAddFile(selectedLanguage.value, params).then(
            (response) => {
              fileUrl = response[0].file_path;
              notify();
            }
          );
        }
      } else {
        alert("Lütfen Resim Seçiniz..");
      }

    const configureData = {
      language: selectedLanguage.value,
      reward_title: data.reward_title.replaceAll("'", "’"),
      reward_content: data.reward_content.replaceAll("'", "’"),
      reward_image: fileUrl !== "" ? fileUrl : file.name,
      reward_image_title: data.reward_image_title,
      // image_list: allImageList,
      can_see: data.can_see,

      seo_title: data.seo_title.replaceAll("'", "’"),
      seo_description: data.seo_description.replaceAll("'", "’"),
      last_operation_time: data.last_operation_time,
    };
    await updateRewards(selectedLanguage.value, {
      content: configureData,
    }).then((res) => {
      notify();
      onGetRewards();
    });
  };

  const onGetRewards = () => {
    getRewards(selectedLanguage.value).then(async (res) => {
      reset(res);
      await setPageData(null);
      setPageData(res);
      uploadFile({ isOld: true, name: res.reward_image });
    });
  };

  useEffect(() => {
    onGetRewards();
  }, [selectedLanguage]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between ">
          <div className="container pr-4 pt-6 pb-6 h-screen overflow-y-auto ">
            <h1 className="font-semibold text-2xl text-main mb-5">
              Ödüllerimiz Düzenle
            </h1>
            {/* Aboutus section is below */}
            <h2 className="font-semibold text-xl text-main mt-5 mb-5">
              Ödüllerimiz Kısmı
            </h2>
            <TextBox
              name="reward_title"
              label="Başlık Metni"
              placeholder="Ödüllü Baskı Deneyimi"
              innerRef={{ ...register("reward_title") }}
              required
            />
            <TextArea
              name="reward_content"
              label="İçerik Metni"
              placeholder="Alanında bir çok dalda ödül almış ve yılların tecrübesine sahip firmayız."
              style="mt-3 mb-3"
              rows="7"
              innerRef={{ ...register("reward_content") }}
              required
            />
            {/* 
            {allImageList && allImageList.length != 0 && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {allImageList?.map((item, index) => {
                        return (
                          <Draggable
                            key={item?.order?.toString()}
                            draggableId={item?.order?.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="mb-5"
                              >
                                <FileCard
                                  name="Dosya"
                                  datas={item}
                                  id={item.order}
                                  inf1={item.file_path}
                                  deleteClick={(order) => onDeleteCard(order)}
                                  editClick={(datas) => {
                                    setOpenEditCardModal(true);
                                    setselectedEditCard(datas);
                                  }}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
            <SecondaryButton
              onClick={() => setOpenAddCardModal(true)}
              type="button"
              left
              text="YENİ ALAN EKLE"
              style="w-full"
            >
              <i className="ri-add-circle-fill text-lg text-secondary mr-2"></i>
            </SecondaryButton> */}

            <div className="grid grid-cols-12 gap-5 items-center">
              <div className="col-span-12 lg:col-span-10 ">
                <div className="flex flex-col">
                  {pageData && (
                    <FileUploader
                      id="download1"
                      file="fileUpload1"
                      name="reward_image"
                      uploadId="uploadId1"
                      value={file}
                      defaultText="Dosya Seçiniz"
                      required="required"
                      setValue={uploadFile}
                      label="Görsel"
                      placeholder="Dosya seçiniz"
                      inputStyle="w-full mb-2 text-xs"
                      labelStyle="mt-3 mb-0"
                      multiple={false}
                      maxFile={10}
                      defaultValue={pageData?.reward_image}
                    />
                  )}
                  <div className="">
                    <p className="text-xs text-menuText">
                      Ana sayfa ve kategorilerde görünecek görsel boyutu 1280 X
                      904 Boyutunda olmalıdır.
                    </p>
                  </div>
                </div>
                <TextBox
                  name="reward_image_title"
                  label="Resim Başlığı"
                  placeholder="Resim Başlığı"
                  innerRef={{ ...register("reward_image_title") }}
                  required
                />
              </div>
            </div>

            <SeoSettings register={register} />
          </div>

          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <ToastContainer />
          <PageSettings date={pageData?.last_operation_time} />
        </div>
      </form>
      {/* {openAddCardModal && (
        <AddMediaModal
          setOpenModal={() => setOpenAddCardModal(false)}
          onClick={(file) => onCreateActivityReportCard(file)}
        />
      )}

      {openEditCardModal && (
        <EditFileModal
          setOpenModal={() => setOpenEditCardModal(false)}
          onClick={(file) => onUpdateActivityReportCard(file)}
          datas={selectedEditCard}
        />
      )} */}
    </div>
  );
}

export default RewardsPageEdit;
