import MainButton from "components/FormElements/MainButton";
import SecondaryButton from "components/FormElements/SecondaryButton";
import SelectBox from "components/FormElements/SelectBox";

import { useConfigureSelectBoxItem } from "hooks";
import React, { useEffect, useState } from "react";
import { getListCategory, listFonCategory, logout } from "services/AllServices";
import { useGlobalStore } from "store/GlobalStore";
import moment from "moment/min/moment-with-locales";
import { useAuthStore } from "store/AuthStore";
import { useHistory } from "react-router-dom";

function PageSettings({
  news,
  hideLanguage,
  date,
  setNewsSeen,
  setSelectedNewsCategory,
  defaultSelectedCategory,
  setSelectedFonCategory,
  selectedFonCategory,
  fonView,
  setFonView,
  fonHomeView,
  setFonHomeView,
  fon,
  editfon,
}) {
  const [selectedLanguage, setSelectedLanguage] = useGlobalStore((state) => [
    state.selectedLanguage,
    state.setSelectedLanguage,
  ]);

  const [categoryList, setCategoryList] = useConfigureSelectBoxItem();
  const [fonCategoryList, setFonCategoryList] = useConfigureSelectBoxItem();

  const [openCategoryCreateModal, setOpenCategoryCreateModal] = useState(false);

  const languages = [
    { id: 1, text: "Türkçe", value: "tr" },
    { id: 2, text: "İngilizce", value: "en" },
  ];
  const newsView = [
    { id: 1, text: "Göster", value: true },
    { id: 2, text: "Gizle", value: false },
  ];

  const [selectedCategory, setSelectedCategory] = useState();

  const [selectedNewsView, setSelectedNewsView] = useState({
    id: 1,
    text: "Göster",
    value: true,
  });

  const history = useHistory();

  const [setIsLoggin] = useAuthStore((state) => [state.setIsLoggin]);

  const onLogOut = () => {
    logout()
      .then((res) => {
        setIsLoggin(false);
        localStorage.clear();
        history.push("/login");
      })
      .catch(setIsLoggin(false), localStorage.clear(), history.push("/login"));
  };

  const onSetSelectedLanguage = (value) => {
    setSelectedLanguage(value);
  };

  const onsetSelectedNewsView = (value) => {
    setSelectedNewsView(value);
    setNewsSeen(value.value);
  };

  const onsetSelectedCategory = (value) => {
    setSelectedCategory(value);
    setSelectedNewsCategory(value);
  };

  const onGetListNewsCategory = () => {
    // if (selectedLanguage.value === "tr") {
    //   getListCategory().then((res) => {
    //     setCategoryList(res, "id", "category_name_tr");
    //   }).catch(err => {
    //     onLogOut()
    //   })
    // } else {
    //   getListCategory().then((res) => {
    //     setCategoryList(res, "id", "category_name_en");
    //   }).catch(err => {
    //     onLogOut()
    //   })
    // }
  };

  const onGetListFonCategory = () => {
    // if (selectedLanguage.value === "tr") {
    //   listFonCategory().then((res) => {
    //     setFonCategoryList(res, "id", "category_name_tr");
    //     if (!editfon) {
    //       setSelectedFonCategory({ id: res[0].id, text: res[0].category_name_tr })
    //     }
    //   }).catch(err => {
    //     onLogOut()
    //   })
    // } else {
    //   listFonCategory().then((res) => {
    //     setFonCategoryList(res, "id", "category_name_en");
    //     if (!editfon) {
    //       setSelectedFonCategory({ id: res[0].id, text: res[0].category_name_en })
    //     }
    //   }).catch(err => {
    //     onLogOut()
    //   })
    // }
  };

  useEffect(() => {
    if (news) {
      onGetListNewsCategory();
    }
    if (fon) {
      onGetListFonCategory();
    }
  }, [selectedLanguage]);

  useEffect(() => {
    if (categoryList && news) {
      setSelectedCategory(categoryList[0]);
      setSelectedNewsCategory(categoryList[0]);
    }
  }, [categoryList]);

  return (
    <div className="bg-white w-72 h-screen p-4">
      <h3 className="text-main text-2xl font-semibold  mb-6">Ayarlar</h3>
      {!hideLanguage && (
        <SelectBox
          label="Dil"
          data={languages}
          selectedItem={selectedLanguage}
          setSelectedItem={(v) => onSetSelectedLanguage(v)}
        />
      )}

      {news && (
        <div>
          <SelectBox
            style="mt-6"
            label="Kategori"
            data={categoryList}
            selectedItem={selectedCategory}
            setSelectedItem={(v) => onsetSelectedCategory(v)}
          />

          <SecondaryButton
            type="button"
            left
            text="Kategori Oluştur"
            style="w-full mt-6"
            onClick={() => setOpenCategoryCreateModal(true)}
          >
            <i className="ri-price-tag-fill text-lg text-secondary mr-2"></i>
          </SecondaryButton>
          <SelectBox
            style="mt-6"
            label="Haber Görünürlüğü"
            data={newsView}
            selectedItem={selectedNewsView}
            setSelectedItem={(v) => onsetSelectedNewsView(v)}
          />
        </div>
      )}

      {fon && (
        <div>
          <SelectBox
            style="mt-6"
            label="Bağlı Fon"
            data={fonCategoryList}
            selectedItem={selectedFonCategory}
            setSelectedItem={(v) => setSelectedFonCategory(v)}
          />

          <SelectBox
            style="mt-6"
            label="Yazı Görünürlüğü"
            data={newsView}
            selectedItem={fonView}
            setSelectedItem={(v) => setFonView(v)}
          />

          <SelectBox
            style="mt-6"
            label="Anasayfa Görünürlüğü"
            data={newsView}
            selectedItem={fonHomeView}
            setSelectedItem={(v) => setFonHomeView(v)}
          />
        </div>
      )}
      {date && (
        <>
          <p className="text-mainText text-sm mb-1 mt-6">Son İşlem Tarihi</p>
          <p className="text-main text-lg">
            {date ? moment(date).format("DD-MM-YYYY / h:mm:ss") : "---"}
          </p>
        </>
      )}

      <MainButton type="submit" left text="Kaydet" style="w-full mt-8">
        <i className="ri-checkbox-circle-fill text-lg text-white mr-2"></i>
      </MainButton>

      {/* {openCategoryCreateModal && (
        <CategoryCreateModal
          setOpenModal={() => setOpenCategoryCreateModal(false)}
        />
      )} */}
    </div>
  );
}

export default PageSettings;
