import React, { useEffect, useState, useRef } from "react";

function SelectBox({
  selectedItem,
  setSelectedItem,
  data,
  defaultSelect,
  label,
  style,
}) {
  const [openOptions, setOpenOptions] = useState(false);

  const onSelectedItem = async (item) => {
    setOpenOptions(!openOptions);
    setSelectedItem(item);
  };
  useEffect(() => {
    if (defaultSelect) setSelectedItem(defaultSelect);
  }, [defaultSelect]);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  //when outside of the combobox clicked, it will be closed
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenOptions(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  return (
    <div
      className={`comboBox relative w-full lg:w-auto ${style && style}`}
      ref={wrapperRef}
    >
      <label className="text-mainText text-sm mb-1">{label}</label>
      <button
        type="button"
        onClick={() => setOpenOptions(!openOptions)}
        className="flex w-full  justify-between   border border-secondaryText   text-mainText shadow-sm px-4 py-3  bg-white text-sm font-medium  hover:bg-gray-50 focus:outline-none "
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true"
      >
        <div className="text-left">{selectedItem?.text}</div>
        <div className="flex">
          <div className="ml-2 bg-WHİTE w-0.5 h-5"></div>
          <svg
            className="-mr-1 ml-1 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </button>
      {openOptions && (
        <div className="origin-top-left absolute   lg:left-0 mt-2 w-full  z-30  shadow-lg bg-white">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {data.map((item, i) => (
              <div
                key={i}
                onClick={() => onSelectedItem(item)}
                className="flex  w-full cursor-pointer px-4 py-3 bg-white text-sm text-mainText font-medium hover:bg-secondaryButtonBg focus:outline-none focus:ring-2 "
                id="options-menu"
                aria-haspopup="true"
                aria-expanded="true"
              >
                <p className="text-cardText">{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default SelectBox;
