import HomeCard from "components/Homepage/HomeCard";
import React, { useEffect, useState } from "react";
import HomeLogTable from "components/Homepage/HomeLogTable";
import { Link } from "react-router-dom";
import Users from "components/Users/CreateUserModal";
import { ToastContainer, toast } from "react-toastify";

import {
  getProductCount,
  getUsersCount,
  getPoliciesCount,
  getSlidesCount,
  getStaffLogs,
  logout,
} from "services/AllServices";
import { useAuthStore } from "store/AuthStore";
import { useHistory } from "react-router-dom";
import CreateUserModal from "components/Users/CreateUserModal";

function Homepage() {
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);

  const [slideCount, setSlideCount] = useState();
  const [ProductCount, setProductCount] = useState();
  const [pageCount, setPageCount] = useState();
  const [userCount, setUserCount] = useState();

  const [staffLogs, setStaffLogs] = useState();
  const [limit, setLimit] = useState(5);

  const history = useHistory();

  const [setIsLoggin] = useAuthStore((state) => [state.setIsLoggin]);

  const onLogOut = () => {
    logout()
      .then((res) => {
        setIsLoggin(false);
        localStorage.clear();
        history.push("/login");
      })
      .catch(setIsLoggin(false), localStorage.clear(), history.push("/login"));
  };

  const ongetCounts = () => {
    getSlidesCount()
      .then((res) => {
        setSlideCount(res.count);
      })
      .catch((err) => {
        onLogOut();
      });
    getProductCount()
      .then((res) => {
        setProductCount(res.count);
      })
      .catch((err) => {
        onLogOut();
      });
    getPoliciesCount()
      .then((res) => {
        setPageCount(res.count);
      })
      .catch((err) => {
        onLogOut();
      });
    getUsersCount()
      .then((res) => {
        setUserCount(res.count);
      })
      .catch((err) => {
        onLogOut();
      });
  };

  const notify = () => {
    toast.success("Kayıt Başarılı", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    // onGetStaffList();
  };

  const onGetStaffLog = () => {
    getStaffLogs(limit).then((res) => {
      setStaffLogs();
      setStaffLogs(res);
    });
  };

  useEffect(() => {
    ongetCounts();
    onGetStaffLog();
  }, []);

  useEffect(() => {
    onGetStaffLog();
  }, [limit]);

  return (
    <div className="container pt-6 pr-4 h-screen overflow-y-auto">
      <p className="text-mainText">Hoşgeldin</p>
      <h3 className="font-semibold text-2xl text-main">Cem Ofset Admin</h3>
      <div className="grid grid-cols-4 gap-10 mt-10">
        <div className="col-span-1">
          <HomeCard count={pageCount} description="Politika Sayfası" />
        </div>
        <div className="col-span-1">
          <HomeCard count={ProductCount} description="Ürün Sayfası" />
        </div>
        <div className="col-span-1">
          <HomeCard count={userCount} description="Oluşuturulmuş Kullanıcı" />
        </div>
        <div className="col-span-1">
          <HomeCard count={slideCount} description="Yayınlanan Slayt" />
        </div>
      </div>
      <h3 className="font-semibold text-2xl text-main mt-10">Hızlı Erişim</h3>
      <div className="grid grid-cols-4 gap-10 mt-5">
        <div className="col-span-1">
          <Link to="/urun-olustur">
            <HomeCard
              icon="ri-book-read-fill"
              description="Ürün Sayfası Oluştur"
            />
          </Link>
        </div>
        <div
          onClick={() => setOpenCreateUserModal(true)}
          className="col-span-1"
        >
          <HomeCard icon="ri-user-fill" description="Kullanıcı Oluştur" />
        </div>
        <div className="col-span-1">
          <Link to="/sayfalar">
            <HomeCard
              icon="ri-file-edit-fill"
              description="Sayfaları Düzenle"
            />
          </Link>
        </div>
        <div className="col-span-1">
          <Link to="/slayt-olustur">
            <HomeCard icon="ri-slideshow-3-fill" description="Slayt Oluştur" />
          </Link>
        </div>
      </div>
      <div>
        <h3 className="font-semibold text-2xl text-main mt-10 mb-5">
          Son Girişler
        </h3>
        {staffLogs && (
          <>
            <HomeLogTable datas={staffLogs} />
            <div
              onClick={() => setLimit(limit + 6)}
              className="w-full border border-secondBg bg-white flex items-center justify-center py-2 mt-8 cursor-pointer transition-all group duration-300 hover:bg-main hover:text-white"
            >
              <i className="ri-restart-line text-mainText font-semibold mr-4 transition-all duration-300 group-hover:text-white"></i>
              <p className="text-mainText font-semibold transition-all duration-300 group-hover:text-white">
                DAHA FAZLA YÜKLE
              </p>
            </div>
          </>
        )}
      </div>
      {/* {openCreateModal && (
        < CategoryCreateModal setOpenModal={() => setOpenCreateModal(false)} />
      )}   */}
      {openCreateUserModal && (
        <CreateUserModal
          setOpenModal={() => setOpenCreateUserModal(false)}
          notify={() => notify()}
        />
      )}
    </div>
  );
}

export default Homepage;
