import React from "react";

function AlertWarning({ title, description, style }) {
  return (
    <div className={`bg-white w-full p-4 flex items-center ${style && style}`}>
      <div>
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="48" height="48" rx="24" fill="#FFF5BC" />
          <path
            d="M24.8661 15L34.3921 31.5C34.4799 31.652 34.5261 31.8244 34.5261 32C34.5261 32.1755 34.4799 32.3479 34.3921 32.4999C34.3043 32.652 34.1781 32.7782 34.0261 32.866C33.8741 32.9537 33.7016 33 33.5261 33H14.4741C14.2986 33 14.1261 32.9537 13.9741 32.866C13.8221 32.7782 13.6959 32.652 13.6081 32.4999C13.5203 32.3479 13.4741 32.1755 13.4741 32C13.4741 31.8244 13.5203 31.652 13.6081 31.5L23.1341 15C23.2219 14.8479 23.3481 14.7217 23.5001 14.634C23.6521 14.5462 23.8246 14.5 24.0001 14.5C24.1756 14.5 24.3481 14.5462 24.5001 14.634C24.6521 14.7217 24.7783 14.8479 24.8661 15V15ZM23.0001 28V30H25.0001V28H23.0001ZM23.0001 21V26H25.0001V21H23.0001Z"
            fill="#FFC350"
          />
        </svg>
      </div>
      <div className="ml-3">
        <p className="text-alertYellow font-semibold">{title}</p>
        <p className="text-mainText text-xs mt-1 ">{description}</p>
      </div>
    </div>
  );
}

export default AlertWarning;
