import axios from "axios";
import api from "./api";
import qs from "query-string";
import { combineData, combineIDContent } from "helpers";

//Staff

export async function getStaffList() {
  const res = await api.get(`staff/list/staff`);
  return await combineIDContent(res.data);
}
export async function getStaff(id) {
  const res = await api.get(`staff/get/staff?staff_id=${id}`);
  return await combineIDContent(res.data);
}

export async function getStaffOwn() {
  const res = await api.get(`staff/get/staff/own`);
  return await combineIDContent(res.data);
}

export async function createStaff(data) {
  const res = await api.post(`staff/create/staff`, { content: data });
  return res.data;
}

export async function updateStaff(id, data) {
  const res = await api.put(`staff/update/staff?staff_id=${id}`, {
    content: data,
  });
  return res.data;
}

export async function deleteStaff(id) {
  const res = await api.delete(`staff/delete/staff?staff_id=${id}`);
  return await res.data;
}

export async function getStaffLogs(limit) {
  const res = await api.get(`staff/logs?limit=${limit}`);
  return res.data;
}

export async function updateStaffMail(data) {
  const res = await api.put(`staff/update/staff/own`, data);
  return res.data;
}

export async function updateStaffPassword(old_password, new_password) {
  const res = await api.put(
    `staff/update/staff/own/password?old_password=${old_password}&&new_password=${new_password}`
  );
  return res.data;
}

//add file

export async function contentsAddFile(file_title, file) {
  const res = await api.post(
    `contents/add/file?file_title=${file_title}`,
    file
  );
  return res.data;
}

//HomePageEdit

export async function getHomepage(lang) {
  const res = await api.get(`contents/get/mainpage?language=${lang}`);
  return await combineData(res.data);
}

export async function updateHomepage(lang, data) {
  const res = await api.put(`contents/update/mainpage?language=${lang}`, data);
  return res.data;
}

//AboutUsPageEdit

export async function getAboutus(lang) {
  const res = await api.get(`contents/get/aboutus?language=${lang}`);
  return await combineData(res.data);
}

export async function updateAboutus(lang, data) {
  const res = await api.put(`contents/update/aboutus?language=${lang}`, data);
  return res.data;
}

//RewardsPageEdit

export async function getRewards(lang) {
  const res = await api.get(`contents/get/rewards?language=${lang}`);
  return await combineData(res.data);
}

export async function updateRewards(lang, data) {
  const res = await api.put(`contents/update/rewards?language=${lang}`, data);
  return res.data;
}

//ReferencesPageEdit

export async function getReferences(lang) {
  const res = await api.get(`contents/get/references?language=${lang}`);
  return await combineData(res.data);
}

export async function updateReferences(lang, data) {
  const res = await api.put(
    `contents/update/references?language=${lang}`,
    data
  );
  return res.data;
}

//CertificatesPageEdit

export async function getCertificates(lang) {
  const res = await api.get(`contents/get/certificates?language=${lang}`);
  return await combineData(res.data);
}

export async function updateCertificates(lang, data) {
  const res = await api.put(
    `contents/update/certificates?language=${lang}`,
    data
  );
  return res.data;
}

//ResponsibilitiesPageEdit

export async function getResponsibilities(lang) {
  const res = await api.get(`contents/get/responsibilities?language=${lang}`);
  return await combineData(res.data);
}

export async function updateResponsibilities(lang, data) {
  const res = await api.put(
    `contents/update/responsibilities?language=${lang}`,
    data
  );
  return res.data;
}

//ContactPageEdit

export async function getContact(lang) {
  const res = await api.get(`contents/get/contacts?language=${lang}`);
  return await combineData(res.data);
}

export async function updateContact(lang, data) {
  const res = await api.put(`contents/update/contacts?language=${lang}`, data);
  return res.data;
}

//Policies section

export async function getPoliciesList() {
  const res = await api.get(`contents/list/policies/all`);
  return await combineIDContent(res.data);
}

export async function createPolicies(data) {
  const res = await api.post(`contents/create/policies`, { content: data });
  return res.data;
}

export async function getPolicies(id) {
  const res = await api.get(`contents/get/policies?record_id=${id}`);
  return await combineData(res.data);
}

export async function updatePolicies(id, data, brand_id) {
  const res = await api.put(
    `contents/update/policy/info?record_id=${id}&brand_id=${brand_id}`,
    {
      content: data,
    }
  );
  return res.data;
}

export async function deletePolicies(id) {
  const res = await api.delete(`contents/delete/policies?record_id=${id}`);
  return await res.data;
}

//Slide Section

export async function updateSlideOrder(data) {
  const res = await api.put(`contents/update/slide/records/order`, data);
  return res.data;
}

export async function createSlideVideo(data) {
  const res = await api.post(`contents/create/slide`, { content: data });
  return res.data;
}

export async function getSlideVideo() {
  const res = await api.get(`contents/get/slide`);
  return res.data;
}

export async function deleteSlideVideo() {
  const res = await api.get(`contents/delete/slide`);
  return res.data;
}

export async function getSlideRecordsList() {
  const res = await api.get(`contents/list/slide/records`);
  if (res.data?.status === "record_not_found") {
    return res.data;
  } else {
    return await combineData(res.data);
  }
}

export async function createSlideRecords(data) {
  const res = await api.post(`contents/create/slide/records`, {
    content: data,
  });
  return res.data;
}

export async function getSlideRecords(id, lang) {
  const res = await api.get(
    `contents/get/slide/records?record_id=${id} && language=${lang}`
  );
  return await combineData(res.data);
}

export async function updateSlideRecordsInfo(id, data) {
  const res = await api.put(
    `contents/update/slide/records/info?record_id=${id}`,
    {
      content: data,
    }
  );
  return res.data;
}

export async function deleteSlideRecords(id) {
  const res = await api.delete(`contents/delete/slide/records?record_id=${id}`);
  return await res.data;
}

// Edit Footer Info

export async function getFooterInfo(lang) {
  const res = await api.get(`contents/get/footer?language=${lang}`);
  return await combineData(res.data);
}

export async function updateFooterInfo(lang, data) {
  const res = await api.put(`contents/update/footer?language=${lang}`, data);
  return res.data;
}

//Edit Site Settings

export async function getSiteSettings(lang) {
  const res = await api.get(`contents/get/site/settings?language=${lang}`);
  return await combineData(res.data);
}

export async function updateSiteSettings(lang, data) {
  const res = await api.put(
    `contents/update/site/settings?language=${lang}`,
    data
  );
  return combineData(res.data);
}

//ProductsEdit APIs

export async function updateProductOrder(data) {
  const res = await api.put(`contents/update/product/order`, data);
  return res.data;
}

// export async function getProductRecordsList() {
//   const res = await api.get(`contents/list/slide/records`);
//   if (res.data?.status === "record_not_found") {
//     return res.data;
//   } else {
//     return await combineData(res.data);
//   }
// }

// export async function createProductRecords(data) {
//   const res = await api.post(`contents/create/slide/records`, {
//     content: data,
//   });
//   return res.data;
// }

// export async function getProductRecords(id, lang) {
//   const res = await api.get(
//     `contents/get/slide/records?record_id=${id} && language=${lang}`
//   );
//   return await combineData(res.data);
// }

// export async function updateProductRecordsInfo(id, data) {
//   const res = await api.put(
//     `contents/update/slide/records/info?record_id=${id}`,
//     {
//       content: data,
//     }
//   );
//   return res.data;
// }

// export async function deleteProductRecords(id) {
//   const res = await api.delete(`contents/delete/slide/records?record_id=${id}`);
//   return await res.data;
// }

export async function getProductsList() {
  const res = await api.get(`contents/list/product/all`);
  return await combineData(res.data);
}

export async function createProducts(data) {
  const res = await api.post(`contents/create/products`, { content: data });
  return res.data;
}

export async function getProducts(id) {
  const res = await api.get(`contents/get/products?record_id=${id}`);
  return await combineData(res.data);
}

export async function updateProducts(id, data, brand_id) {
  const res = await api.put(
    `contents/update/product/info?record_id=${id}&brand_id=${brand_id}`,
    {
      content: data,
    }
  );
  return res.data;
}

export async function deleteProducts(id) {
  const res = await api.delete(`contents/delete/products?record_id=${id}`);
  return await res.data;
}

//Counts API s
export async function getSlidesCount() {
  const res = await api.get(`contents/get/slide/count`);
  return res.data;
}

export async function getUsersCount() {
  const res = await api.get(`staff/get/staff/count`);
  return res.data;
}

export async function getPoliciesCount() {
  const res = await api.get(`contents/get/policy/count`);
  return res.data;
}

export async function getProductCount() {
  const res = await api.get(`contents/get/product/count`);
  return res.data;
}

//Auth İşlemleri

export async function logout() {
  const res = await api.get(`staff/logout`);
  return res.data;
}

export async function login(UserDetails) {
  const signInData = {
    grant_type: "password",
    scope: "",
    client_id: "",
    client_secret: "",
    username: UserDetails.email,
    password: UserDetails.password,
  };
  const res = await api.post("/staff/login", qs.stringify(signInData), {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
  api.defaults.headers.Authorization = `Bearer ${res.data.access_token}`;
  return res.data;
}

export async function tokenCheck(token) {
  const res = await api.get(`staff/token-check?token=${token}`);
  return res.data;
}

export async function rememberPasswordSendMail(staff_mail) {
  const res = await api.post(`staff/send/mail/code?staff_mail=${staff_mail}`);
  return res.data;
}

export async function rememberPasswordNewPassword(
  staff_mail,
  code,
  new_password
) {
  const res = await api.post(
    `staff​/check​/staff​/send​/mail​/code?staff_mail=${staff_mail}&&code=${code}&&new_password=${new_password}`
  );
  return res.data;
}
