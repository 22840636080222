import FileUploader from "components/FormElements/FileUploader";
import SecondaryButton from "components/FormElements/SecondaryButton";
import TextBox from "components/FormElements/TextBox";
import React, { useEffect, useState } from "react";
import TextArea from "components/FormElements/TextArea";
import PageSettings from "components/PageSettings/PageSettings";
import SeoSettings from "components/SeoSettings/SeoSettings";
import { useGlobalStore } from "store/GlobalStore";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  contentsAddFile,
  getHomepage,
  updateHomepage,
} from "services/AllServices";
import { ToastContainer, toast } from "react-toastify";

const schema = yup.object().shape({
  about_us_title: yup.string(),
  about_us_content: yup.string(),
  about_us_button_text: yup.string(),
  about_us_button_url: yup.string(),
  about_us_promo_video_source: yup.string(),

  company_info_title: yup.string(),
  company_info_content: yup.string(),
  company_info_button_text: yup.string(),
  company_info_button_url: yup.string(),
  company_info_first_box_icon: yup.string(),
  company_info_first_box_count: yup.string(),
  company_info_first_box_text: yup.string(),
  company_info_second_box_icon: yup.string(),
  company_info_second_box_count: yup.string(),
  company_info_second_box_text: yup.string(),
  company_info_third_box_icon: yup.string(),
  company_info_third_box_count: yup.string(),
  company_info_third_box_text: yup.string(),
  company_info_fourth_box_icon: yup.string(),
  company_info_fourth_box_count: yup.string(),
  company_info_fourth_box_text: yup.string(),

  values_title: yup.string(),
  values_content: yup.string(),
  values_first_box_icon: yup.string(),
  values_first_box_text: yup.string(),
  values_second_box_icon: yup.string(),
  values_second_box_text: yup.string(),
  values_third_box_icon: yup.string(),
  values_third_box_text: yup.string(),
  values_fourth_box_icon: yup.string(),
  values_fourth_box_text: yup.string(),
  values_fifth_box_icon: yup.string(),
  values_fifth_box_text: yup.string(),

  awards_info_title: yup.string(),
  awards_info_content: yup.string(),
  awards_info_button_text: yup.string(),
  awards_info_button_url: yup.string(),

  seo_title: yup.string(),
  seo_description: yup.string(),
  can_see: yup.bool(),
  last_operation_time: yup.string(),
});

function HomePageEdit() {
  const [file, uploadFile] = useState();
  const [pageData, setPageData] = useState();

  const [selectedLanguage] = useGlobalStore((state) => [
    state.selectedLanguage,
  ]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const notify = () => {
    toast.success("Kayıt Başarılı", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onSubmit = async (data) => {
    const configureData = {
      language: selectedLanguage.value,
      about_us_title: data.about_us_title.replaceAll("'", "’"),
      about_us_content: data.about_us_content.replaceAll("'", "’"),
      about_us_button_text: data.about_us_button_text.replaceAll("'", "’"),
      about_us_button_url: data.about_us_button_url.replaceAll("'", "’"),
      about_us_promo_video_source: data.about_us_promo_video_source.replaceAll(
        "'",
        "’"
      ),
      company_info_title: data.company_info_title.replaceAll("'", "’"),
      company_info_content: data.company_info_content.replaceAll("'", "’"),
      company_info_button_text: data.company_info_button_text.replaceAll(
        "'",
        "’"
      ),
      company_info_button_url: data.company_info_button_url.replaceAll(
        "'",
        "’"
      ),
      company_info_first_box_icon: data.company_info_first_box_icon.replaceAll(
        "'",
        "’"
      ),
      company_info_first_box_count:
        data.company_info_first_box_count.replaceAll("'", "’"),
      company_info_first_box_text: data.company_info_first_box_text.replaceAll(
        "'",
        "’"
      ),
      company_info_second_box_icon:
        data.company_info_second_box_icon.replaceAll("'", "’"),
      company_info_second_box_count:
        data.company_info_second_box_count.replaceAll("'", "’"),
      company_info_second_box_text:
        data.company_info_second_box_text.replaceAll("'", "’"),
      company_info_third_box_icon: data.company_info_third_box_icon.replaceAll(
        "'",
        "’"
      ),
      company_info_third_box_count:
        data.company_info_third_box_count.replaceAll("'", "’"),
      company_info_third_box_text: data.company_info_third_box_text.replaceAll(
        "'",
        "’"
      ),
      company_info_fourth_box_icon:
        data.company_info_fourth_box_icon.replaceAll("'", "’"),
      company_info_fourth_box_count:
        data.company_info_fourth_box_count.replaceAll("'", "’"),
      company_info_fourth_box_text:
        data.company_info_fourth_box_text.replaceAll("'", "’"),
      values_title: data.values_title.replaceAll("'", "’"),
      values_content: data.values_content.replaceAll("'", "’"),
      values_first_box_icon: data.values_first_box_icon.replaceAll("'", "’"),
      values_first_box_text: data.values_first_box_text.replaceAll("'", "’"),
      values_second_box_icon: data.values_second_box_icon.replaceAll("'", "’"),
      values_second_box_text: data.values_second_box_text.replaceAll("'", "’"),
      values_third_box_icon: data.values_third_box_icon.replaceAll("'", "’"),
      values_third_box_text: data.values_third_box_text.replaceAll("'", "’"),
      values_fourth_box_icon: data.values_fourth_box_icon.replaceAll("'", "’"),
      values_fourth_box_text: data.values_fourth_box_text.replaceAll("'", "’"),
      values_fifth_box_icon: data.values_fifth_box_icon.replaceAll("'", "’"),
      values_fifth_box_text: data.values_fifth_box_text.replaceAll("'", "’"),
      awards_info_title: data.awards_info_title.replaceAll("'", "’"),
      awards_info_content: data.awards_info_content.replaceAll("'", "’"),
      awards_info_button_text: data.awards_info_button_text.replaceAll(
        "'",
        "’"
      ),
      awards_info_button_url: data.awards_info_button_url.replaceAll("'", "’"),
      seo_title: data.seo_title.replaceAll("'", "’"),
      seo_description: data.seo_description.replaceAll("'", "’"),
      can_see: data.can_see,
      last_operation_time: data.last_operation_time,
    };

    await updateHomepage(selectedLanguage.value, {
      content: configureData,
    }).then((res) => {
      notify();
    });
  };

  useEffect(() => {
    getHomepage(selectedLanguage.value).then(async (res) => {
      reset(res);
      await setPageData(null);
      setPageData(res);
      //  setNewFile(res.slide_image);
    });
  }, [selectedLanguage]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-between ">
        <div className="container pr-4 pt-6 pb-6 h-screen overflow-y-auto ">
          <h1 className="font-semibold text-2xl text-main mb-5">
            Anasayfayı Düzenle
          </h1>

          <h2 className="font-semibold text-xl text-main mt-5 mb-5">
            Hakkımızda Kısmı
          </h2>
          <TextBox
            name="about_us_title"
            label="Başlık Metni"
            placeholder="Başlık Giriniz"
            innerRef={{ ...register("about_us_title") }}
            required
          />
          <TextArea
            name="about_us_content"
            label="İçerik Metni"
            placeholder="Paragraf Metni Giriniz."
            style="mt-3 mb-3"
            rows="7"
            innerRef={{ ...register("about_us_content") }}
            required
          />
          <div className="grid grid-cols-2 gap-5">
            <div className="col-span-1">
              <TextBox
                name="about_us_button_text"
                label="Buton Metni"
                placeholder="Butan Yazısı"
                innerRef={{ ...register("about_us_button_text") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="about_us_button_url"
                label="Buton URL Adresi"
                placeholder="/contact-us"
                innerRef={{ ...register("about_us_button_url") }}
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-12 ">
              <TextBox
                name="about_us_promo_video_source"
                label="Tanıtım Videosu Kaynağı"
                placeholder="https://www.youtube.com/embed/A-hlyFxlK70"
                innerRef={{ ...register("about_us_promo_video_source") }}
                required
              />
              {/* {pageData && (
                <FileUploader
                  id="download1"
                  file="fileUpload1"
                  name="about_us_promo_video_source"
                  uploadId="uploadId1"
                  value={file}
                  defaultText="Dosya Seçiniz"
                  required="required"
                  setValue={uploadFile}
                  label="Tanıtım Videosu Kaynağı"
                  placeholder="Dosya seçiniz"
                  inputStyle="w-full mb-2 text-xs"
                  labelStyle="mt-3 mb-0"
                  multiple={false}
                  maxFile={10}
                  defaultValue={pageData?.about_us_promo_video_source}
                />
              )} */}
            </div>
          </div>

          {/* company details are below  */}
          <h2 className="font-semibold text-xl text-main mt-5 mb-5">
            Firma Bilgileri Kısmı
          </h2>
          <TextBox
            name="company_info_title"
            label="Başlık Metni"
            placeholder="Başlık Giriniz"
            innerRef={{ ...register("company_info_title") }}
            required
          />
          <TextArea
            name="company_info_content"
            label="İçerik Metni"
            placeholder="Paragraf Metni Giriniz."
            style="mt-3 mb-3"
            rows="7"
            innerRef={{ ...register("company_info_content") }}
            required
          />
          <div className="grid grid-cols-2 gap-5">
            <div className="col-span-1">
              <TextBox
                name="company_info_button_text"
                label="Buton Metni"
                placeholder="Buton Yazısı"
                innerRef={{ ...register("company_info_button_text") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="company_info_button_url"
                label="Buton URL Adresi"
                placeholder="/contact-us"
                innerRef={{ ...register("company_info_button_url") }}
                required
              />
            </div>
          </div>
          {/* Icontext boxes are below */}
          <div className="grid grid-cols-3 gap-5 mt-5">
            <div className="col-span-1">
              <TextBox
                name="company_info_first_box_icon"
                label="1.Kutu İkonu"
                placeholder="ex:<i class=ri-building-fill></i>"
                innerRef={{ ...register("company_info_first_box_icon") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="company_info_first_box_count"
                label="1.Kutu Sayısı"
                placeholder="12345"
                innerRef={{ ...register("company_info_first_box_count") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="company_info_first_box_text"
                label="1.Kutu Yazısı"
                placeholder="Çalışan Personel"
                innerRef={{ ...register("company_info_first_box_text") }}
                required
              />
            </div>

            <div className="col-span-1">
              <TextBox
                name="company_info_second_box_icon"
                label="2.Kutu İkonu"
                placeholder="ex:<i class=ri-building-fill></i>"
                innerRef={{ ...register("company_info_second_box_icon") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="company_info_second_box_count"
                label="2.Kutu Sayısı"
                placeholder="12345"
                innerRef={{ ...register("company_info_second_box_count") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="company_info_second_box_text"
                label="2.Kutu Yazısı"
                placeholder="Çalışan Personel"
                innerRef={{ ...register("company_info_second_box_text") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="company_info_third_box_icon"
                label="3.Kutu İkonu"
                placeholder="ex:<i class=ri-building-fill></i>"
                innerRef={{ ...register("company_info_third_box_icon") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="company_info_third_box_count"
                label="3.Kutu Sayısı"
                placeholder="12345"
                innerRef={{ ...register("company_info_third_box_count") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="company_info_third_box_text"
                label="3.Kutu Yazısı"
                placeholder="Çalışan Personel"
                innerRef={{ ...register("company_info_third_box_text") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="company_info_fourth_box_icon"
                label="4.Kutu İkonu"
                placeholder="ex:<i class=ri-building-fill></i>"
                innerRef={{ ...register("company_info_fourth_box_icon") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="company_info_fourth_box_count"
                label="4.Kutu Sayısı"
                placeholder="12345"
                innerRef={{ ...register("company_info_fourth_box_count") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="company_info_fourth_box_text"
                label="4.Kutu Yazısı"
                placeholder="Çalışan Personel"
                innerRef={{ ...register("company_info_fourth_box_text") }}
                required
              />
            </div>
          </div>

          {/* Our Values part are below */}

          <h2 className="font-semibold text-xl text-main mt-5 mb-5">
            Değerlerimiz Kısmı
          </h2>
          <TextBox
            name="values_title"
            label="Başlık Metni"
            placeholder="Başlık Giriniz"
            innerRef={{ ...register("values_title") }}
            required
          />
          <TextArea
            name="values_content"
            label="İçerik Metni"
            placeholder="Paragraf Metni Giriniz."
            style="mt-3 mb-3"
            rows="7"
            innerRef={{ ...register("values_content") }}
            required
          />
          {/* Icontext boxes are below */}
          <div className="grid grid-cols-2 gap-3 mt-5">
            <div className="col-span-1">
              <TextBox
                name="values_first_box_icon"
                label="1.Kutu İkonu"
                placeholder="ex:<i class=ri-building-fill></i>"
                innerRef={{ ...register("values_first_box_icon") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="values_first_box_text"
                label="1.Kutu Metni"
                placeholder="En Yeni Üretim Teknolojisi"
                innerRef={{ ...register("values_first_box_text") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="values_second_box_icon"
                label="1.Kutu İkonu"
                placeholder="ex:<i class=ri-building-fill></i>"
                innerRef={{ ...register("values_second_box_icon") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="values_second_box_text"
                label="1.Kutu Metni"
                placeholder="En Yeni Üretim Teknolojisi"
                innerRef={{ ...register("values_second_box_text") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="values_third_box_icon"
                label="1.Kutu İkonu"
                placeholder="ex:<i class=ri-building-fill></i>"
                innerRef={{ ...register("values_third_box_icon") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="values_third_box_text"
                label="1.Kutu Metni"
                placeholder="En Yeni Üretim Teknolojisi"
                innerRef={{ ...register("values_third_box_text") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="values_fourth_box_icon"
                label="1.Kutu İkonu"
                placeholder="ex:<i class=ri-building-fill></i>"
                innerRef={{ ...register("values_fourth_box_icon") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="values_fourth_box_text"
                label="1.Kutu Metni"
                placeholder="En Yeni Üretim Teknolojisi"
                innerRef={{ ...register("values_fourth_box_text") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="values_fifth_box_icon"
                label="1.Kutu İkonu"
                placeholder="ex:<i class=ri-building-fill></i>"
                innerRef={{ ...register("values_fifth_box_icon") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="values_fifth_box_text"
                label="1.Kutu Metni"
                placeholder="En Yeni Üretim Teknolojisi"
                innerRef={{ ...register("values_fifth_box_text") }}
                required
              />
            </div>
          </div>

          {/* Awards part are below */}

          <h2 className="font-semibold text-xl text-main mt-5 mb-5">
            Ödüllerimiz Kısmı
          </h2>
          <TextBox
            name="awards_info_title"
            label="Başlık Metni"
            placeholder="Ödüllü Baskı Deneyimi"
            innerRef={{ ...register("awards_info_title") }}
            required
          />
          <TextArea
            name="awards_info_content"
            label="İçerik Metni"
            placeholder="Alanında bir çok dalda ödüller almış bir firmayız."
            style="mt-3 mb-3"
            rows="7"
            innerRef={{ ...register("awards_info_content") }}
            required
          />
          <div className="grid grid-cols-2 gap-5">
            <div className="col-span-1">
              <TextBox
                name="awards_info_button_text"
                label="Buton Metni"
                placeholder="Ödüllerimiz"
                innerRef={{ ...register("awards_info_button_text") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="awards_info_button_url"
                label="Buton URL"
                placeholder="https://www.cemofset.com/odullerimiz"
                innerRef={{ ...register("awards_info_button_url") }}
                required
              />
            </div>
          </div>

          <SeoSettings register={register} />
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ToastContainer />
        <PageSettings date={pageData?.last_operation_time} />
      </div>
    </form>
  );
}

export default HomePageEdit;
