import TextBox from "components/FormElements/TextBox";
import React, { useEffect, useState } from "react";
import TextArea from "components/FormElements/TextArea";
import PageSettings from "components/PageSettings/PageSettings";
import SeoSettings from "components/SeoSettings/SeoSettings";
import {
  createSlideRecords,
  getSlideRecords,
  updateSlideRecordsInfo,
} from "services/AllServices";
import { useGlobalStore } from "store/GlobalStore";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const schema = yup.object().shape({
  slide_title: yup.string(),
  slide_description: yup.string(),
  first_button_text: yup.string(),
  first_button_url: yup.string(),
  second_button_text: yup.string(),
  second_button_url: yup.string(),
  can_see: yup.bool(),
});
function SlideEdit(props) {
  const history = useHistory();
  const [slidesSeen, setSlidesSeen] = useState(true);
  const [isCreated, setIsCreated] = useState(false);
  const [pageData, setPageData] = useState();

  const [selectedLanguage] = useGlobalStore((state) => [
    state.selectedLanguage,
  ]);
  const slideID = props.location?.state?.id;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const notify = () => {
    toast.success("Kayıt Başarılı", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onSubmit = async (data) => {
    const configureData = {
      language: selectedLanguage.value,
      slide_title: data.slide_title.replaceAll("'", "’"),
      slide_description: data.slide_description.replaceAll("'", "’"),
      first_button_text: data.first_button_text.replaceAll("'", "’"),
      first_button_url: data.first_button_url.replaceAll("'", "’"),
      second_button_text: data.second_button_text.replaceAll("'", "’"),
      second_button_url: data.second_button_url.replaceAll("'", "’"),
      can_see: slidesSeen,
    };

    if (isCreated) {
      updateSlideRecordsInfo(pageData.id, configureData).then((res) => {
        notify();
        setTimeout(() => {
          history.push("/slaytlari-goruntule");
        }, 1000);
      });
    } else {
    }
  };

  const onGetSlides = () => {
    getSlideRecords(slideID, selectedLanguage.value).then((res) => {
      reset(res);
      setPageData();
      setPageData(res);
    });
  };

  useEffect(() => {
    onGetSlides();
  }, [selectedLanguage]);

  useEffect(() => {
    if (pageData) {
      if (pageData.status === "report_not_found") {
        setIsCreated(false);
      } else {
        setIsCreated(true);
      }
    }
  }, [pageData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-between ">
        <div className="container pr-4 pt-6 pb-6 h-screen overflow-y-auto ">
          <h1 className="font-semibold text-2xl text-main mb-5">
            Slayt {slideID ? "Düzenle" : "Oluştur"}
          </h1>
          <TextBox
            style="mb-5"
            name="slide_title"
            label="Başlık"
            placeholder="Slayt Başlığı"
            innerRef={{ ...register("slide_title") }}
            required
            maxLength={40}
          />

          <div className="grid grid-cols-2 gap-5 mb-5">
            <div className="col-span-1 ">
              <TextBox
                name="first_button_text"
                label="Buton Yazısı"
                placeholder="Detaylı Bilgi İsteyin"
                innerRef={{ ...register("first_button_text") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="first_button_url"
                label="Buton URL Adresi"
                placeholder="/contact-us"
                innerRef={{ ...register("first_button_url") }}
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-5">
            <div className="col-span-1">
              <TextBox
                name="second_button_text"
                label="Buton Yazısı"
                placeholder="Detaylı Bilgi İsteyin"
                innerRef={{ ...register("second_button_text") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="second_button_url"
                label="Buton URL Adresi"
                placeholder="/contact-us"
                innerRef={{ ...register("second_button_url") }}
                required
              />
            </div>
          </div>

          <TextArea
            name="slide_description"
            label="Slayt Yazısı"
            placeholder="Slayt Alt Yazısı"
            style="mt-3 mb-3"
            rows="7"
            innerRef={{ ...register("slide_description") }}
            required
            maxLength={180}
          />
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ToastContainer />

        <PageSettings hideLanguage={slideID ? true : false} />
      </div>
    </form>
  );
}

export default SlideEdit;
