import React, { lazy, Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ProtectedRoute } from "./protected.routes";
import { createBrowserHistory } from "history";
import Login from "pages/Login/Login";
import Loading from "pages/Loading/Loading";
import Homepage from "pages/Homepage/Homepage";
import ForgetPassword from "pages/Login/ForgetPassword";
import Layout from "layout/Layout";
import FixedPages from "pages/FixedPages/FixedPages";
import HomePageEdit from "pages/FixedPages/HomePageEdit";
import AboutUsPageEdit from "pages/FixedPages/AboutUsPageEdit";
import RewardsPageEdit from "pages/FixedPages/RewardsPageEdit";
import ReferencesPageEdit from "pages/FixedPages/ReferencesPageEdit";
import CertificatesPageEdit from "pages/FixedPages/CertificatesPageEdit";
import ContactPageEdit from "pages/FixedPages/ContactPageEdit";
import ProductCreate from "pages/Products/ProductCreate";
import ProductList from "pages/Products/ProductList";
import ProductEdit from "pages/Products/ProductEdit";
import PolicyCreate from "pages/Politics/PolicyCreate";
import PolicyList from "pages/Politics/PolicyList";
import PolicyEdit from "pages/Politics/PolicyEdit";
import SlideCreate from "pages/Slide/SlideCreate";
import SlideList from "pages/Slide/SlideList";
import SlideEdit from "pages/Slide/SlideEdit";
import FooterEdit from "pages/FooterEdit/FooterEdit";
import SiteSettingsEdit from "pages/SiteSettingsEdit/SiteSettingsEdit";
import ProfileSettings from "pages/ProfileSettings/ProfileSettings";
import Users from "pages/Users/Users";
import ResponsibilityPageEdit from "pages/FixedPages/ResponsibilityEditPage";

const browserHistory = createBrowserHistory();

const Routes = () => {
  // const [authTokens, setAuthTokens] = useState();
  // const setTokens = (data) => setAuthTokens(data);
  // const history = useHistory();
  // const location = useLocation();

  // const [isLoggin, setIsLoggin] = useAuthStore((state) => [
  //   state.isLoggin,
  //   state.setIsLoggin,
  // ]);

  // const checkAuth = async () => {
  //   if (localStorage.getItem("__mp_tk") !== null) {
  //     tokenCheck(localStorage.getItem("__mp_tk"))
  //       .then((res) => {

  //         if (!res.status) {
  //           setIsLoggin(false);
  //           history.push("/login");
  //         }
  //       })
  //       .catch((err) => {
  //         setIsLoggin(false);
  //         history.push("/login");
  //       });
  //   }
  // };

  // useEffect(() => {
  //   checkAuth();
  // }, []);

  return (
    <Router history={browserHistory}>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route
            exact
            path="/login"
            render={() => {
              return <Login />;
            }}
          />
          <Route
            exact
            path="/forget-password"
            render={(props) => {
              return <ForgetPassword {...props} />;
            }}
          />

          <ProtectedRoute
            component={Homepage}
            exact
            layout={Layout}
            path={"/"}
          />

          <ProtectedRoute
            component={FixedPages}
            exact
            layout={Layout}
            path={"/sayfalar"}
          />
          <ProtectedRoute
            component={HomePageEdit}
            exact
            layout={Layout}
            path={"/anasayfa-duzenle"}
          />
          <ProtectedRoute
            component={AboutUsPageEdit}
            exact
            layout={Layout}
            path={"/hakkimizda-duzenle"}
          />
          <ProtectedRoute
            component={RewardsPageEdit}
            exact
            layout={Layout}
            path={"/odullerimiz-duzenle"}
          />
          <ProtectedRoute
            component={ReferencesPageEdit}
            exact
            layout={Layout}
            path={"/referanslarimiz-duzenle"}
          />
          <ProtectedRoute
            component={CertificatesPageEdit}
            exact
            layout={Layout}
            path={"/sertifikalarimiz-duzenle"}
          />
          <ProtectedRoute
            component={ResponsibilityPageEdit}
            exact
            layout={Layout}
            path={"/sorumluluklarimiz-duzenle"}
          />
          <ProtectedRoute
            component={ContactPageEdit}
            exact
            layout={Layout}
            path={"/iletisim-duzenle"}
          />

          <ProtectedRoute
            component={ProductList}
            exact
            layout={Layout}
            path={"/urunleri-goruntule"}
          />
          <ProtectedRoute
            component={ProductCreate}
            exact
            layout={Layout}
            path={"/urun-olustur"}
          />
          <ProtectedRoute
            component={ProductEdit}
            exact
            layout={Layout}
            path={"/urun-duzenle"}
          />

          <ProtectedRoute
            component={PolicyList}
            exact
            layout={Layout}
            path={"/politikalar"}
          />
          <ProtectedRoute
            component={PolicyCreate}
            exact
            layout={Layout}
            path={"/politika-olustur"}
          />
          <ProtectedRoute
            component={PolicyEdit}
            exact
            layout={Layout}
            path={"/politika-duzenle"}
          />

          <ProtectedRoute
            component={SlideList}
            exact
            layout={Layout}
            path={"/slaytlari-goruntule"}
          />
          <ProtectedRoute
            component={SlideCreate}
            exact
            layout={Layout}
            path={"/slayt-olustur"}
          />
          <ProtectedRoute
            component={SlideEdit}
            exact
            layout={Layout}
            path={"/slayt-duzenle"}
          />
          <ProtectedRoute
            component={FooterEdit}
            exact
            layout={Layout}
            path={"/alt-bilgi-duzenle"}
          />

          <ProtectedRoute
            component={SiteSettingsEdit}
            exact
            layout={Layout}
            path={"/ayarlar"}
          />
          <ProtectedRoute
            component={ProfileSettings}
            exact
            layout={Layout}
            path={"/profil-ayarlari"}
          />
          <ProtectedRoute
            component={Users}
            exact
            layout={Layout}
            path={"/kullanicilar"}
          />

          {/* <ProtectedRoute
                component={CustomerList}
                exact
                layout={Layout}
                path={"/customer-list"}
              />
    
              <ProtectedRoute
                component={CustomerDetails}
                exact
                layout={Layout}
                path={"/customer-details"}
              /> */}
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
