import AlertWarning from "components/ContentAlerts/AlertWarning";
import FileUploader from "components/FormElements/FileUploader";
import MainButton from "components/FormElements/MainButton";
import SecondaryButton from "components/FormElements/SecondaryButton";
import TextArea from "components/FormElements/TextArea";
import TextBox from "components/FormElements/TextBox";
import React, { useState, useEffect } from "react";
import { getCompanyProfileAdress } from "services/AllServices";

function AddMediaModal({ setOpenModal, type = "image", onClick, editData }) {
  const [uploadFile, setUploadFile] = useState();
  const [title, setTitle] = useState("");

  const addContent = () => {
    let configure = {};
    if (uploadFile && uploadFile.length !== 0) {
      if (!editData) {
        configure = {
          type: type,
          content: {
            title: title,
            image: uploadFile,
            file_name: uploadFile[0].name,
          },
        };
      } else {
        configure = {
          type: type,
          content: {
            title: title,
            id: editData.id,
            image: uploadFile,
            file_name: uploadFile[0].name,
          },
        };
      }

      onClick(configure, title !== "" ? title : null);
      setOpenModal(false);
    } else {
      alert("Lütfen boş alan bırakmayınız.");
    }
  };
  console.log(title);
  useEffect(() => {
    if (editData) {
      setTitle(editData.content_json?.file_title);
      setUploadFile(editData.content_json?.media);
    }
  }, []);

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white ">
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <div className="flex items-center justify-between px-5 py-3 ">
                  <p
                    className="text-xl font-semibold leading-6 text-main"
                    id="modal-headline"
                  >
                    Medya {editData ? "Düzenle" : "Ekle"}
                  </p>
                  <i
                    onClick={() => setOpenModal(false)}
                    className="text-2xl cursor-pointer ri-close-line text-main"
                  ></i>
                </div>
                <div className="px-5 pt-2 pb-5 bg-white">
                  {/* {type === "file" && ( */}
                  <TextBox
                    onChange={(v) => setTitle(v)}
                    label="Dosya Başlığı"
                    placeholder="Dosya için başlık giriniz.."
                    defaultValue={editData?.content_json?.file_title}
                  />
                  {/* )} */}
                  <FileUploader
                    id="download2Media"
                    file="fileUploadMedia2"
                    name="download2Media"
                    uploadId="uploadIdMedia2"
                    justFile={type === "file"}
                    value={uploadFile}
                    defaultText="Dosya Seçiniz"
                    required="required"
                    setValue={setUploadFile}
                    label="Başlık Görseli"
                    placeholder="Dosya seçiniz"
                    inputStyle="w-full mb-2 text-xs"
                    labelStyle="mt-3 mb-0"
                    multiple={false}
                    maxFile={10}
                    defaultValue={editData?.content_json?.file_path}
                  />
                </div>
                <div className="flex items-center justify-end px-5 pt-2 pb-5 bg-white">
                  <MainButton
                    type="button"
                    onClick={() => {
                      onClick && addContent();
                    }}
                    className=""
                    left
                    text="Kaydet"
                  >
                    <i className="mr-2 text-white ri-checkbox-circle-fill"></i>
                  </MainButton>
                  <SecondaryButton
                    onClick={() => setOpenModal(false)}
                    type="button"
                    text="Vazgeç"
                    style="ml-5"
                  ></SecondaryButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddMediaModal;
