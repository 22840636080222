import React, { useEffect, useRef, useState } from "react";
import TextBox from "components/FormElements/TextBox";
import TextArea from "components/FormElements/TextArea";
import SeoSettings from "components/SeoSettings/SeoSettings";
import { useGlobalStore } from "store/GlobalStore";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PageSettings from "components/PageSettings/PageSettings";

import * as yup from "yup";
import FileUploader from "components/FormElements/FileUploader";
import SecondaryButton from "components/FormElements/SecondaryButton";
import { useHistory } from "react-router-dom";
import { useSlugify } from "hooks";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FileCard from "components/Cards/FileCard";
import { ToastContainer, toast } from "react-toastify";
import AddMediaModal from "components/Modals/AddMediaModal";

import { createProducts, contentsAddFile } from "services/AllServices";

const schema = yup.object().shape({
  product_title: yup.string(),
  product_content_title: yup.string(),
  product_content_text: yup.string(),
  seo_title: yup.string(),
  seo_description: yup.string(),
  can_see: yup.bool(),
  product_image_list: yup.string(),
  marked_information_list: yup.string(),
});

function ProductCreate() {
  const history = useHistory();
  const [newsSeen, setNewsSeen] = useState(true);
  const [pageTitleSlug, setPageTitleSlug] = useSlugify();
  const [pageTitle, setPageTitle] = useState("");
  const [newsCategorySlug, setNewsCategorySlug] = useSlugify();
  const [file, uploadFile] = useState();
  const [categoryFile, setCategoryFile] = useState();

  const [pageData, setPageData] = useState();
  const [allImageList, setAllImageList] = useState([]);
  const [maxOrder, setMaxOrder] = useState(0);
  const [openAddCardModal, setOpenAddCardModal] = useState(false);
  const [openEditCardModal, setOpenEditCardModal] = useState(false);
  const [selectedEditCard, setselectedEditCard] = useState();
  const [activityReportData, setActivityReportData] = useState(null);
  // const [pageTitleSlug, setPageTitleSlug] = useSlugify();

  const [selectedLanguage] = useGlobalStore((state) => [
    state.selectedLanguage,
  ]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const notify = () => {
    toast.success("Kayıt Başarılı", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onSubmit = async (data) => {
    let categoryfileURL = "";

    if (categoryFile) {
      for (const [key, value] of Object.entries(categoryFile)) {
        let params = new FormData();
        params.append("files", value);
        await contentsAddFile(selectedLanguage.value, params).then(
          (response) => {
            categoryfileURL = response[0].file_path;
          }
        );
      }
    } else {
      alert("Lütfen Resim2 Seçiniz..");
    }

    const configureData = {
      language: selectedLanguage.value,
      page_url: `/${pageTitleSlug}`,
      product_title: data.product_title.replaceAll("'", "’"),
      product_content_text: data.product_content_text.replaceAll("'", "’"),
      product_content_title: data.product_content_title.replaceAll("'", "’"),
      marked_information_list: data.marked_information_list.replaceAll(
        "'",
        "’"
      ),
      order: 0,
      product_image: categoryfileURL,
      product_image_list: allImageList,
      seo_title: data.seo_title.replaceAll("'", "’"),
      seo_description: data.seo_description.replaceAll("'", "’"),
      can_see: data.can_see,
      last_operation_time: data.last_operation_time,
    };

    createProducts(configureData).then((res) => {
      notify();
      history.push("/urunleri-goruntule");
    });
    // };

    if (activityReportData && activityReportData.status !== "not_found") {
      let newOrder = [];
      activityReportData.map((item, index) => {
        newOrder.push({ id: item.id, order: index + 1 });
      });

      // updateActivityReportCardOrder(newOrder);
    }
  };

  const reOrder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newList = reOrder(
      allImageList,
      result.source.index,
      result.destination.index
    );
    setAllImageList(newList);
  }

  const onCreateActivityReportCard = async (file, file_title = null) => {
    let imageList = allImageList;

    if (file) {
      for (const [key, value] of Object.entries(file.content.image)) {
        let params = new FormData();
        params.append("files", value);
        contentsAddFile(file_title, params)
          .then(async (response) => {
            imageList.push({
              file_title: response[0].file_title,
              file_path: response[0].file_path,
              order: maxOrder + 1,
            });
            setMaxOrder(maxOrder + 1);

            setAllImageList([...imageList]);
          })
          .catch((e) => console.log("e", e));
      }
    } else {
      alert("Lütfen Dosya Seçiniz..");
    }
  };

  const onUpdateActivityReportCard = (file, file_title = null) => {
    let newArr = [];
    if (file) {
      for (const [key, value] of Object.entries(file)) {
        let params = new FormData();
        params.append("files", value);
        contentsAddFile(file_title, params).then(async (res) => {
          allImageList.map((item) => {
            if (item.order !== selectedEditCard.order) {
              newArr.push(item);
            } else {
              let newItem = {
                order: selectedEditCard.order,
                file_path: res[0].file_path,
                file_title: res[0].file_title,
              };
              newArr.push(newItem);
            }
          });
          setAllImageList(newArr);
        });
      }
    } else {
      alert("Lütfen Dosya Seçiniz..");
    }
  };

  const onDeleteCard = (order) => {
    let newArr = [];
    allImageList.map((item) => {
      if (item.order !== order) {
        newArr.push(item);
      }
    });
    setAllImageList(newArr);
  };

  useEffect(() => {
    console.log(allImageList, "allImageList");
  }, [allImageList]);

  // useEffect(() => {
  //   createProducts(selectedLanguage.value).then(async (res) => {
  //     reset(res);
  //     await setPageData(null);
  //     setPageData(res);
  //
  //     setAllImageList(res.image_list);

  //     if (res.image_list.length === 0) {
  //       setMaxOrder(0);
  //     } else {
  //       let lastItem = res.image_list.slice(-1);

  //       setMaxOrder(lastItem[0].order);
  //     }
  //   });
  // }, [selectedLanguage]);

  useEffect(() => {
    setPageTitleSlug(pageTitle);
  }, [pageTitle]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between ">
          <div className="container pr-4 pt-6 pb-4  overflow-y-scroll h-screen ">
            <h1 className="font-semibold text-2xl text-main mb-5">
              Ürün Oluşturma
            </h1>
            <TextBox
              name="product_title"
              value={pageTitle}
              onChange={(v) => setPageTitle(v)}
              placeholder="Ürün Başlığı"
              innerRef={{ ...register("product_title") }}
              required
            />
            <div className="flex items-center justify-between">
              <p className="text-mainText">
                {selectedLanguage.value === "tr"
                  ? `cemofset.com/urunlerimiz/${pageTitleSlug}`
                  : `cemofset.com/${selectedLanguage.value}/products/${pageTitleSlug}`}{" "}
              </p>
              {/* <TextBox placeholder="getirgsyf" style="w-2/3" /> */}
            </div>

            <FileUploader
              id="download1"
              file="fileUpload1"
              name="product_image"
              uploadId="uploadId1"
              value={categoryFile}
              defaultText="Dosya Seçiniz"
              required="required"
              setValue={setCategoryFile}
              label="Anasayfa ve Kategori Görseli"
              placeholder="Dosya seçiniz"
              inputStyle="w-full mb-2 text-xs"
              labelStyle="mt-3 mb-0"
              multiple={true}
              maxFile={10}
            />
            <p className="text-mainText text-xs">
              Ana sayfa ve kategorilerde görünecek görsel boyutu 600 X 600
              Boyutunda olmalıdır.
            </p>
            <TextBox
              style="mt-4"
              label="İçerik Başlığı"
              name="product_content_title"
              placeholder="Ürünlerinizin zarar görmeden ve en şık şekilde tüketiciye ulaşması önceliğimiz."
              innerRef={{ ...register("product_content_title") }}
              required
            />
            <TextArea
              style="mt-4"
              name="product_content_text"
              label="İçerik Metni"
              placeholder="6’sı tasarıma dönük 10 kişilik ArGe ekibimizle tasarım ve uygulama ihtiyaçlarınıza çözümler sunar, 4 sırada 8 renge kadar baskı yapma kabiliyeti ve 120 gram ile laminasyon sıvama özelliği gibi teknolojilerimiz ile ürünlerinizi en hızlı ve kaliteli şekilde tüketicinize ulaştırmanızı sağlarız.
              "
              styleName="mt-3"
              innerRef={{ ...register("product_content_text") }}
              required
              rows="6"
            />
            <h1 className="font-semibold text-xl text-main mb-2 mt-3">
              İşaretli Bilgiler
            </h1>
            <TextArea
              style="mt-4"
              name="marked_information_list"
              label="İçerik Metni"
              placeholder="
              Aynı anda 3 kamyona kadar yükleme yapabilen platform,
              120 gram laminasyon sıvası yapabilme,
              Yıllık 40 milyon sayfalık üretim hacmi,
              4 sırada 8 renge kadar baskı kabiliyeti"
              styleName="mt-3"
              innerRef={{ ...register("marked_information_list") }}
              required
              rows="10"
            />

            <h1 className="font-semibold text-xl text-main mb-2 mt-3">
              Ürün Görselleri
            </h1>
            {allImageList && allImageList.length != 0 && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {allImageList?.map((item, index) => {
                        return (
                          <Draggable
                            key={item?.order?.toString()}
                            draggableId={item?.order?.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="mb-5"
                              >
                                <FileCard
                                  name="Dosya"
                                  datas={item}
                                  id={item.order}
                                  inf1={item.file_path}
                                  deleteClick={(order) => onDeleteCard(order)}
                                  editClick={(datas) => {
                                    setOpenEditCardModal(true);
                                    setselectedEditCard(datas);
                                  }}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
            <SecondaryButton
              onClick={() => setOpenAddCardModal(true)}
              type="button"
              left
              text="YENİ ALAN EKLE"
              style="w-full"
            >
              <i className="ri-add-circle-fill text-lg text-secondary mr-2"></i>
            </SecondaryButton>

            <SeoSettings register={register} />
          </div>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <ToastContainer />
          <PageSettings />
        </div>
      </form>
      {openAddCardModal && (
        <AddMediaModal
          setOpenModal={() => setOpenAddCardModal(false)}
          onClick={(file, file_title) =>
            onCreateActivityReportCard(file, file_title)
          }
        />
      )}

      {openEditCardModal && (
        <AddMediaModal
          setOpenModal={() => setOpenEditCardModal(false)}
          onClick={(file, file_title, data) =>
            onUpdateActivityReportCard(file, file_title, data)
          }
          datas={{ content_json: selectedEditCard }}
        />
      )}
    </div>
  );
}

export default ProductCreate;
