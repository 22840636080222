import MainButton from "components/FormElements/MainButton";
import DeleteModal from "components/Modals/DeleteModal";
import ProductsTable from "components/Products/ProductsTable";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  getProductsList,
  updateProductOrder,
  deleteProducts,
} from "services/AllServices";
import { useAuthStore } from "store/AuthStore";
import ProductDndTable from "components/Products/ProductDndTable";
import SecondaryButton from "components/FormElements/SecondaryButton";
import { useGlobalStore } from "store/GlobalStore";

function ProductList() {
  const history = useHistory();
  const [productList, setProductList] = useState();
  const [pageData, setPageData] = useState();
  const [deleteID, setDeleteID] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedLanguage] = useGlobalStore((state) => [
    state.selectedLanguage,
  ]);

  // const onLogOut = () => {
  //   logout()
  //     .then((res) => {
  //       setIsLoggin(false);
  //       localStorage.clear();
  //       history.push("/login");
  //     })
  //     .catch(setIsLoggin(false), localStorage.clear(), history.push("/login"));
  // };

  const notify = () => {
    toast.success("Başarılı", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onGetProductList = () => {
    getProductsList().then((res) => {
      setProductList(res.sort((a, b) => (a.order > b.order ? 1 : -1)));
    });
  };

  const onUpdateProductOrder = () => {
    let orderList = [];
    productList.map((item, index) => {
      let configure = { id: item.id, order: index + 1 };
      orderList.push(configure);
    });

    updateProductOrder(orderList).then((res) => {
      onGetProductList();
      notify();
    });
  };

  const onDeleteProduct = async (id) => {
    deleteProducts(id).then(async (res) => {
      notify();
      await setProductList();
      onGetProductList();
    });
  };

  // const onUpdateFile = () => {
  //   if (slideVideo) {
  //     for (const [key, value] of Object.entries(slideVideo)) {
  //       let params = new FormData();
  //       params.append("files", value);
  //       contentsAddFile(selectedLanguage.value, params).then((response) => {
  //         let conf = {
  //           video_source: response[0].file_path,
  //         };
  //         createSlideVideo(conf).then((res) => {
  //           notify();
  //         });
  //       });
  //     }
  //   } else {
  //     alert("Lütfen Resim Seçiniz..");
  //   }

  // if (file) {
  //   for (const [key, value] of Object.entries(file)) {
  //     let params = new FormData();
  //     params.append("file", value);
  //     updateHomepageImage(selectedLanguage.value, params).then((response) => {
  //       notify();
  //     });
  //   }
  // } else {
  //   alert("Lütfen Resim Seçiniz..");
  // }
  //};

  useEffect(() => {
    // getSlideVideo().then((res) => {
    //   setSlideVideoUrl(res);
    // });
    onGetProductList();
  }, []);

  return (
    <div className="container pt-6 pr-3 pb-3 overflow-y-scroll h-screen">
      <div className="flex items-center justify-between">
        <h1 className="font-semibold text-2xl text-main">Ürünleri Düzenle</h1>

        <div className="flex items-center">
          <MainButton
            onClick={() => onUpdateProductOrder()}
            style="mr-4"
            left
            text="Sıralamayı Kaydet"
          >
            <i className="ri-save-fill text-white mr-2"></i>
          </MainButton>
          <Link to="/urun-olustur">
            <MainButton className="" left text="Ürün Oluştur">
              <i className="ri-add-circle-fill text-white mr-2"></i>
            </MainButton>
          </Link>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-5">
        {/* <div className="col-span-12 lg:col-span-10 ">
          {slideVideoUrl && (
            <FileUploader
              id="download1"
              file="fileUpload1"
              name="download1"
              uploadId="uploadId1"
              value={slideVideo}
              defaultText="Dosya Seçiniz"
              required="required"
              setValue={setSlideVideo}
              label="Tanıtım Videosu Kaynağı"
              placeholder="Dosya seçiniz"
              inputStyle="w-full mb-2 text-xs"
              labelStyle="mt-3 mb-0"
              multiple={false}
              maxFile={10}
              defaultValue={slideVideoUrl}
            />
          )}
        </div> */}
        {/* <div className="col-span-12 lg:col-span-2 flex items-center lg:mt-6">
          <SecondaryButton
            onClick={() => onUpdateFile()}
            type="button"
            left
            text="Kaydet"
            style="w-full"
          >
            <i className="ri-checkbox-circle-fill text-lg text-white mr-2"></i>
          </SecondaryButton>
        </div> */}
      </div>
      <div className="mt-10">
        {/* {slideList && slideList?.status !== "record_not_found" ? (
          <SlaytTable
            datas={slideList}
            onDeleteSlide={(id) => onDeleteSlide(id)}
          />

        )
          : <div>Slide yok</div>
        } */}

        {productList && productList?.status !== "record_not_found" ? (
          <ProductDndTable
            datas={productList}
            setDatas={(v) => setProductList(v)}
            onDeleteProduct={(id) => onDeleteProduct(id)}
          />
        ) : (
          <div className="text-center">Her hangi bir slaytınız yok!</div>
        )}
      </div>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />

      {openDeleteModal && (
        <DeleteModal
          setOpenModal={() => setOpenDeleteModal(false)}
          title="Ürünü Sil"
          question="Ürünü silmek istediğinize emin misiniz ?"
          buttonText="Sil"
          notify={() => notify()}
          onClick={() => {
            onDeleteProduct(deleteID);
            setOpenDeleteModal(false);
          }}
          id={deleteID}
        />
      )}
    </div>
  );
}

export default ProductList;
