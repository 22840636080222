import { useState, useEffect, useRef } from "react";
import slugify from "react-slugify";

export const useComboBox = (selectedItemText = "", defaultList = []) => {
  const [selectedItem, setSelectedItem] = useState({
    id: 0,
    text: selectedItemText,
  });
  const [itemList, setItems] = useState(defaultList);

  const changeSelectedItem = (id, text) => {
    setSelectedItem({ id: id, text: text });
  };

  const setItemList = (
    items,
    idParam,
    textParam,
    secondTextParam = null,
    textSeperator = null
  ) => {
    let newItemList = [];
    for (let i = 0; i < items.length; i++)
      if (secondTextParam === null)
        newItemList.push({ id: items[i][idParam], text: items[i][textParam] });
      else
        newItemList.push({
          id: items[i][idParam],
          text: items[i][textParam] + textSeperator + items[i][secondTextParam],
        });
    setItems(newItemList);
  };

  return [selectedItem, itemList, changeSelectedItem, setItemList];
};

export const useMultiselectComboBox = (selectedItemText = "") => {
  const [selectedItems, setSelectedItems] = useState([
    { id: 0, text: selectedItemText },
  ]);
  const [itemList, setItems] = useState([]);

  const checkIfExist = (id) => {
    for (let i = 0; i < selectedItems.length; i++)
      if (selectedItems[i].id === id) return true;
    return false;
  };

  const removeFromList = (id) => {
    let newList = [];
    for (let i = 0; i < selectedItems.length; i++) {
      if (selectedItems[i].id !== id) newList.push(selectedItems[i]);
    }
    setSelectedItems(newList);
  };

  const addToList = (id, text) => {
    let newList = [];
    for (let i = 0; i < selectedItems.length; i++)
      if (selectedItems[0].id !== 0) newList.push(selectedItems[i]);
    newList.push({ id: id, text: text });
    setSelectedItems(newList);
  };

  const toggleItems = (id, text) => {
    if (id > 0)
      if (checkIfExist(id)) removeFromList(id);
      else addToList(id, text);
    else setSelectedItems([{ id: id, text: text }]);
  };

  const setItemList = (
    items,
    idParam,
    textParam,
    secondTextParam = null,
    textSeperator = null
  ) => {
    let newItemList = [];
    for (let i = 0; i < items.length; i++)
      if (secondTextParam === null)
        newItemList.push({ id: items[i][idParam], text: items[i][textParam] });
      else
        newItemList.push({
          id: items[i][idParam],
          text: items[i][textParam] + textSeperator + items[i][secondTextParam],
        });
    setItems(newItemList);
  };

  return [selectedItems, itemList, toggleItems, setItemList];
};

export function useToggle(defaultValue) {
  const [data, setData] = useState(defaultValue);

  function toggle() {
    setData(!data);
  }

  return [data, toggle];
}

export function useToggleSidebar() {
  const [menu1Open, setmenu1Open] = useState(false);
  const [menu2Open, setmenu2Open] = useState(false);
  const [menu3Open, setmenu3Open] = useState(false);
  const [menu4Open, setmenu4Open] = useState(false);
  const [menu5Open, setmenu5Open] = useState(false);
  const [menu6Open, setmenu6Open] = useState(false);
  const [menu7Open, setmenu7Open] = useState(false);
  const [menu8Open, setmenu8Open] = useState(false);

  function toggle(value) {
    closeToggles();
    if (value === "1") {
      setmenu1Open(!menu1Open);
    }
    if (value === "2") setmenu2Open(!menu2Open);
    if (value === "3") setmenu3Open(!menu3Open);
    if (value === "4") setmenu4Open(!menu4Open);
    if (value === "5") setmenu5Open(!menu5Open);
    if (value === "6") setmenu6Open(!menu6Open);
    if (value === "7") setmenu7Open(!menu7Open);
    if (value === "8") setmenu8Open(!menu8Open);
  }
  function closeToggles() {
    setmenu1Open(false);
    setmenu2Open(false);
    setmenu3Open(false);
    setmenu4Open(false);
    setmenu5Open(false);
    setmenu6Open(false);
    setmenu7Open(false);
    setmenu8Open(false);
  }
  return [
    menu1Open,
    menu2Open,
    menu3Open,
    menu4Open,
    menu5Open,
    menu6Open,
    menu7Open,
    menu8Open,
    toggle,
    closeToggles,
  ];
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export function useCheckList() {
  const [checkList, setCheckList] = useState([]);

  function onSetCheckList(name) {
    let newList = [];
    if (checkList.length > 0) {
      if (checkList.some((n) => n === name)) {
        checkList.map((item) => {
          if (item !== name) {
            newList.push(item);
          }
        });
      } else {
        newList = [...checkList];
        newList.push(name);
      }
    } else {
      newList.push(name);
    }
    setCheckList(newList);
  }
  return [checkList, onSetCheckList, setCheckList];
}
export function useCheckListObj() {
  const [checkList, setCheckList] = useState([]);

  function onSetCheckList(obj) {
    let newList = [];
    if (checkList.length > 0) {
      if (checkList.some((n) => n.id === obj.id)) {
        checkList.map((item) => {
          if (item.id !== obj.id) {
            newList.push(item);
          }
        });
      } else {
        newList = [...checkList];
        newList.push(obj);
      }
    } else {
      newList.push(obj);
    }
    setCheckList(newList);
  }
  return [checkList, onSetCheckList, setCheckList];
}

export function useCloseSelectBox(func) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  //when outside of the combobox clicked, it will be closed
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          func(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  return [wrapperRef];
}

export function useConfigureSelectBoxItem() {
  const [allData, setAllData] = useState([]);

  function configureSelectBoxItem(data, id, text) {
    let configure = [];
    data.map((item) => {
      configure.push({
        id: item[id],
        text: item[text],
      });
    });
    setAllData(configure);
  }

  return [allData, configureSelectBoxItem];
}

export function useSlugify() {
  const [slugData, setSlugData] = useState();

  function configureSelectBoxItem(data) {
    let slugTitle = data;
    slugTitle = slugTitle.replaceAll("ı", "i");
    slugTitle = slugTitle.replaceAll("İ", "i");
    slugTitle = slugTitle.replaceAll("ö", "o");
    slugTitle = slugTitle.replaceAll("ç", "c");
    slugTitle = slugTitle.replaceAll("ü", "u");
    slugTitle = slugTitle.replaceAll("ğ", "g");
    slugTitle = slugTitle.replaceAll("ş", "s");

    setSlugData(slugify(slugTitle));
  }

  return [slugData, configureSelectBoxItem];
}
