import GrayButton from "components/FormElements/GrayButton";
import MainButton from "components/FormElements/MainButton";
import RedButton from "components/FormElements/RedButton";
import SecondaryButton from "components/FormElements/SecondaryButton";
import React, { useState } from "react";

function DeleteModal({
  title,
  question,
  description,
  errDescription,
  setOpenModal,
  buttonText,
  onClick,
}) {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block align-bottom bg-white  text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white">
            <div className="flex items-center justify-between bg-alertRed px-2 py-3">
              <div className="flex items-center">
                <i
                  onClick={() => setOpenModal(false)}
                  className="ri-error-warning-fill text-2xl text-white text-passive cursor-pointer"
                ></i>
                <h2 className="font-semibold text-white ml-2">{title}</h2>
              </div>
              <i
                onClick={() => setOpenModal(false)}
                className="ri-close-line text-2xl text-white text-passive cursor-pointer"
              ></i>
            </div>

            <div className="flex flex-col items-center justify-center px-5  py-10 ">
              {question && <p className="text-mainText">{question}</p>}
              {description && (
                <p className="mt-5 text-center lg:mx-10">{description}</p>
              )}
            </div>

            <div className=" bg-white px-5 lg:px-10">
              <div className="flex flex-col-reverse lg:flex-row items-end justify-end border-t-2 py-4">
                <GrayButton
                  onClick={() => setOpenModal(false)}
                  style="px-12 mr-0 mt-3 lg:mt-0 lg:mr-5 w-full lg:w-auto"
                  tertiary
                >
                  Vazgeç
                </GrayButton>
                <RedButton
                  onClick={() => onClick()}
                  style="px-20 ml-5   w-full lg:w-auto"
                >
                  {buttonText}
                </RedButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteModal;
