import IconTextBox from "components/FormElements/IconTextBox";
import MainButton from "components/FormElements/MainButton";
import SecondaryButton from "components/FormElements/SecondaryButton";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { logout, updateStaffPassword } from "services/AllServices";
import { useAuthStore } from "store/AuthStore";
import { useHistory } from "react-router-dom";

const schema = yup.object().shape({
  oldPassword: yup.string(),
  newPassword: yup.string(),
  newPasswordAgain: yup.string(),
});

function ChangePassword() {
  const [isLoggin, setIsLoggin, setLogginOpen, setUserInformations] =
    useAuthStore((state) => [
      state.isLoggin,
      state.setIsLoggin,
      state.setLogginOpen,
      state.setUserInformations,
    ]);
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onLogOut = () => {
    logout()
      .then((res) => {
        setIsLoggin(false);
        localStorage.clear();
        history.push("/login");
      })
      .catch(setIsLoggin(false), localStorage.clear(), history.push("/login"));
  };

  const onChangePassword = (data) => {
    if (data.newPassword !== data.newPasswordAgain) {
      alert("Lütfen şifreleri aynı giriniz.");
    } else {
      updateStaffPassword(data.oldPassword, data.newPassword).then((res) => {
        if (res.status === "incorrect_password") {
          alert("Eski parolanızı doğru giriniz.");
        } else {
          alert("Şifre Değişimi Başarılı.");
          onLogOut();
        }
      });
    }
  };

  return (
    <div className="mt-10">
      <h1 className="font-semibold text-2xl text-main ">Parola Değiştirme</h1>
      <form onSubmit={handleSubmit(onChangePassword)}>
        <IconTextBox
          label="Eski Parola"
          name="oldPassword"
          type="password"
          placeholder="•••••••"
          iconChange={true}
          textBoxIcon="ri-eye-line"
          textBoxSecondIcon="ri-eye-off-line"
          style="mt-6"
          innerRef={{ ...register("oldPassword") }}
          required
        />

        <IconTextBox
          label="Yeni Parola"
          name="newPassword"
          type="password"
          placeholder="•••••••"
          iconChange={true}
          textBoxIcon="ri-eye-line"
          textBoxSecondIcon="ri-eye-off-line"
          style="mt-4"
          innerRef={{ ...register("newPassword") }}
          required
        />

        <IconTextBox
          label="Yeni Parola Yeniden"
          name="newPasswordAgain"
          type="password"
          placeholder="•••••••"
          iconChange={true}
          textBoxIcon="ri-eye-line"
          textBoxSecondIcon="ri-eye-off-line"
          style="mt-4"
          innerRef={{ ...register("newPasswordAgain") }}
          required
        />
        <MainButton style="mt-12 w-full" left text="Parolayı Değiştir">
          <i className="ri-checkbox-circle-fill text-white mr-2"></i>
        </MainButton>
      </form>
    </div>
  );
}

export default ChangePassword;
