import moment from "moment";
import React, { useState } from "react";
import { useTable, useSortBy } from "react-table";

function HomeLogTable({ datas }) {
  const data = React.useMemo(() => datas, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Tarih",
        accessor: "login_when",
        Cell: (props) =>
          props.value !== null
            ? moment(props.value).format("DD-MM-YYYY / h:mm:ss")
            : "-",
      },
      {
        Header: "IP",
        accessor: "ip_address",
      },
      {
        Header: "Durum",
        accessor: "status",
        Cell: settings,
      },
    ],
    []
  );

  function settings(props) {
    return (
      <div className=" ">
        {props.value === "fail" ? (
          <div className="flex items-center justify-center bg-alertRed rounded-2xl py-1">
            <p className="text-white">Başarısız</p>
          </div>
        ) : (
          <div className="flex items-center justify-center bg-alertGreen rounded-2xl py-1">
            <p className="text-white">Başarılı</p>
          </div>
        )}
      </div>
    );
  }
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <table {...getTableProps()} className="w-full">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {/* {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps()}
                                className="bg-main text-white p-3 text-left text-sm"
                            >
                                {column.render('Header')}
                            </th>
                        ))} */}
            {headerGroup.headers.map((column) => (
              <th
                className="bg-mainDark text-white p-3 text-left text-sm"
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render("Header")}
                <span className="flex relative justify-between items-center">
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <svg
                        className="absolute right-0 -top-3"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 6L0.669873 0.749999L9.33013 0.75L5 6Z"
                          fill="#D8D8D8"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="absolute right-0 -top-3"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 0L9.33013 5.25L0.669873 5.25L5 0Z"
                          fill="#D8D8D8"
                        />
                      </svg>
                    )
                  ) : (
                    ""
                  )}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className={
                index % 2 === 0 ? " bg-white p-2" : " bg-borderGray p-2"
              }
            >
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()} className="p-2 text-main">
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default HomeLogTable;
