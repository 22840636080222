import create from "zustand";
import { devtools } from "zustand/middleware";

export const useGlobalStore = create(
  devtools((set, get) => ({
    selectedLanguage: JSON.parse(
      localStorage.getItem("__selectedLanguage")
    ) || { id: 1, text: "Türkçe", value: "tr" },
    setSelectedLanguage: (value) => {
      set((state) => ({
        selectedLanguage: value,
      }));

      get().addToLocalStorageGlobal();
    },

    addToLocalStorageGlobal: () => {
      // localStorage.removeItem("__products")
      localStorage.setItem(
        "__selectedLanguage",
        JSON.stringify(get().selectedLanguage)
      );
    },
    checkLocalStorageGlobal: () => {
      if (
        typeof localStorage.getItem("__selectedLanguage") !== "undefined" &&
        localStorage.getItem("__selectedLanguage") !== null &&
        localStorage.getItem("__selectedLanguage") !== ""
      )
        set((state) => ({
          selectedLanguage: JSON.parse(
            localStorage.getItem("__selectedLanguage")
          ),
        }));
    },
  }))
);
