import React, { useEffect, useRef, useState } from "react";

import TextBox from "components/FormElements/TextBox";
import TextArea from "components/FormElements/TextArea";
import SeoSettings from "components/SeoSettings/SeoSettings";
import { useGlobalStore } from "store/GlobalStore";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PageSettings from "components/PageSettings/PageSettings";
import { ToastContainer, toast } from "react-toastify";
import * as yup from "yup";
import { createPolicies } from "services/AllServices";
import { useHistory } from "react-router-dom";
import { useSlugify } from "hooks";

const schema = yup.object().shape({
  policy_title: yup.string(),
  policy_content: yup.string(),
  seo_title: yup.string(),
  seo_description: yup.string(),
  can_see: yup.bool(),
});

function PoliticCreate() {
  const history = useHistory();
  const [newsSeen, setNewsSeen] = useState(true);
  const [pageTitleSlug, setPageTitleSlug] = useSlugify();
  const [pageTitle, setPageTitle] = useState("");

  const [selectedLanguage] = useGlobalStore((state) => [
    state.selectedLanguage,
  ]);
  const notify = () => {
    toast.success("Kayıt Başarılı", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (data) => {
    const configureData = {
      language: selectedLanguage.value,
      page_url: `/${pageTitleSlug}`,

      policy_title: data.policy_title.replaceAll("'", "’"),
      policy_content: data.policy_content.replaceAll("'", "’"),
      seo_title: data.seo_title.replaceAll("'", "’"),
      seo_description: data.seo_description.replaceAll("'", "’"),
      can_see: data.can_see,
      policy_content_title: null,
      policy_content_text: null,
      marked_information_list: null,
      policy_image_list: null,
    };

    createPolicies(configureData).then((res) => {
      notify();
      history.push("/politikalar");
    });
  };

  useEffect(() => {
    setPageTitleSlug(pageTitle);
  }, [pageTitle]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between ">
          <div className="container pr-4 pt-6 pb-4  overflow-y-scroll h-screen ">
            <h1 className="font-semibold text-2xl text-main mb-5">
              Politika Oluşturma
            </h1>
            <TextBox
              name="policy_title"
              value={pageTitle}
              onChange={(v) => setPageTitle(v)}
              placeholder="Suç Gelirlerinin Aklanmasının ve Terörün Finansmanının Önlenmesi Poltikamız"
              innerRef={{ ...register("policy_title") }}
              required
            />
            <div className="flex items-center justify-between">
              <p className="text-mainText">
                {selectedLanguage.value === "tr"
                  ? `cemofset.com/politikalarimiz/${pageTitleSlug}`
                  : `cemofset.com/${selectedLanguage.value}/policies/${pageTitleSlug}`}{" "}
              </p>
              {/* <TextBox placeholder="getirgsyf" style="w-2/3" /> */}
            </div>

            <TextArea
              name="policy_content"
              label="Açıklama Metni"
              placeholder=""
              style="mt-3"
              rows="14"
              innerRef={{ ...register("policy_content") }}
              required
            />

            <SeoSettings register={register} />
          </div>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <ToastContainer />
          <PageSettings
          // setNewsSeen={(v) => setNewsSeen(v)}
          // setSelectedNewsCategory={(v) => setSelectedCategory(v)}
          // news
          />
        </div>
      </form>
    </div>
  );
}

export default PoliticCreate;
