import TextBox from "components/FormElements/TextBox";
import PageSettings from "components/PageSettings/PageSettings";
import React, { useEffect, useState } from "react";
import { useGlobalStore } from "store/GlobalStore";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getFooterInfo, updateFooterInfo } from "services/AllServices";
import { ToastContainer, toast } from "react-toastify";
import TextArea from "components/FormElements/TextArea";

const schema = yup.object().shape({
  email: yup.string(),
  phone: yup.string(),
  address: yup.string(),
  aboutus: yup.string(),
  title_text: yup.string(),
  title_sub_text: yup.string(),
  button_text: yup.string(),
  button_url: yup.string(),
  last_operation_time: yup.string(),
});

function FooterEdit() {
  const [pageData, setPageData] = useState();
  const [selectedLanguage] = useGlobalStore((state) => [
    state.selectedLanguage,
  ]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (data) => {
    const configureData = {
      language: selectedLanguage.value,
      email: data.email.replaceAll("'", "’"),
      phone: data.phone.replaceAll("'", "’"),
      address: data.address.replaceAll("'", "’"),
      title_text: data.title_text.replaceAll("'", "’"),
      aboutus: data.aboutus.replaceAll("'", "’"),
      title_sub_text: data.title_sub_text.replaceAll("'", "’"),
      button_text: data.button_text.replaceAll("'", "’"),
      button_url: data.button_url.replaceAll("'", "’"),
      last_operation_time: data.last_operation_time,
    };

    const notify = () => {
      toast.success("Kayıt Başarılı", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };
    updateFooterInfo(selectedLanguage.value, { content: configureData }).then(
      (res) => {
        notify();
      }
    );
  };

  useEffect(() => {
    getFooterInfo(selectedLanguage.value).then(async (res) => {
      reset(res);
      await setPageData(null);
      setPageData(res);
    });
  }, [selectedLanguage]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className=" flex justify-between">
        <div className="container pt-6 overflow-y-scroll h-screen">
          <div className="flex items-center justify-between">
            <h1 className="font-semibold text-2xl text-main">
              Alt Bilgi Ayarları
            </h1>
          </div>
          <h2 className="font-semibold text-xl text-main mt-8 mb-4">
            İletişim Ayarları
          </h2>
          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-6">
              <TextBox
                name="email"
                label="E-Posta"
                placeholder="info@cemofset.com"
                innerRef={{ ...register("email") }}
                required
              />
            </div>
            <div className="col-span-6">
              <TextBox
                name="phone"
                label="Telefon"
                placeholder="+90 999 999 99 99"
                innerRef={{ ...register("phone") }}
                required
              />
            </div>

            <div className="col-span-12">
              <TextArea
                name="address"
                label="Adres"
                placeholder="Beşyol Fabrikalar Cad. No:21 Sefaköy / Istanbul PK: 34295"
                innerRef={{ ...register("address") }}
                required
              />
            </div>

            <div className="col-span-12">
              <TextArea
                name="aboutus"
                label="Hakkımızda Metni"
                placeholder="Kurulduğumuz günden bu güne kadar profesyonel olarak matbaa ve basım alanında hizmet sunmaktayız."
                innerRef={{ ...register("aboutus") }}
                required
              />
            </div>
          </div>

          <h2 className="font-semibold text-xl text-main mt-8 mb-4">
            Ürün Alt Bilgi Alanı
          </h2>

          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-6">
              <TextBox
                name="title_text"
                label="Başlık Metni"
                placeholder="Sizler de Yerinizi Alın"
                innerRef={{ ...register("title_text") }}
                required
              />
            </div>
            <div className="col-span-6">
              <TextBox
                name="title_sub_text"
                label="Başlık Alt Metni"
                placeholder="Bizi tercih eden onlarca büyük firma arasında bu gün yerinizi alın."
                innerRef={{ ...register("title_sub_text") }}
                required
              />
            </div>

            <div className="col-span-6">
              <TextBox
                name="button_text"
                label="Buton Metni"
                placeholder="İletişime Geçin"
                innerRef={{ ...register("button_text") }}
                required
              />
            </div>
            <div className="col-span-6">
              <TextBox
                name="button_url"
                label="Buton URL Adresi"
                placeholder="https://www.cemofset.com/iletisim"
                innerRef={{ ...register("button_url") }}
                required
              />
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ToastContainer />

        <PageSettings date={pageData?.last_operation_time} />
      </div>
    </form>
  );
}

export default FooterEdit;
