import MainButton from "components/FormElements/MainButton";
//import SlaytTable from "components/Slide/SlaytTable";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FileUploader from "components/FormElements/FileUploader";
import { useHistory } from "react-router-dom";
import {
  contentsAddFile,
  deleteSlideRecords,
  getSlideRecordsList,
  deleteSlideVideo,
  logout,
  updateSlideOrder,
  createSlideVideo,
  getSlideVideo,
} from "services/AllServices";
import SlaytDndTable from "components/Slide/SlaytDndTable";
import { ToastContainer, toast } from "react-toastify";
import SecondaryButton from "components/FormElements/SecondaryButton";
import { useGlobalStore } from "store/GlobalStore";
import DeleteModal from "components/Modals/DeleteModal";

function SlideList() {
  const history = useHistory();
  const [slideVideo, setSlideVideo] = useState();
  const [slideVideoUrl, setSlideVideoUrl] = useState();

  const [slideList, setSlideList] = useState();
  const [file, uploadFile] = useState();
  const [pageData, setPageData] = useState();
  const [deleteID, setDeleteID] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedLanguage] = useGlobalStore((state) => [
    state.selectedLanguage,
  ]);

  // const onLogOut = () => {
  //   logout()
  //     .then((res) => {
  //       setIsLoggin(false);
  //       localStorage.clear();
  //       history.push("/login");
  //     })
  //     .catch(setIsLoggin(false), localStorage.clear(), history.push("/login"));
  // };

  const notify = () => {
    toast.success("Başarılı", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onGetSlideList = () => {
    getSlideRecordsList().then((res) => {
      setSlideList(res);
    });
  };

  const onUpdateSlideOrder = () => {
    let orderList = [];
    slideList.map((item, index) => {
      let configure = { id: item.id, order: index + 1 };
      orderList.push(configure);
    });

    updateSlideOrder(orderList).then((res) => {
      onGetSlideList();
      notify();
    });
  };

  const onDeleteSlide = async (id) => {
    deleteSlideRecords(id).then(async (res) => {
      notify();
      await setSlideList();
      onGetSlideList();
    });
  };

  const onUpdateFile = () => {
    if (slideVideo) {
      for (const [key, value] of Object.entries(slideVideo)) {
        let params = new FormData();
        params.append("files", value);
        contentsAddFile(selectedLanguage.value, params).then((response) => {
          let conf = {
            video_source: response[0].file_path,
          };
          createSlideVideo(conf).then((res) => {
            notify();
          });
        });
      }
    } else {
      alert("Lütfen Resim Seçiniz..");
    }

    // if (file) {
    //   for (const [key, value] of Object.entries(file)) {
    //     let params = new FormData();
    //     params.append("file", value);
    //     updateHomepageImage(selectedLanguage.value, params).then((response) => {
    //       notify();
    //     });
    //   }
    // } else {
    //   alert("Lütfen Resim Seçiniz..");
    // }
  };

  useEffect(() => {
    getSlideVideo().then((res) => {
      setSlideVideoUrl(res);
    });
    onGetSlideList();
  }, []);

  return (
    <div className="container pt-6 pr-3 pb-3 overflow-y-scroll h-screen">
      <div className="flex items-center justify-between">
        <h1 className="font-semibold text-2xl text-main">Slaytlari Düzenle</h1>

        <div className="flex items-center">
          <MainButton
            onClick={() => onUpdateSlideOrder()}
            style="mr-4"
            left
            text="Sıralamayı Kaydet"
          >
            <i className="ri-save-fill text-white mr-2"></i>
          </MainButton>
          <Link to="/slayt-olustur">
            <MainButton className="" left text="Slayt Oluştur">
              <i className="ri-add-circle-fill text-white mr-2"></i>
            </MainButton>
          </Link>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-12 lg:col-span-10 ">
          {slideVideoUrl && (
            <FileUploader
              id="download1"
              file="fileUpload1"
              name="download1"
              uploadId="uploadId1"
              value={slideVideo}
              defaultText="Dosya Seçiniz"
              required="required"
              setValue={setSlideVideo}
              label="Tanıtım Videosu Kaynağı"
              placeholder="Dosya seçiniz"
              inputStyle="w-full mb-2 text-xs"
              labelStyle="mt-3 mb-0"
              multiple={false}
              maxFile={10}
              defaultValue={slideVideoUrl}
            />
          )}
        </div>
        <div className="col-span-12 lg:col-span-2 flex items-center lg:mt-6">
          <SecondaryButton
            onClick={() => onUpdateFile()}
            type="button"
            left
            text="Kaydet"
            style="w-full"
          >
            <i className="ri-checkbox-circle-fill text-lg text-white mr-2"></i>
          </SecondaryButton>
        </div>
      </div>
      <div className="mt-10">
        {/* {slideList && slideList?.status !== "record_not_found" ? (
          <SlaytTable
            datas={slideList}
            onDeleteSlide={(id) => onDeleteSlide(id)}
          />

        )
          : <div>Slide yok</div>
        } */}

        {slideList && slideList?.status !== "record_not_found" ? (
          <SlaytDndTable
            datas={slideList}
            setDatas={(v) => setSlideList(v)}
            onDeleteSlide={(id) => onDeleteSlide(id)}
          />
        ) : (
          <div className="text-center">Her hangi bir slaytınız yok!</div>
        )}
      </div>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />

      {openDeleteModal && (
        <DeleteModal
          setOpenModal={() => setOpenDeleteModal(false)}
          title="Ürünü Sil"
          question="Ürünü silmek istediğinize emin misiniz ?"
          buttonText="Sil"
          notify={() => notify()}
          onClick={() => {
            onDeleteSlide(deleteID);
            setOpenDeleteModal(false);
          }}
          id={deleteID}
        />
      )}
    </div>
  );
}

export default SlideList;
