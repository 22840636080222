import React from "react";

function TextBox({
  id,
  label,
  name,
  placeholder,
  innerRef,
  value,
  onChange,
  style,
  required,
  defaultValue,
  maxLength,
}) {
  return (
    <div className={`flex flex-col  ${style && style}`}>
      <label className="text-mainText text-sm mb-1">{label}</label>
      <input
        id={id}
        {...innerRef}
        onChange={onChange && ((e) => onChange(e.target.value))}
        className={`w-full border-1 border-secondaryText  py-2 px-3 focus:outline-none focus:border-hoverYellow focus:ring-1 focus:ring-hoverYellow placeholder-secondaryText`}
        type="text"
        name={name}
        placeholder={placeholder}
        value={value && value}
        defaultValue={defaultValue && defaultValue}
        required={required && required}
        maxLength={maxLength && maxLength}
      />
    </div>
  );
}

export default TextBox;
