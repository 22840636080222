import React from "react";

function MainButton({ text, style, children, type, left, onClick }) {
  return (
    <button
      className={`bg-mainYellow font-semibold flex items-center justify-center rounded-md text-white hover:bg-mainDark py-3 px-4 cursor-pointer transition-all duration-300 ${
        style && style
      }`}
      type={type && type}
      onClick={onClick && onClick}
    >
      {left && children}
      {text}
      {!left && children}
    </button>
  );
}

export default MainButton;
