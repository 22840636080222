import React, { useEffect, useRef, useState } from "react";

import TextBox from "components/FormElements/TextBox";
import TextArea from "components/FormElements/TextArea";
import SeoSettings from "components/SeoSettings/SeoSettings";
import { useGlobalStore } from "store/GlobalStore";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PageSettings from "components/PageSettings/PageSettings";
import { useSlugify } from "hooks";

import * as yup from "yup";

import {
  createPolicies,
  getPolicies,
  updatePolicies,
} from "services/AllServices";
import { useHistory } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";

const schema = yup.object().shape({
  policy_title: yup.string(),
  policy_content: yup.string(),
  seo_title: yup.string(),
  seo_description: yup.string(),
  can_see: yup.bool(),
});

function PoliticEdit(props) {
  const history = useHistory();
  const [policiesSeen, setPoliciesSeen] = useState(true);
  const [pageData, setPageData] = useState();
  const [isCreated, setIsCreated] = useState(false);
  const [pageTitleSlug, setPageTitleSlug] = useSlugify();
  const [pageTitle, setPageTitle] = useState("");

  const [selectedLanguage, setSelectedLanguage] = useGlobalStore((state) => [
    state.selectedLanguage,
    state.setSelectedLanguage,
  ]);
  const policiesID = props.location?.state?.id;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const notify = () => {
    toast.success("Başarılı", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onSubmit = async (data) => {
    const configureData = {
      language: selectedLanguage.value,
      page_url: pageData?.policy_content.find(
        (x) => x.language === selectedLanguage.value
      )
        ? pageData?.policy_content.find(
            (x) => x.language === selectedLanguage.value
          ).page_url
        : selectedLanguage.value === "tr"
        ? `/${pageTitleSlug}`
        : `/${pageTitleSlug}`,

      policy_title: data.policy_title?.replaceAll("'", "’"),
      policy_content: data.policy_content?.replaceAll("'", "’"),

      seo_title: data.seo_title?.replaceAll("'", "’"),
      seo_description: data.seo_description?.replaceAll("'", "’"),
      can_see: policiesSeen,

      policy_content_title: null,
      policy_content_text: null,
      marked_information_list: null,
      policy_image_list: null,
    };

    updatePolicies(pageData.id, configureData, pageData.brand_id).then(
      (res) => {
        notify();
        setTimeout(() => {
          history.push("/politikalar");
        }, 1000);
      }
    );
  };

  const onGetPolicies = () => {
    getPolicies(policiesID, selectedLanguage.value).then((res) => {
      setPageData();
      setPageData(res);
      setSelectedLanguage(
        res.policy_content[0].language === "tr"
          ? { id: 1, text: "Türkçe", value: "tr" }
          : { id: 2, text: "İngilizce", value: "en" }
      );
    });
  };

  useEffect(() => {
    onGetPolicies();
  }, []);

  useEffect(() => {
    if (pageData) {
      reset(
        pageData?.policy_content.find(
          (x) => x.language === selectedLanguage.value
        )
          ? pageData?.policy_content.find(
              (x) => x.language === selectedLanguage.value
            )
          : {
              policy_title: "",
              policy_content: "",
              seo_title: "",
              seo_description: "",
            }
      );
      if (
        pageData?.policy_content.find(
          (x) => x.language === selectedLanguage.value
        )
      ) {
        let newList = pageData?.policy_content.find(
          (x) => x.language === selectedLanguage.value
        );
      }
    }
  }, [selectedLanguage]);

  useEffect(() => {
    setPageTitleSlug(pageTitle);
  }, [pageTitle]);

  useEffect(() => {
    if (pageData) {
      if (pageData.status === "report_not_found") {
        setIsCreated(false);
      } else {
        setIsCreated(true);
      }
    }
  }, [pageData]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between ">
          <div className="container pr-4 pt-6 pb-4  overflow-y-scroll h-screen ">
            <h1 className="font-semibold text-2xl text-main mb-5">
              Politika Düzenle
            </h1>
            <TextBox
              name="policy_title"
              onChange={(v) => setPageTitle(v)}
              placeholder="Suç Gelirlerinin Aklanmasının ve Terörün Finansmanının Önlenmesi Poltikamız"
              innerRef={{ ...register("policy_title") }}
              required
            />
            <div className="flex items-center justify-between">
              <p className="text-mainText mt-2">
                {pageData?.policy_content.find(
                  (x) => x.language === selectedLanguage.value
                )
                  ? pageData?.policy_content.find(
                      (x) => x.language === selectedLanguage.value
                    ).page_url
                  : selectedLanguage.value === "tr"
                  ? `cemofset.com/${pageTitleSlug}`
                  : `cemofset.com/${selectedLanguage.value}/${pageTitleSlug}`}
              </p>
              {/* <TextBox placeholder="getirgsyf" style="w-2/3" /> */}
            </div>

            <TextArea
              name="policy_content"
              label="Açıklama Metni"
              placeholder=""
              style="mt-3"
              rows="14"
              innerRef={{ ...register("policy_content") }}
              required
            />

            <SeoSettings register={register} />
          </div>
          {pageData && <PageSettings setNewsSeen={(v) => setPoliciesSeen(v)} />}
        </div>
      </form>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default PoliticEdit;
