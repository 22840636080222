import MainButton from "components/FormElements/MainButton";
import DeleteModal from "components/Modals/DeleteModal";
import CreateUserModal from "components/Users/CreateUserModal";
import UpdateUserModal from "components/Users/UpdateUserModal";
import UsersTable from "components/Users/UsersTable";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { deleteStaff, getStaffList } from "services/AllServices";
import { useAuthStore } from "store/AuthStore";

function Users() {
  const history = useHistory();
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [openUpdateUserModal, setOpenUpdateUserModal] = useState(false);
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [isLoggin, setIsLoggin, userInformations] = useAuthStore((state) => [
    state.isLoggin,
    state.setIsLoggin,
    state.userInformations,
  ]);
  const [selectedUserId, setSelectedUserId] = useState();

  const [staffList, setStaffList] = useState();

  const notify = () => {
    toast.success("Kayıt Başarılı", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    onGetStaffList();
  };

  const onGetStaffList = () => {
    getStaffList().then((res) => {
      setStaffList(res);
    });
  };

  const onDeleteUser = () => {
    deleteStaff(selectedUserId).then((res) => {
      setOpenDeleteUserModal(false);
      onGetStaffList();
    });
  };

  useEffect(() => {
    onGetStaffList();
  }, []);

  return (
    <div className="container pt-6">
      <div className="flex items-center justify-between">
        <h1 className="font-semibold text-2xl text-main">
          Kullanıcıları Düzenle
        </h1>
        <MainButton
          onClick={() => setOpenCreateUserModal(true)}
          className=""
          left
          text="Kullanıcı Oluştur"
        >
          <i className="ri-user-add-fill text-white mr-2"></i>
        </MainButton>
      </div>
      <div className="mt-10">
        {staffList && (
          <UsersTable
            datas={staffList}
            setOpenUpdateUserModal={() => setOpenUpdateUserModal(true)}
            setOpenDeleteUserModal={() => setOpenDeleteUserModal(true)}
            setSelectedUserId={(v) => setSelectedUserId(v)}
          />
        )}
      </div>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />
      {openCreateUserModal && (
        <CreateUserModal
          setOpenModal={() => setOpenCreateUserModal(false)}
          notify={() => notify()}
        />
      )}
      {openUpdateUserModal && (
        <UpdateUserModal
          setOpenModal={() => setOpenUpdateUserModal(false)}
          notify={() => notify()}
          id={selectedUserId}
        />
      )}

      {openDeleteUserModal && (
        <DeleteModal
          setOpenModal={() => setOpenDeleteUserModal(false)}
          title="Kullanıcı Sil"
          question="Kullanıcıyı silmek istediğinize emin misiniz ?"
          buttonText="Sil"
          notify={() => notify()}
          onClick={() => onDeleteUser()}
          id={selectedUserId}
        />
      )}
    </div>
  );
}

export default Users;
