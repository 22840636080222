import React, { useEffect, useState } from "react";

function IconTextBox({
  label,
  name,
  type,
  placeholder,
  iconChange,
  textBoxIcon,
  textBoxSecondIcon,
  innerRef,
  value,
  style,
  required,
}) {
  const [changeIcon, setChangeIcon] = useState(false);
  const [inpuValue, setInputValue] = useState(value);
  const onSetInputValue = (e) => {
    setInputValue(e.target.value);
  };
  useEffect(() => {
    setInputValue(value);
  }, [value]);
  return (
    <div className={`flex flex-col  ${style && style}`}>
      <label className="text-mainText text-sm mb-1">{label}</label>
      <div className="flex w-full  items-center relative">
        <input
          {...innerRef}
          value={inpuValue}
          onChange={(e) => {
            onSetInputValue(e);
          }}
          className="w-full border-1 border-secondaryText rounded-md py-2 pl-3 pr-8 focus:outline-none focus:border-transparent focus:ring-1 focus:ring-hoverYellow placeholder-secondaryText "
          type={iconChange && !changeIcon ? type : "text"}
          name={name}
          placeholder={placeholder}
          required={required && required}
        />
        <div
          onClick={() => setChangeIcon(!changeIcon)}
          className="w-12 flex justify-center  py-2 rounded-md rounded-l-none absolute right-0 cursor-pointer"
        >
          <div className="mr-2 bg-makdosSecondaryGray w-0.5 h-5"></div>
          <i
            className={
              (iconChange
                ? changeIcon
                  ? textBoxIcon
                  : textBoxSecondIcon
                : textBoxIcon) + " text-textGray"
            }
          ></i>
        </div>
      </div>
    </div>
  );
}

export default IconTextBox;
