import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useToggleSidebar } from "hooks";

import { useAuthStore } from "store/AuthStore";
import { logout } from "services/AllServices";

function Sidebar() {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [isLoggin, setIsLoggin, userInformations] = useAuthStore((state) => [
    state.isLoggin,
    state.setIsLoggin,
    state.userInformations,
  ]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [
    menu1open,
    menu2open,
    menu3open,
    menu4open,
    menu5open,
    menu6open,
    menu7open,
    menu8open,
    toggle,
    closeToggles,
  ] = useToggleSidebar();

  const [menuCustomToggle, setMenuCustomToggle] = useState(false);

  const [openRenameCustomViewModal, setOpenRenameCustomViewModal] =
    useState(false);
  const [openDeleteCustomViewModal, setOpenDeleteCustomViewModal] =
    useState(false);

  const onSetMenuCustomToggle = (value) => {
    if (menuCustomToggle !== value) {
      setMenuCustomToggle(value);
    } else {
      setMenuCustomToggle(false);
    }
  };

  const onLogOut = () => {
    logout()
      .then((res) => {
        setIsLoggin(false);
        localStorage.clear();
      })
      .catch(setIsLoggin(false), localStorage.clear());
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  //when outside of the combobox clicked, it will be closed
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setMenuCustomToggle(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    if (menuOpen) {
      closeToggles();
    }
  }, [menuOpen]);

  return (
    <>
      <div
        className={
          !menuOpen
            ? "bg-main hidden h-screen lg:block pr-5 w-72  py-3 "
            : "bg-main h-screen pr-5 w-14 border-r-2 border-borderGray py-3 "
        }
      >
        <Link to="/">
          <div className=" flex items-center justify-center  mb-8 mt-2 cursor-pointer">
            <img src="/sidebarLogo.png" width="200" height="82" />
          </div>
        </Link>

        <Link to="/">
          <div
            onClick={() => toggle("1")}
            className={
              menu1open
                ? "flex items-center font-semibold text-white bg-bgSecond  py-1 pl-3 pr-2 mt-3 border-l-4 border-menuText  cursor-pointer"
                : "flex items-center font-semibold text-white py-1 pl-3 pr-2 mt-3  cursor-pointer"
            }
          >
            <i className=" text-menuText ri-home-4-fill mr-3 text-xl "></i>
            {!menuOpen && <p className="text-menuText"> Anasayfa</p>}
          </div>
        </Link>
        <Link to="/sayfalar">
          <div
            onClick={() => toggle("2")}
            className={
              menu2open
                ? "flex items-center  text-white bg-bgSecond  py-1 pl-3 pr-2 mt-3 border-l-4 border-menuText  cursor-pointer"
                : "flex items-center  text-white py-1 pl-3 pr-2 mt-3  cursor-pointer"
            }
          >
            <i className=" text-menuText ri-layout-2-fill mr-3 text-xl"></i>
            {!menuOpen && (
              <p className="text-menuText  font-semibold">Sayfalar</p>
            )}
          </div>
        </Link>

        {/* <div
          onClick={() => {
            toggle("2");
            setMenuOpen(false);
          }}
          className={
            menu2open
              ? "flex items-center justify-between font-semibold text-white bg-bgSecond  py-1 pl-3 pr-2 mt-3 border-l-4  cursor-pointer"
              : "flex items-center justify-between font-semibold text-white py-1 pl-3 pr-2 mt-3  cursor-pointer"
          }
        >
          <div className="flex items-center">
            <i className=" text-white ri-image-2-fill mr-3 text-xl"></i>
            {!menuOpen && (
              <p className="text-white" className="text-white">
                Medya
              </p>
            )}
          </div>
          {!menuOpen && (
            <svg
              className="-mr-1 ml-4 h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </div>
        {menu2open && (
          <div className="ml-12">
            <ul>
              <Link to="/">
                <li className="text-white text-sm my-3 font-medium  hover:text-white">
                  Görselleri Görüntüle
                </li>
              </Link>
              <Link to="/">
                <li className="text-white text-sm my-3 font-medium hover:text-white">
                  Görsel Yükle
                </li>
              </Link>
            </ul>
          </div>
        )} */}

        <div
          onClick={() => {
            toggle("3");
            setMenuOpen(false);
          }}
          className={
            menu3open
              ? "flex items-center justify-between font-semibold text-white bg-bgSecond  py-1 pl-3 pr-2 mt-3 border-l-4 border-menuText  cursor-pointer"
              : "flex items-center justify-between font-semibold text-white py-1 pl-3 pr-2 mt-3  cursor-pointer"
          }
        >
          <div className="flex items-center ">
            <i className="text-menuText ri-stack-fill mr-3 text-xl"></i>
            {!menuOpen && <p className="text-menuText">Ürünler </p>}
          </div>
          {!menuOpen && (
            <svg
              className="-mr-1 ml-4 h-5 w-5 text-menuText"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </div>
        {menu3open && (
          <div className="ml-12">
            <ul>
              <Link to="/urunleri-goruntule">
                <li className="text-menuText text-sm my-3 font-medium hover:text-mainYellow">
                  Ürünleri Görüntüle
                </li>
              </Link>
              <Link to="/urun-olustur">
                <li className="text-menuText text-sm my-3 font-medium hover:text-mainYellow">
                  Ürün Oluştur
                </li>
              </Link>
            </ul>
          </div>
        )}

        <div
          onClick={() => {
            toggle("4");
            setMenuOpen(false);
          }}
          className={
            menu4open
              ? "flex items-center justify-between font-semibold text-white bg-bgSecond  py-1 pl-3 pr-2 mt-3 border-l-4 border-menuText cursor-pointer"
              : "flex items-center justify-between font-semibold text-white py-1 pl-3 pr-2 mt-3  cursor-pointer"
          }
        >
          <div className="flex items-center">
            <i className=" text-menuText ri-file-edit-fill mr-3 text-xl"></i>
            {!menuOpen && <p className="text-menuText">Politikalar</p>}
          </div>
          {!menuOpen && (
            <svg
              className="-mr-1 ml-4 h-5 w-5 text-menuText"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </div>
        {menu4open && (
          <div className="ml-12">
            <ul>
              <Link to="/politikalar">
                <li className="text-menuText text-sm my-3 font-medium  hover:text-mainYellow">
                  Politikaları Görüntüle
                </li>
              </Link>
              <Link to="/politika-olustur">
                <li className="text-menuText text-sm my-3 font-medium  hover:text-mainYellow">
                  Politika Oluştur
                </li>
              </Link>
            </ul>
          </div>
        )}

        <div
          onClick={() => {
            toggle("5");
            setMenuOpen(false);
          }}
          className={
            menu5open
              ? "flex items-center justify-between font-semibold text-white bg-bgSecond  py-1 pl-3 pr-2 mt-3 border-l-4 border-menuText cursor-pointer"
              : "flex items-center justify-between font-semibold text-white py-1 pl-3 pr-2 mt-3  cursor-pointer"
          }
        >
          <div className="flex items-center">
            <i className=" text-menuText ri-slideshow-3-fill mr-3 text-xl"></i>
            {!menuOpen && <p className="text-menuText">Slayt Alanı</p>}
          </div>
          {!menuOpen && (
            <svg
              className="-mr-1 ml-4 h-5 w-5 text-menuText"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </div>
        {menu5open && (
          <div className="ml-12">
            <ul>
              <Link to="/slaytlari-goruntule">
                <li className="text-menuText text-sm my-3 font-medium  hover:text-hoverYellow">
                  Slaytları Görüntüle
                </li>
              </Link>
              <Link to="/slayt-olustur">
                <li className="text-menuText text-sm my-3 font-medium  hover:text-hoverYellow">
                  Slayt Oluştur
                </li>
              </Link>
            </ul>
          </div>
        )}

        <Link to="/alt-bilgi-duzenle">
          <div
            onClick={() => toggle("6")}
            className={
              menu6open
                ? "flex items-center  text-white bg-bgSecond  py-1 pl-3 pr-2 mt-3 border-l-4 border-menuText cursor-pointer"
                : "flex items-center  text-white py-1 pl-3 pr-2 mt-3  cursor-pointer"
            }
          >
            <i className=" text-menuText ri-layout-bottom-fill mr-3 text-xl"></i>
            {!menuOpen && (
              <p className="text-menuText  font-semibold">Alt Bilgi</p>
            )}
          </div>
        </Link>

        <Link to="/ayarlar">
          <div
            onClick={() => toggle("7")}
            className={
              menu7open
                ? "flex items-center  text-white bg-bgSecond  py-1 pl-3 pr-2 mt-3 border-l-4 border-menuText cursor-pointer"
                : "flex items-center  text-white py-1 pl-3 pr-2 mt-3  cursor-pointer"
            }
          >
            <i className=" text-menuText ri-settings-5-fill mr-3 text-xl"></i>
            {!menuOpen && (
              <p className="text-menuText  font-semibold">Ayarlar</p>
            )}
          </div>
        </Link>

        {/* {userInformations.staff_is_admin === true && ( */}
        <Link to="/kullanicilar">
          <div
            onClick={() => toggle("8")}
            className={
              menu8open
                ? "flex items-center font-semibold text-white bg-bgSecond  py-1 pl-3 pr-2 mt-3 border-l-4 border-menuText  cursor-pointer"
                : "flex items-center font-semibold text-white py-1 pl-3 pr-2 mt-3  cursor-pointer"
            }
          >
            <i className="text-menuText ri-team-fill mr-3 text-xl"></i>
            {!menuOpen && <p className="text-menuText"> Kullanıcılar</p>}
          </div>
        </Link>
        {/* )} */}

        <div className="absolute w-64 flex items-center justify-between bottom-3 left-3 cursor-pointer">
          <div className="flex items-center">
            <img src="avatar.png" width="44" height="44" />
            <div className="ml-4">
              <p className="font-semibold text-menuText">
                {userInformations.fullname}
              </p>
              <Link to="/profil-ayarlari">
                <p className="text-xs text-menuText">Profil Ayarları</p>
              </Link>
            </div>
          </div>
          <i
            onClick={() => onLogOut()}
            className=" text-menuText hover:text-hoverYellow ri-logout-box-line  text-2xl"
          ></i>
        </div>
      </div>

      {/* {openCreateModal && (
        <CategoryCreateModal setOpenModal={() => setOpenCreateModal(false)} />
      )} */}
    </>
  );
}

export default Sidebar;
