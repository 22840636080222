import FileUploader from "components/FormElements/FileUploader";
import SecondaryButton from "components/FormElements/SecondaryButton";
import TextBox from "components/FormElements/TextBox";
import React, { useEffect, useState } from "react";
import TextArea from "components/FormElements/TextArea";
import PageSettings from "components/PageSettings/PageSettings";
import SeoSettings from "components/SeoSettings/SeoSettings";
import { useGlobalStore } from "store/GlobalStore";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  getAboutus,
  updateAboutus,
  contentsAddFile,
} from "services/AllServices";
import { ToastContainer, toast } from "react-toastify";
import IconTextBox from "components/FormElements/IconTextBox";

const schema = yup.object().shape({
  first_about_us_title: yup.string(),
  first_about_us_content: yup.string(),
  first_about_us_image: yup.string(),
  second_about_us_title: yup.string(),
  second_about_us_content: yup.string(),
  second_about_us_image: yup.string(),
  project_title: yup.string(),
  project_image: yup.string(),
  project_button_text: yup.string(),
  project_video_url: yup.string(),

  seo_title: yup.string(),
  seo_description: yup.string(),
  can_see: yup.bool(),
  last_operation_time: yup.string(),
  first_about_us_image_title: yup.string(),
  second_about_us_image_title: yup.string(),
  third_about_us_image_title: yup.string(),
  fourth_about_us_image_title: yup.string(),
  fifth_about_us_image_title: yup.string(),
});

function AboutUsPageEdit() {
  const [fileAboutFirst, uploadFileAboutFirst] = useState();
  const [fileAboutSecond, uploadFileAboutSecond] = useState();
  const [fileAboutThird, uploadFileAboutThird] = useState();
  const [fileAboutFourth, uploadFileAboutFourth] = useState();
  const [fileAboutFifth, uploadFileAboutFifth] = useState();
  const [fileProject, uploadFileProject] = useState();

  const [pageData, setPageData] = useState();

  const [selectedLanguage] = useGlobalStore((state) => [
    state.selectedLanguage,
  ]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const notify = () => {
    toast.success("Kayıt Başarılı", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onSubmit = async (data) => {
    let fileaboutfirst = "";
    let fileaboutsecond = "";
    let fileaboutthird = "";
    let fileaboutfourth = "";
    let fileaboutfifth = "";
    // let fileproject = "";
    if (!fileAboutFirst?.isOld)
      if (fileAboutFirst) {
        for (const [key, value] of Object.entries(fileAboutFirst)) {
          let params = new FormData();
          params.append("files", value);
          await contentsAddFile("aboutusFirst", params).then((response) => {
            fileaboutfirst = response[0].file_path;
            notify();
          });
        }
      }
    if (!fileAboutSecond?.isOld)
      if (fileAboutSecond) {
        for (const [key, value] of Object.entries(fileAboutSecond)) {
          let params = new FormData();
          params.append("files", value);
          await contentsAddFile("aboutusSecond", params).then((response) => {
            fileaboutsecond = response[0].file_path;
            notify();
          });
        }
      }
    if (!fileAboutThird?.isOld)
      if (fileAboutThird) {
        for (const [key, value] of Object.entries(fileAboutThird)) {
          let params = new FormData();
          params.append("files", value);
          await contentsAddFile("aboutusThird", params).then((response) => {
            fileaboutthird = response[0].file_path;
            notify();
          });
        }
      }
    if (!fileAboutFourth?.isOld)
      if (fileAboutFourth) {
        for (const [key, value] of Object.entries(fileAboutFourth)) {
          let params = new FormData();
          params.append("files", value);
          await contentsAddFile("aboutusFourth", params).then((response) => {
            fileaboutfourth = response[0].file_path;
            notify();
          });
        }
      }
    if (!fileAboutFifth?.isOld)
      if (fileAboutFifth) {
        for (const [key, value] of Object.entries(fileAboutFifth)) {
          let params = new FormData();
          params.append("files", value);
          await contentsAddFile("aboutusFifth", params).then((response) => {
            fileaboutfifth = response[0].file_path;
            notify();
          });
        }
      }

      // if (fileProject) {
      //   for (const [key, value] of Object.entries(fileProject)) {
      //     let params = new FormData();
      //     params.append("files", value);
      //     await contentsAddFile("fileProject", params).then((response) => {
      //       fileproject = response[0].file_path;
      //       notify();
      //     });
      //   }
      //}
      else {
        alert("Lütfen Resim Seçiniz..");
      }

    const configureData = {
      language: selectedLanguage.value,
      first_about_us_title: data.first_about_us_title.replaceAll("'", "’"),
      first_about_us_content: data.first_about_us_content.replaceAll("'", "’"),
      first_about_us_image:
        fileaboutfirst !== "" ? fileaboutfirst : fileAboutFirst.name,
      second_about_us_title: data.second_about_us_title.replaceAll("'", "’"),
      second_about_us_content: data.second_about_us_content.replaceAll(
        "'",
        "’"
      ),
      second_about_us_image:
        fileaboutsecond !== "" ? fileaboutsecond : fileAboutSecond.name,

      third_about_us_title: data.third_about_us_title.replaceAll("'", "’"),
      third_about_us_content: data.third_about_us_content.replaceAll("'", "’"),
      third_about_us_image:
        fileaboutthird !== "" ? fileaboutthird : fileAboutThird.name,

      fourth_about_us_title: data.fourth_about_us_title.replaceAll("'", "’"),
      fourth_about_us_content: data.fourth_about_us_content.replaceAll(
        "'",
        "’"
      ),
      fourth_about_us_image:
        fileaboutfourth !== "" ? fileaboutfourth : fileAboutFourth.name,

      fifth_about_us_title: data.fifth_about_us_title.replaceAll("'", "’"),
      fifth_about_us_content: data.fifth_about_us_content.replaceAll("'", "’"),
      fifth_about_us_image:
        fileaboutfifth !== "" ? fileaboutfifth : fileAboutFifth.name,

      project_title: data.project_title.replaceAll("'", "’"),
      project_content: data.project_content.replaceAll("'", "’"),
      // project_image: fileproject,
      project_button_text: data.project_button_text.replaceAll("'", "’"),
      project_video_url: data.project_video_url.replaceAll("'", "’"),
      seo_title: data.seo_title.replaceAll("'", "’"),
      seo_description: data.seo_description.replaceAll("'", "’"),
      can_see: data.can_see,
      last_operation_time: data.last_operation_time,
      first_about_us_image_title: data.first_about_us_image_title.replaceAll(
        "'",
        "’"
      ),
      second_about_us_image_title: data.second_about_us_image_title.replaceAll(
        "'",
        "’"
      ),
      third_about_us_image_title: data.third_about_us_image_title.replaceAll(
        "'",
        "’"
      ),
      fourth_about_us_image_title: data.fourth_about_us_image_title.replaceAll(
        "'",
        "’"
      ),
      fifth_about_us_image_title: data.fifth_about_us_image_title.replaceAll(
        "'",
        "’"
      ),
    };
    await updateAboutus(selectedLanguage.value, {
      content: configureData,
    }).then((res) => {
      notify();
      onGetAboutUs();
    });
  };

  const onGetAboutUs = () => {
    getAboutus(selectedLanguage.value).then(async (res) => {
      reset(res);
      await setPageData(null);
      setPageData(res);
      uploadFileAboutFirst({ isOld: true, name: res.first_about_us_image });
      uploadFileAboutSecond({ isOld: true, name: res.second_about_us_image });
      uploadFileAboutThird({ isOld: true, name: res.third_about_us_image });
      uploadFileAboutFourth({ isOld: true, name: res.fourth_about_us_image });
      uploadFileAboutFifth({ isOld: true, name: res.fifth_about_us_image });
    });
  };

  useEffect(() => {
    onGetAboutUs();
  }, [selectedLanguage]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-between ">
        <div className="container pr-4 pt-6 pb-6 h-screen overflow-y-auto ">
          <h1 className="font-semibold text-2xl text-main mb-5">
            Hakkımızda Düzenle
          </h1>
          {/* Aboutus section is below */}
          <h2 className="font-semibold text-xl text-main mt-5 mb-5">
            1. Hakkımızda Kısmı
          </h2>
          <TextBox
            name="first_about_us_title"
            label="Başlık Metni"
            placeholder="Başlık Giriniz"
            innerRef={{ ...register("first_about_us_title") }}
            required
          />
          <TextArea
            name="first_about_us_content"
            label="İçerik Metni"
            placeholder="Kurucumuz Dr. Oktay Duran’ın yenilikçi vizyonu ile 1979 yılında matbaacılık ve ambalaj teknolojileri alanında ulusal ve uluslararası pazarlara üretim yapmak üzere faaliyete başladık.
            Kurulduğumuz yıldan bugüne iş alanlarımıza yenilerini ekleyerek, bütünsel kalite, sektör kültürüne yatırım ve insan kaynakları yönetimi ilkelerini gelenekselden çağdaşa taşıyan yapımızla faaliyet göstermeye devam ediyoruz."
            style="mt-3 mb-3"
            rows="7"
            innerRef={{ ...register("first_about_us_content") }}
            required
          />

          <div className="grid grid-cols-12 gap-5 items-center">
            <div className="col-span-12">
              <div className="flex flex-col">
                {pageData && (
                  <FileUploader
                    id="aboutusupload1"
                    file="aboutusupload1"
                    name="first_about_us_image"
                    uploadId="aboutusupload1"
                    value={fileAboutFirst}
                    defaultText="Dosya Seçiniz"
                    required="required"
                    setValue={uploadFileAboutFirst}
                    label="Görsel"
                    placeholder="Dosya seçiniz"
                    inputStyle="w-full mb-2 text-xs"
                    labelStyle="mt-3 mb-0"
                    multiple={false}
                    maxFile={10}
                    defaultValue={pageData?.first_about_us_image}
                  />
                )}
                <div className="">
                  <p className="text-xs text-menuText">
                    Ana sayfa ve kategorilerde görünecek görsel boyutu 620 X 430
                    Boyutunda olmalıdır.
                  </p>
                </div>
              </div>

              <TextBox
                style="mt-3"
                name="first_about_us_image_title"
                label="Görsel İsmi"
                placeholder="Görsel Başlığı Giriniz"
                innerRef={{ ...register("first_about_us_image_title") }}
                required
              />
            </div>
          </div>

          {/* Aboutus section 2 is below  */}
          <h2 className="font-semibold text-xl text-main mt-5 mb-5">
            2. Hakkımızda Kısmı
          </h2>
          <TextBox
            name="second_about_us_title"
            label="Başlık Metni"
            placeholder="Müşterilerimizin ihtiyaçlarına uygun, sürdürülebilir ve ekonomik çözümler sunarken, ilkelerimizden ödün vermiyoruz. Çağın gerekliliklerini takip etmekle yetinmiyor, öncülük görevini üstlenerek sektöre bilinçli çalışan yetiştirme ve mutlu çalışan profilini geliştirme üzerine yatırım yapıyoruz. İşinde uzman ve motivasyonu yüksek çalışan profilimiz, akreditasyon ve sertifikalarımız, esnek ve çözüm odaklı yaklaşımımız ile sürdürülebilir hizmet anlayışımızı uyguluyoruz."
            innerRef={{ ...register("second_about_us_title") }}
            required
          />
          <TextArea
            name="second_about_us_content"
            label="İçerik Metni"
            placeholder="Paragraf Metni Giriniz."
            style="mt-3 mb-3"
            rows="7"
            innerRef={{ ...register("second_about_us_content") }}
            required
          />

          <div className="grid grid-cols-12 gap-5 items-center">
            <div className="col-span-12 lg:col-span-10 ">
              <div className="flex flex-col">
                {pageData && (
                  <FileUploader
                    id="aboutusupload2"
                    file="aboutusupload2"
                    name="second_about_us_image"
                    uploadId="aboutusupload2"
                    value={fileAboutSecond}
                    defaultText="Dosya Seçiniz"
                    required="required"
                    setValue={uploadFileAboutSecond}
                    label="Görsel"
                    placeholder="Dosya seçiniz"
                    inputStyle="w-full mb-2 text-xs"
                    labelStyle="mt-3 mb-0"
                    multiple={false}
                    maxFile={10}
                    defaultValue={pageData?.second_about_us_image}
                  />
                )}
                <div className="">
                  <p className="text-xs text-menuText">
                    Ana sayfa ve kategorilerde görünecek görsel boyutu 620 X 430
                    Boyutunda olmalıdır.
                  </p>
                </div>
              </div>

              <TextBox
                style="mt-3"
                name="second_about_us_image_title"
                label="Görsel İsmi"
                placeholder="Görsel Başlığı Giriniz"
                innerRef={{ ...register("second_about_us_image_title") }}
                required
              />
            </div>
          </div>

          {/* Aboutus section 3 is below  */}
          <h2 className="font-semibold text-xl text-main mt-5 mb-5">
            3. Hakkımızda Kısmı
          </h2>
          <TextBox
            name="third_about_us_title"
            label="Başlık Metni"
            placeholder="Müşterilerimizin ihtiyaçlarına uygun, sürdürülebilir ve ekonomik çözümler sunarken, ilkelerimizden ödün vermiyoruz. Çağın gerekliliklerini takip etmekle yetinmiyor, öncülük görevini üstlenerek sektöre bilinçli çalışan yetiştirme ve mutlu çalışan profilini geliştirme üzerine yatırım yapıyoruz. İşinde uzman ve motivasyonu yüksek çalışan profilimiz, akreditasyon ve sertifikalarımız, esnek ve çözüm odaklı yaklaşımımız ile sürdürülebilir hizmet anlayışımızı uyguluyoruz."
            innerRef={{ ...register("third_about_us_title") }}
            required
          />
          <TextArea
            name="third_about_us_content"
            label="İçerik Metni"
            placeholder="Paragraf Metni Giriniz."
            style="mt-3 mb-3"
            rows="7"
            innerRef={{ ...register("third_about_us_content") }}
            required
          />

          <div className="grid grid-cols-12 gap-5 items-center">
            <div className="col-span-12 lg:col-span-10 ">
              <div className="flex flex-col">
                {pageData && (
                  <FileUploader
                    id="aboutusupload3"
                    file="aboutusupload3"
                    name="third_about_us_image"
                    uploadId="aboutusupload3"
                    value={fileAboutThird}
                    defaultText="Dosya Seçiniz"
                    required="required"
                    setValue={uploadFileAboutThird}
                    label="Görsel"
                    placeholder="Dosya seçiniz"
                    inputStyle="w-full mb-2 text-xs"
                    labelStyle="mt-3 mb-0"
                    multiple={false}
                    maxFile={10}
                    defaultValue={pageData?.third_about_us_image}
                  />
                )}
                <div className="">
                  <p className="text-xs text-menuText">
                    Ana sayfa ve kategorilerde görünecek görsel boyutu 620 X 430
                    Boyutunda olmalıdır.
                  </p>
                </div>
              </div>
              <TextBox
                style="mt-3"
                name="third_about_us_image_title"
                label="Görsel İsmi"
                placeholder="Görsel Başlığı Giriniz"
                innerRef={{ ...register("third_about_us_image_title") }}
                required
              />
            </div>
          </div>

          {/* Aboutus section 4 is below  */}
          <h2 className="font-semibold text-xl text-main mt-5 mb-5">
            4. Hakkımızda Kısmı
          </h2>
          <TextBox
            name="fourth_about_us_title"
            label="Başlık Metni"
            placeholder="Müşterilerimizin ihtiyaçlarına uygun, sürdürülebilir ve ekonomik çözümler sunarken, ilkelerimizden ödün vermiyoruz. Çağın gerekliliklerini takip etmekle yetinmiyor, öncülük görevini üstlenerek sektöre bilinçli çalışan yetiştirme ve mutlu çalışan profilini geliştirme üzerine yatırım yapıyoruz. İşinde uzman ve motivasyonu yüksek çalışan profilimiz, akreditasyon ve sertifikalarımız, esnek ve çözüm odaklı yaklaşımımız ile sürdürülebilir hizmet anlayışımızı uyguluyoruz."
            innerRef={{ ...register("fourth_about_us_title") }}
            required
          />
          <TextArea
            name="fourth_about_us_content"
            label="İçerik Metni"
            placeholder="Paragraf Metni Giriniz."
            style="mt-3 mb-3"
            rows="7"
            innerRef={{ ...register("fourth_about_us_content") }}
            required
          />

          <div className="grid grid-cols-12 gap-5 items-center">
            <div className="col-span-12 lg:col-span-10 ">
              <div className="flex flex-col">
                {pageData && (
                  <FileUploader
                    id="aboutusupload4"
                    file="aboutusupload4"
                    name="fourth_about_us_image"
                    uploadId="aboutusupload4"
                    value={fileAboutFourth}
                    defaultText="Dosya Seçiniz"
                    required="required"
                    setValue={uploadFileAboutFourth}
                    label="Görsel"
                    placeholder="Dosya seçiniz"
                    inputStyle="w-full mb-2 text-xs"
                    labelStyle="mt-3 mb-0"
                    multiple={false}
                    maxFile={10}
                    defaultValue={pageData?.fourth_about_us_image}
                  />
                )}
                <div className="">
                  <p className="text-xs text-menuText">
                    Ana sayfa ve kategorilerde görünecek görsel boyutu 620 X 430
                    Boyutunda olmalıdır.
                  </p>
                </div>
              </div>
              <TextBox
                style="mt-3"
                name="fourth_about_us_image_title"
                label="Görsel İsmi"
                placeholder="Görsel Başlığı Giriniz"
                innerRef={{ ...register("fourth_about_us_image_title") }}
                required
              />
            </div>
          </div>

          {/* Aboutus section 5 is below  */}
          <h2 className="font-semibold text-xl text-main mt-5 mb-5">
            5. Hakkımızda Kısmı
          </h2>
          <TextBox
            name="fifth_about_us_title"
            label="Başlık Metni"
            placeholder="Müşterilerimizin ihtiyaçlarına uygun, sürdürülebilir ve ekonomik çözümler sunarken, ilkelerimizden ödün vermiyoruz. Çağın gerekliliklerini takip etmekle yetinmiyor, öncülük görevini üstlenerek sektöre bilinçli çalışan yetiştirme ve mutlu çalışan profilini geliştirme üzerine yatırım yapıyoruz. İşinde uzman ve motivasyonu yüksek çalışan profilimiz, akreditasyon ve sertifikalarımız, esnek ve çözüm odaklı yaklaşımımız ile sürdürülebilir hizmet anlayışımızı uyguluyoruz."
            innerRef={{ ...register("fifth_about_us_title") }}
            required
          />
          <TextArea
            name="fifth_about_us_content"
            label="İçerik Metni"
            placeholder="Paragraf Metni Giriniz."
            style="mt-3 mb-3"
            rows="7"
            innerRef={{ ...register("fifth_about_us_content") }}
            required
          />

          <div className="grid grid-cols-12 gap-5 items-center">
            <div className="col-span-12 lg:col-span-10 ">
              <div className="flex flex-col">
                {pageData && (
                  <FileUploader
                    id="aboutusupload5"
                    file="aboutusupload5"
                    name="fifth_about_us_image"
                    uploadId="aboutusupload5"
                    value={fileAboutFifth}
                    defaultText="Dosya Seçiniz"
                    required="required"
                    setValue={uploadFileAboutFifth}
                    label="Görsel"
                    placeholder="Dosya seçiniz"
                    inputStyle="w-full mb-2 text-xs"
                    labelStyle="mt-3 mb-0"
                    multiple={false}
                    maxFile={10}
                    defaultValue={pageData?.fifth_about_us_image}
                  />
                )}
                <div className="">
                  <p className="text-xs text-menuText">
                    Ana sayfa ve kategorilerde görünecek görsel boyutu 620 X 430
                    Boyutunda olmalıdır.
                  </p>
                </div>
              </div>
              <TextBox
                style="mt-3"
                name="fifth_about_us_image_title"
                label="Görsel İsmi"
                placeholder="Görsel Başlığı Giriniz"
                innerRef={{ ...register("fifth_about_us_image_title") }}
                required
              />
            </div>
          </div>

          {/* Responsibilities part are below */}

          {/* <h2 className="font-semibold text-xl text-main mt-5 mb-5">
            Sorumluluk Projesi Kısmı
          </h2>
          <TextBox
            name="project_title"
            label="Başlık Metni"
            placeholder="Toplumsal Sorumluluk Projelerimiz"
            innerRef={{ ...register("project_title") }}
            required
          />
          <TextArea
            name="project_content"
            label="İçerik Metni"
            placeholder="Paragraf Metni Giriniz."
            style="mt-3 mb-3"
            rows="7"
            innerRef={{ ...register("project_content") }}
            required
          /> */}

          {/* <div className="grid grid-cols-12 gap-5 items-center">
            <div className="col-span-12 lg:col-span-10 ">
              <div className="flex flex-col">
                {pageData && (
                  <FileUploader
                    id="projectUpload"
                    file="projectUpload"
                    name="project_image"
                    uploadId="projectUpload"
                    value={fileProject}
                    defaultText="Dosya Seçiniz"
                    required="required"
                    setValue={uploadFileProject}
                    label="Görsel"
                    placeholder="Dosya Seçiniz"
                    inputStyle="w-full mb-2 text-xs"
                    labelStyle="mt-3 mb-0"
                    multiple={false}
                    maxFile={10}
                    defaultValue={pageData?.project_image}
                  />
                )}
                <div className="">
                  <p className="text-xs text-menuText">
                    Ana sayfa ve kategorilerde görünecek görsel boyutu 1000 X
                    680 Boyutunda olmalıdır.
                  </p>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="grid grid-cols-2 gap-5 lg:mt-6">
            <div className="col-span-1">
              <TextBox
                name="project_button_text"
                label="Buton Metni"
                placeholder="Tanıtım Videomuz"
                innerRef={{ ...register("project_button_text") }}
                required
              />
            </div>
            <div className="col-span-1">
              <TextBox
                name="project_video_url"
                label="Video URL"
                placeholder="https://www.youtube.com/watch?v=A-hlyFxlK70&t=1s"
                innerRef={{ ...register("project_video_url") }}
                required
              />
            </div>
          </div> */}

          <SeoSettings register={register} />
        </div>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ToastContainer />

        <PageSettings date={pageData?.last_operation_time} />
      </div>
    </form>
  );
}

export default AboutUsPageEdit;
