import ChangeMail from "components/ProfileSettings/ChangeMail";
import ChangePassword from "components/ProfileSettings/ChangePassword";
import React from "react";

function ProfileSettings() {
  return (
    <div className="container pt-6">
      <h1 className="font-semibold text-4xl text-main ">Kullanıcı Ayarları</h1>

      <div className="grid grid-cols-3 gap-10">
        <div className="col-span-1">
          <ChangeMail />
        </div>
        <div className="col-span-1">
          <ChangePassword />
        </div>
      </div>
    </div>
  );
}

export default ProfileSettings;
