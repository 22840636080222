import React, { useEffect } from "react";
import { useTable, useSortBy } from "react-table";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import update from "immutability-helper";
import { useHistory } from "react-router-dom";
import { useGlobalStore } from "store/GlobalStore";

const Table = ({ columns, data, setDatas }) => {
  const getRowId = React.useCallback((row) => {
    return row.id;
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        data: data,
        columns,
        getRowId,
      },
      useSortBy
    );

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = data[dragIndex];
    setDatas(
      update(data, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <table {...getTableProps()} className="w-full">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              <th className="bg-mainDark text-white p-3 text-left text-sm">
                Taşı
              </th>
              {headerGroup.headers.map((column) => (
                <th
                  className="bg-mainDark text-white p-3 text-left text-sm"
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                  <span className="flex relative justify-between items-center">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <svg
                          className="absolute right-0 -top-3"
                          width="10"
                          height="6"
                          viewBox="0 0 10 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 6L0.669873 0.749999L9.33013 0.75L5 6Z"
                            fill="#D8D8D8"
                          />
                        </svg>
                      ) : (
                        <svg
                          className="absolute right-0 -top-3"
                          width="10"
                          height="6"
                          viewBox="0 0 10 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 0L9.33013 5.25L0.669873 5.25L5 0Z"
                            fill="#D8D8D8"
                          />
                        </svg>
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(
            (row, index) =>
              prepareRow(row) || (
                <Row
                  index={index}
                  row={row}
                  moveRow={moveRow}
                  {...row.getRowProps()}
                />
              )
          )}
        </tbody>
      </table>
    </DndProvider>
  );
};

const DND_ITEM_TYPE = "row";

const Row = ({ row, index, moveRow }) => {
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = dropRef.current.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DND_ITEM_TYPE, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <tr
      style={{ opacity }}
      ref={dropRef}
      {...row.getRowProps()}
      className={index % 2 === 0 ? " bg-white p-2" : " bg-bgColor p-2"}
    >
      <td ref={dragRef} className="p-2 text-mainText cursor-pointer">
        <i className="ri-menu-line ml-2"></i>
      </td>

      {row.cells.map((cell) => {
        return (
          <td {...cell.getCellProps()} className="p-2 text-mainText">
            {cell.render("Cell")}
          </td>
        );
      })}
    </tr>
  );
};

const ReactTable = ({ datas, onDeleteSlide, setDatas }) => {
  const history = useHistory();
  const [setSelectedLanguage] = useGlobalStore((state) => [
    state.setSelectedLanguage,
  ]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Başlık",
        accessor: "slide_title",
      },
      {
        Header: "Alt Yazı",
        accessor: "slide_description",
      },
      {
        Header: "Dil",
        accessor: "language",
        Cell: (props) => (props.value === "tr" ? "Türkçe" : "İngilizce"),
      },
      {
        Header: "Aksiyonlar",
        accessor: "id",
        Cell: settings,
      },
    ],
    []
  );

  function settings(props) {
    return (
      <div className="flex items-center ml-4 ">
        <i
          onClick={() => onDeleteSlide(props.value)}
          className="ri-delete-bin-fill text-alertRed cursor-pointer"
        ></i>
        {/* <i className="ri-eye-fill text-secondary cursor-pointer ml-3"></i> */}
        <i
          onClick={() => {
            setSelectedLanguage(
              props.row.original.language === "tr"
                ? { id: 1, text: "Türkçe", value: "tr" }
                : { id: 2, text: "İngilizce", value: "en" }
            );
            history.push({
              pathname: "/slayt-duzenle",
              state: { id: props.value },
            });
          }}
          className="ri-edit-box-fill ml-3 text-secondary cursor-pointer"
        ></i>
      </div>
    );
  }

  return <Table columns={columns} data={datas} setDatas={(v) => setDatas(v)} />;
};

export default ReactTable;
