import FileUploader from "components/FormElements/FileUploader";
import SecondaryButton from "components/FormElements/SecondaryButton";
import TextBox from "components/FormElements/TextBox";
import React, { useEffect, useState } from "react";
import TextArea from "components/FormElements/TextArea";
import PageSettings from "components/PageSettings/PageSettings";
import SeoSettings from "components/SeoSettings/SeoSettings";
import { useGlobalStore } from "store/GlobalStore";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  getReferences,
  updateReferences,
  contentsAddFile,
} from "services/AllServices";

import AddMediaModal from "components/Modals/AddMediaModal";
import EditFileModal from "components/Modals/EditFileModal";
import { ToastContainer, toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FileCard from "components/Cards/FileCard";

const schema = yup.object().shape({
  seo_title: yup.string(),
  seo_description: yup.string(),
  can_see: yup.bool(),
});

function ReferencesPageEdit() {
  const [file, uploadFile] = useState();
  const [pageData, setPageData] = useState();
  const [allImageList, setAllImageList] = useState([]);
  const [maxOrder, setMaxOrder] = useState();

  const [activityReportData, setActivityReportData] = useState(null);
  const [openAddCardModal, setOpenAddCardModal] = useState(false);
  const [openEditCardModal, setOpenEditCardModal] = useState(false);
  const [selectedEditCard, setselectedEditCard] = useState();
  const [selectedLanguage] = useGlobalStore((state) => [
    state.selectedLanguage,
  ]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const notify = () => {
    toast.success("Başarılı", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onSubmit = async (data) => {
    // if (allImageList) {
    //   for (const [key, value] of Object.entries(allImageList)) {
    //     let params = new FormData();
    //     params.append("file", value);
    //     contentsAddFile(selectedLanguage.value, params).then(
    //       (response) => {
    //         imageList.push(response.file_path)
    //         notify();
    //       }
    //     );
    //   }
    // } else {
    //   alert("Lütfen Resim Seçiniz..");
    //}

    const configureData = {
      language: selectedLanguage.value,
      image_list: allImageList,
      seo_title: data.seo_title.replaceAll("'", "’"),
      seo_description: data.seo_description.replaceAll("'", "’"),
      can_see: data.can_see,
      last_operation_time: data.last_operation_time,
    };

    updateReferences(selectedLanguage.value, { content: configureData }).then(
      (res) => {
        notify();
      }
    );

    if (activityReportData && activityReportData.status !== "not_found") {
      let newOrder = [];
      activityReportData.map((item, index) => {
        newOrder.push({ id: item.id, order: index + 1 });
      });

      // updateActivityReportCardOrder(newOrder);
    }
  };

  const reOrder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newList = reOrder(
      allImageList,
      result.source.index,
      result.destination.index
    );
    setAllImageList(newList);
  }

  const onCreateActivityReportCard = async (file, file_title = null) => {
    let imageList = allImageList;

    if (file) {
      for (const [key, value] of Object.entries(file.content.image)) {
        let params = new FormData();
        params.append("files", value);
        contentsAddFile(file_title, params)
          .then(async (response) => {
            imageList.push({
              file_title: response[0].file_title,
              file_path: response[0].file_path,
              order: maxOrder + 1,
            });
            setMaxOrder(maxOrder + 1);

            setAllImageList([...imageList]);
          })
          .catch((e) => console.log("e", e));
      }
    } else {
      alert("Lütfen Dosya Seçiniz..");
    }
  };

  const onUpdateActivityReportCard = (file, file_title = null) => {
    let newArr = [];
    if (file) {
      for (const [key, value] of Object.entries(file)) {
        let params = new FormData();
        params.append("files", value);
        contentsAddFile(file_title, params).then(async (res) => {
          allImageList.map((item) => {
            if (item.order !== selectedEditCard.order) {
              newArr.push(item);
            } else {
              let newItem = {
                order: selectedEditCard.order,
                file_path: res[0].file_path,
                file_title: res[0].file_title,
              };
              newArr.push(newItem);
            }
          });
          setAllImageList(newArr);
        });
      }
    } else {
      allImageList.map((item) => {
        if (item.order !== selectedEditCard.order) {
          newArr.push(item);
        } else {
          let newItem = {
            order: selectedEditCard.order,
            file_path: selectedEditCard.file_path,
            file_title: file_title,
          };
          newArr.push(newItem);
        }
      });
      setAllImageList(newArr);
    }
  };

  const onDeleteCard = (order) => {
    let newArr = [];
    allImageList.map((item) => {
      if (item.order !== order) {
        newArr.push(item);
      }
    });
    setAllImageList(newArr);
    //imagelist i map et kartı bul ve sil
    //delete codes will be here
  };

  useEffect(() => {
    console.log(allImageList, "allImageList");
  }, [allImageList]);

  useEffect(() => {
    getReferences(selectedLanguage.value).then(async (res) => {
      reset(res);
      await setPageData(null);
      setPageData(res);

      setAllImageList(res.image_list);

      if (res.image_list.length === 0) {
        setMaxOrder(0);
      } else {
        let lastItem = res.image_list.slice(-1);

        setMaxOrder(lastItem[0].order);
      }
    });
  }, [selectedLanguage]);

  useEffect(() => {
    console.log(maxOrder, "maxorder");
  }, [maxOrder]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between ">
          <div className="container pr-4 pt-6 pb-4  overflow-y-scroll h-screen">
            <h1 className="font-semibold text-2xl text-main mb-5">
              Referansları Düzenle
            </h1>
            <h2 className="font-semibold text-xl text-main mt-5 mb-5">
              Referans Görselleri
            </h2>
            {allImageList && allImageList.length != 0 && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {allImageList?.map((item, index) => {
                        return (
                          <Draggable
                            key={item?.order?.toString()}
                            draggableId={item?.order?.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="mb-5"
                              >
                                <FileCard
                                  name="Dosya"
                                  datas={item}
                                  id={item.order}
                                  inf1={item.file_path}
                                  deleteClick={(order) => onDeleteCard(order)}
                                  editClick={(datas) => {
                                    setOpenEditCardModal(true);
                                    setselectedEditCard(datas);
                                  }}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
            <SecondaryButton
              onClick={() => setOpenAddCardModal(true)}
              type="button"
              left
              text="YENİ ALAN EKLE"
              style="w-full"
            >
              <i className="ri-add-circle-fill text-lg text-secondary mr-2"></i>
            </SecondaryButton>

            <SeoSettings register={register} />
          </div>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <ToastContainer />
          <PageSettings date={pageData?.last_operation_time} />
        </div>
      </form>
      {openAddCardModal && (
        <AddMediaModal
          setOpenModal={() => setOpenAddCardModal(false)}
          onClick={(file, file_title) =>
            onCreateActivityReportCard(file, file_title)
          }
        />
      )}

      {openEditCardModal && (
        <EditFileModal
          setOpenModal={() => setOpenEditCardModal(false)}
          onClick={(file, file_title) =>
            onUpdateActivityReportCard(file, file_title)
          }
          datas={selectedEditCard}
        />
      )}
    </div>
  );
}

export default ReferencesPageEdit;
