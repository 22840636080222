import AlertWarning from "components/ContentAlerts/AlertWarning";
import FileUploader from "components/FormElements/FileUploader";
import MainButton from "components/FormElements/MainButton";
import SecondaryButton from "components/FormElements/SecondaryButton";
import TextArea from "components/FormElements/TextArea";
import TextBox from "components/FormElements/TextBox";
import React, { useState, useEffect } from "react";
import { getCompanyProfileAdress } from "services/AllServices";

function EditFileModal({ setOpenModal, onClick, datas }) {
  const [uploadFile, setUploadFile] = useState();
  const [fileTitle, setFileTitle] = useState();

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white  ">
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <div className="flex items-center justify-between  py-3 px-5 ">
                  <p
                    className="text-xl leading-6 font-semibold text-main"
                    id="modal-headline"
                  >
                    Dosya Düzenle
                  </p>
                  <i
                    onClick={() => setOpenModal(false)}
                    className="ri-close-line text-2xl text-main cursor-pointer"
                  ></i>
                </div>
                <div className="px-5 pt-2 pb-5 bg-white">
                  <TextBox
                    onChange={(v) => setFileTitle(v)}
                    label="Dosya Başlığı"
                    placeholder="Dosya için başlık giriniz.."
                    defaultValue={datas.file_title}
                  />
                  <FileUploader
                    id="download3"
                    file="fileUpload3"
                    name="download3"
                    uploadId="uploadId3"
                    value={uploadFile}
                    defaultText="Dosya Seçiniz"
                    required="required"
                    setValue={setUploadFile}
                    label="Başlık Görseli"
                    placeholder="Dosya seçiniz"
                    inputStyle="w-full mb-2 text-xs"
                    labelStyle="mt-3 mb-0"
                    multiple={false}
                    maxFile={10}
                    justFile={false}
                    defaultValue={datas?.file_path}
                  />
                </div>
                <div className="flex items-center justify-end px-5 pt-2 pb-5 bg-white">
                  <MainButton
                    type="button"
                    onClick={() => {
                      onClick && onClick(uploadFile, fileTitle, datas);
                      setOpenModal(false);
                    }}
                    className=""
                    left
                    text="Kaydet"
                  >
                    <i className="ri-checkbox-circle-fill text-white mr-2"></i>
                  </MainButton>
                  <SecondaryButton
                    onClick={() => setOpenModal(false)}
                    type="button"
                    text="Vazgeç"
                    style="ml-5"
                  ></SecondaryButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditFileModal;
